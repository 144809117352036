import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { liveFeedbackSaga } from './saga';

export const initialState = {
  liveFeedbackResult: null,
  lastChunkKey: null,
  currentTranscriptionId: null,
  audioChunks: [],
  mimeType: null,
  headerChunk: null,
  currentMetric: 'speechPace',
  showTimeBeforeEnd: false,
  showEndLiveFeedbackConfirmation: false,
};

export const liveFeedbackSlice = createSlice({
  name: 'liveFeedback',
  initialState,
  reducers: {
    setCurrentTranscriptionId(state, action) {
      state.currentTranscriptionId = action.payload;
    },
    setLiveFeedback(state, action) {
      state.liveFeedbackResult = action.payload;
    },
    saveLastChunkKey(state, action) {
      state.lastChunkKey = action.payload;
    },
    setMimeType(state, action) {
      state.mimeType = action.payload;
    },
    resetMimeType(state) {
      state.mimeType = null;
    },
    addAudioChunk(state, action) {
      state.audioChunks = [...state.audioChunks, action.payload];
      console.log(state.audioChunks);
    },
    resetAudioChunks(state) {
      state.audioChunks = [];
    },
    setHeaderChunk(state, action) {
      state.headerChunk = action.payload;
    },
    resetHeaderChunk(state) {
      state.headerChunk = null;
    },
    setCurrentMetric(state, action) {
      state.currentMetric = action.payload;
    },
    resetCurrentMetric(state) {
      state.currentMetric = null;
    },
    setShowTimeBeforeEnd(state, action) {
      state.showTimeBeforeEnd = action.payload;
    },
    setShowEndLiveFeedbackConfirmation(state, action) {
      state.showEndLiveFeedbackConfirmation = action.payload;
    },

    // saga actions
    uploadLiveFeedbackChunk() {},
    fetchLiveFeedback(state, action) {},
  },
});

export const { actions: liveFeedbackActions } = liveFeedbackSlice;

export const useLiveFeedbackSlice = () => {
  useInjectReducer({
    key: liveFeedbackSlice.name,
    reducer: liveFeedbackSlice.reducer,
  });
  useInjectSaga({ key: liveFeedbackSlice.name, saga: liveFeedbackSaga });
  return { actions: liveFeedbackSlice.actions };
};
