import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

export const RecordLink: React.FC = () => {
  const requestRecordingStart = useCallRecordingStore(
    (state) => state.requestRecordingStart,
  );
  const isRecording = useCallRecordingStore((state) => state.isRecording);

  return (
    <span
      className={isRecording ? '' : 'text-accent-blue cursor-pointer'}
      onClick={requestRecordingStart}
    >
      Record
    </span>
  );
};
