import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '@/pages/HomePage/slice/selectors';

import {
  selectRepsLoading,
  selectShowNoCallsBanner,
} from '../../../pages/LeadershipDashboard/slice/selectors';
import LoadMoreButton from '../../LeadershipPage/shared/LoadMoreButton';
import { RepPerformanceHelper } from './helpers/RepPerformanceHelper';
import RecommendedMinimumLine from './RecommendedMinimumLine';
import RepsAttentionItem from './RepsAttentionItem';

const RepsAttentionList = ({
  header,
  dotColor,
  marginRightNeeded,
  reps,
  nextPage,
  paginationSize,
}) => {
  const repsLoading = useSelector(selectRepsLoading);
  const showNoCallsBanner = useSelector(selectShowNoCallsBanner);
  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const repsWithFullInfo = RepPerformanceHelper.setRepsInfo(reps);

  return (
    <div
      className={`flex flex-col w-[50%] h-[100%] ${
        marginRightNeeded ? 'mr-6' : ''
      } p-6 border rounded-[20px] border-1 border-[#ECECEC] shadow-sm`}
    >
      <div className="flex flex-row items-center">
        <div
          className={'w-[8px] h-[8px] rounded-full'}
          style={{ backgroundColor: `${dotColor}` }}
        ></div>
        <span className="ml-1 text-base text-[#1C1C1C] font-semibold">
          {header}
        </span>
      </div>
      {(!showNoCallsBanner || useMock) && (
        <div>
          <div className="mt-4 flex flex-col">
            <div className="mb-2 w-[100%] flex justify-end">
              <div
                className="flex flex-col relative"
                style={{ width: 'calc(100% - 188px)' }}
              >
                <span
                  className="uppercase text-[10px] font-bold text-[#2C6CF6] text-end"
                  style={{ width: 'calc(80% - 90px)' }}
                >
                  recommended minimum
                </span>
                <div
                  className="h-[4px] bg-[#2C6CF6] rounded-sm"
                  style={{ width: 'calc(80% - 90px)' }}
                ></div>
                <RecommendedMinimumLine repsCount={reps.length} />
              </div>
            </div>
            {repsWithFullInfo?.map((rep, index) => (
              <RepsAttentionItem
                isFirstItem={index === 0}
                repInfo={rep}
                key={rep.id}
              />
            ))}
          </div>
          <div className="flex items-center justify-center h-[36px] mt-4">
            <LoadMoreButton
              onClick={() => nextPage()}
              disabled={paginationSize > reps?.length}
              isLoading={repsLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RepsAttentionList;
