import React from 'react';
import { Line } from 'react-chartjs-2';

import { ChartDataLoading } from '@/components/InsightsAcrossCallsPage/chart/ChartDataLoading';
import { emptyChartOptions } from '@/components/InsightsAcrossCallsPage/consts/InsightsAcrossCallsChartEmptyOptionsConst';
import { InsightsEmptyState } from '@/components/InsightsAcrossCallsPage/InsightsEmptyState';

interface EmptyBackgroundChartParams {
  isLoading: boolean;
  playbookItemsState?: {
    hasPlaybookItemsOptions?: boolean;
    errorText: string;
  };
}

export const EmptyBackgroundChart: React.FC<EmptyBackgroundChartParams> = ({
  isLoading,
  playbookItemsState,
}: EmptyBackgroundChartParams) => {
  const emptyChartData = {
    datasets: [],
  };

  return (
    <div className="h-[400px] relative">
      <Line
        options={emptyChartOptions as any}
        data={emptyChartData as any}
        className="absolute"
      />
      <div className="absolute w-fit left-0 right-0 m-auto top-[46%] translate-y-[-50%]">
        {isLoading ? (
          <ChartDataLoading />
        ) : (
          <div className="bg-[#FFF] rounded-[20px] border-[1px] border-[#ECECEC] px-[120px] py-[72px]">
            <InsightsEmptyState playbookItemsState={playbookItemsState} />
          </div>
        )}
      </div>
    </div>
  );
};
