import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button/Button';
import { EmailInput } from '@/components/Input/EmailInput';
import { Label } from '@/components/Label/Label';
import { signUpActions } from '@/pages/SignUpPage/slice';
import { validateEmail } from '@/utils/validators/validateEmail';

import { useSendPasswordResetEmail } from '../hooks/useSendPasswordResetEmail';

const UNKNOWN_USER_ERROR =
  "We can't find an account associated with this email";

interface EmailInputStepProps {
  initialEmail?: string;
  onEmailAccept: (email: string) => void;
}

export const EmailInputStep: React.FC<EmailInputStepProps> = ({
  initialEmail = '',
  onEmailAccept,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState(initialEmail);
  const { sendPasswordResetEmail, isPending } = useSendPasswordResetEmail();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setError('Please enter valid work email. Example: john@workemail.com');
      return;
    }

    const res = await sendPasswordResetEmail(email);
    if (!res.success) {
      setError(UNKNOWN_USER_ERROR);
      return;
    }

    setError(null);
    onEmailAccept(email);
  };

  return (
    <>
      <h2 className="font-bold text-2xl mb-4">Reset Password</h2>
      <p className="text-dark text-sm mb-6">
        Enter your email and we will send you a link to reset your password
      </p>

      {error && (
        <div className="mb-6 flex flex-col items-center">
          <p className="text-accent-red font-medium text-sm">{error}</p>
          {error === UNKNOWN_USER_ERROR && (
            <div className="flex items-center">
              <span className="text-sm font-medium">
                Don't have an account yet?
              </span>
              <Button
                variant="link"
                onClick={() => {
                  dispatch(signUpActions.setVerifiedEmail(email));
                  navigate('/auth/register');
                }}
              >
                Sign up for free
              </Button>
            </div>
          )}
        </div>
      )}

      <form onSubmit={handleSubmit} className="w-[400px]">
        <Label isError={!!error} htmlFor="reset_password_email_input">
          Email
        </Label>
        <EmailInput
          name="email"
          id="reset_password_email_input"
          value={email}
          onChange={handleChange}
          placeholder="Enter your work email"
          className="mb-6"
          isError={!!error}
        />

        <Button className="w-full" isLoading={isPending} type="submit">
          Continue
        </Button>
      </form>
    </>
  );
};
