import React from 'react';
import { createPortal } from 'react-dom';
import { Toaster } from 'sonner';

export const ToastProvider: React.FC = () => {
  return createPortal(
    <Toaster position="bottom-center" className="w-[800px]" />,
    document.body,
  );
};
