import { Response } from '@/api/response';

import { API } from '../../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  getGoogleAuthStatus: backendURL + '/api/google/auth/status',
};

export interface GetGoogleAuthStatusResponse {
  areCredentialsValid: boolean | null;
  isCalendarConnected: boolean;
}

export const getGoogleAuthStatus = () => async () => {
  const response = await API(endpoints.getGoogleAuthStatus, {
    method: 'GET',
  });

  const result: Response<GetGoogleAuthStatusResponse> = await response.json();

  return result.data;
};
