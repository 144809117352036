import { formatDurationHMS, formatTimestamp } from '@/utils/formatDate';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

interface DisplayRecordingCallDurationProps {
  format?: 'formatDurationHMS' | 'formatTimestamp';
  recordingType?: 'bot' | 'user';
}

export const DisplayRecordingCallDuration: React.FC<
  DisplayRecordingCallDurationProps
> = ({ format = 'formatDurationHMS', recordingType }) => {
  const botElapsedSeconds = useBotRecordingStore(
    (state) => state.elapsedSeconds,
  );

  const elapsedSeconds = useCallRecordingStore((state) => state.elapsedSeconds);

  const formatter =
    format === 'formatDurationHMS' ? formatDurationHMS : formatTimestamp;

  return (
    <>
      {formatter(
        (recordingType === 'bot' ? botElapsedSeconds : elapsedSeconds) * 1000,
      )}
    </>
  );
};
