import React, { useState } from 'react';
import { Info, Link2 } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Input } from '@/components/Input/Input';
import { SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE } from '@/components/Recording/AddAssistantSuccess';
import { useJoinRecorderByUrl } from '@/components/Recording/hooks/useJoinRecorderByUrl';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { RecordButton } from '@/components/Recording/shared/RecordButton';
import { useHomeSlice } from '@/pages/HomePage/slice';
import cn from '@/utils/cn';
import { getCookie } from '@/utils/cookie';

interface AddNewCalendarEventProps {
  onClose: () => void;
  containerClassName?: string;
}

export const AddNewCalendarEvent: React.FC<AddNewCalendarEventProps> = ({
  onClose,
  containerClassName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { actions } = useHomeSlice();

  const { setIsEventAdded } = useRecordingCalendarStore();
  const { joinRecorderByUrl, isPending } = useJoinRecorderByUrl();

  const [inputValue, setInputValue] = useState<string>();
  const [inputHasError, setInputHasError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (value: string) => {
    if (inputValue !== value && inputHasError) setInputHasError(false);

    setInputValue(value);
  };

  const submitNewCalendarEvent = async () => {
    if (!inputValue?.length) return;

    const googleMeetRegExp = new RegExp(
      /^(?:https?:\/\/)?meet\.google\.com\/[a-zA-Z]{3}-[a-zA-Z]{4}-[a-z0-9-]{3}$/i,
    );

    const zoomMeetRegExp = new RegExp(
      /^https:\/\/[\w-]*\.?zoom\.us\/j\/\S*\?(pwd=[^\s]*)?/i,
    );

    const isZoomMeet = zoomMeetRegExp.test(inputValue);
    const isGoogleMeet = googleMeetRegExp.test(inputValue);

    if (!isZoomMeet && !isGoogleMeet) {
      setInputHasError(true);
      return;
    }

    setIsEventAdded(false);
    setIsLoading(true);
    const newCall = await joinRecorderByUrl(inputValue);

    if (newCall) {
      dispatch(actions.fetchCalls(null));
      setIsLoading(false);
      setIsEventAdded(true);

      const showSuccessView = getCookie(
        SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE,
      );

      if (showSuccessView === 'false') {
        setIsEventAdded(false);
        onClose();
      }

      navigate(`/calls/${newCall.id}`);
    }
  };

  return (
    <div className={cn('flex flex-col', containerClassName)}>
      <div className="flex flex-row items-center justify-between w-full">
        <Input
          leftSlot={
            <Link2
              className={inputHasError ? 'text-accent-red' : 'text-dark'}
              size={20}
            />
          }
          isError={inputHasError}
          placeholder="Paste Zoom or Google Meet meeting URL to record"
          className={cn(
            'text-sm text-dark',
            !inputValue?.length && 'text-opacity-[.48]',
          )}
          onChange={(e) => onChange(e.target.value)}
        />
        <RecordButton
          onClick={submitNewCalendarEvent}
          variant="primary"
          className="ml-1"
          disabled={!inputValue?.length}
          isLoading={isLoading || isPending}
        />
      </div>
      {inputHasError && (
        <div className="flex flex-row mt-6 items-center">
          <Info size={16} className="text-accent-red" />
          <span className="text-accent-red text-sm ml-2">
            Please enter a Zoom or Google Meet meeting URL
          </span>
        </div>
      )}
    </div>
  );
};
