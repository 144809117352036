import { InsightsAcrossCallsTimeRangeOptionsValue } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const getProspectInsights =
  (timeRange?: InsightsAcrossCallsTimeRangeOptionsValue) => async () => {
    if (!timeRange) return;

    const query = `timeRange=${timeRange}`;

    const response = await fetch(
      backendURL + `/api/insights/prospect?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const result = await response.json();

    return result.data;
  };
