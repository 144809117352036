import React from 'react';

export const ChartLegendEmpty = () => {
  return (
    <>
      <div className="w-4 h-4 bg-[#F6F6F6] border-[1px] border-[#ECECEC] rounded-sm"></div>
      <div className="ml-1 w-[120px] h-3 rounded-[10px] bg-[#ECECEC]"></div>
    </>
  );
};
