import React from 'react';

import { DetailedFillerWordsMetrics } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

export enum FillerWords {
  Ah = 'ah',
  Like = 'like',
  Um = 'um',
  Uh = 'uh',
}

interface FillerWordsTooltipProps {
  fillerWords: Array<FillerWord>;
}

const MetricToTitle = Object.freeze({
  [DetailedFillerWordsMetrics.FillerWordsAhCount]: FillerWords.Ah,
  [DetailedFillerWordsMetrics.FillerWordsLikeCount]: FillerWords.Like,
  [DetailedFillerWordsMetrics.FillerWordsUmCount]: FillerWords.Um,
  [DetailedFillerWordsMetrics.FillerWordsUhCount]: FillerWords.Uh,
});

export interface FillerWord {
  key: DetailedFillerWordsMetrics;
  count: number;
}

export const FillerWordsTooltip: React.FC<FillerWordsTooltipProps> = ({
  fillerWords,
}: FillerWordsTooltipProps) => {
  const capitalizeFirstLetter = (el: string) => {
    return el.charAt(0).toUpperCase() + el.slice(1);
  };

  const FillerWordBlock = (el: FillerWord) => {
    return (
      <div
        key={el.key}
        className="rounded-[8px] px-2 py-1 flex items-center flex-row ml-1"
        style={{
          background: '#E7402929',
        }}
      >
        <span
          className="text-xs font-medium"
          style={{
            color: '#E74029',
          }}
        >
          {capitalizeFirstLetter(MetricToTitle[el.key])} - {el.count}
        </span>
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center">
      <span className="color-[#1C1C1C] text-xs font-medium">
        Most used words:
      </span>
      {fillerWords.map(FillerWordBlock)}
    </div>
  );
};
