import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  Edit,
  MinusCircle,
  MoreVertical,
  Plus,
  Trash,
  User,
} from 'react-feather';
import { useDispatch } from 'react-redux';

import { eventTracker } from '@/amplitude/eventTracker';
import { canDeleteCall } from '@/utils/callHelper';
import cn from '@/utils/cn';

import { useCallDetailsSlice } from '../../pages/CallDetailsPage/slice';

export const EditMenu = ({
  call,
  openEditCallDetailsModal,
  openDeleteCallModal,
}) => {
  const dispatch = useDispatch();
  const { actions } = useCallDetailsSlice();

  const updateCall = (body) => {
    if (!call.id) {
      return;
    }
    dispatch(
      actions.updateCall({
        id: call.id,
        body,
      }),
    );

    dispatch(actions.fetchCall({ id: call.id }));
  };

  const updateIncludeInAnalysis = () => {
    if (call.includeInAnalysis) {
      eventTracker.callDetails.excludeFromAnalysisClick({ callId: call.id });
    } else {
      eventTracker.callDetails.includeInAnalysisClick({ callId: call.id });
    }

    updateCall({
      includeInAnalysis: !call.includeInAnalysis,
    });
  };

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as="div">
          {({ open }) => (
            <div
              data-testid="call-edit-menu"
              className={cn(
                'p-2.5 rounded-lg-1.5 hover:bg-main-gray',
                open ? 'bg-main-gray' : 'bg-light-gray',
              )}
            >
              <MoreVertical size={16} />
            </div>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-72 font-medium origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-10 focus:outline-none">
            {/* This div is needed to prevent Menu from adding separator lines between items */}
            <div className="p-3">
              <Menu.Item
                onClick={() => {
                  openEditCallDetailsModal();
                  eventTracker.callDetails.editCallDetailsClick({
                    callId: call.id,
                  });
                }}
                className="cursor-pointer rounded-s hover:bg-light-gray"
              >
                <div className="p-2 flex items-center gap-2 text-dark">
                  <Edit size={16} />
                  <span className="text-sm">Edit Call Details</span>
                </div>
              </Menu.Item>
              {call.status === 'done' && !call.isDemoCall && (
                <Menu.Item
                  onClick={() => {
                    dispatch(actions.openChangeSpeakerModal());
                    eventTracker.callDetails.changeSellerClick({
                      callId: call.id,
                    });
                  }}
                  className="cursor-pointer rounded-s hover:bg-light-gray"
                >
                  <div className="p-2 flex items-center gap-2 text-dark">
                    <User size={16} />
                    <span className="text-sm">Change Seller</span>
                  </div>
                </Menu.Item>
              )}
              {call.status === 'done' && !call.isDemoCall && (
                <Menu.Item
                  onClick={() => {
                    updateIncludeInAnalysis();
                  }}
                  className="cursor-pointer rounded-s hover:bg-light-gray"
                >
                  {call.includeInAnalysis ? (
                    <div className="p-2 flex items-center gap-2 text-accent-red">
                      <MinusCircle size={16} />
                      <span className="text-sm">
                        Exclude from insights analysis
                      </span>
                    </div>
                  ) : (
                    <div className="p-2 flex items-center gap-2 text-dark">
                      <Plus size={16} />
                      <span className="text-sm">
                        Include in insights analysis
                      </span>
                    </div>
                  )}
                </Menu.Item>
              )}
              {canDeleteCall(call) && (
                <Menu.Item
                  onClick={() => {
                    openDeleteCallModal();
                    eventTracker.callDetails.deleteCallClick({
                      callId: call.id,
                    });
                  }}
                  className="cursor-pointer rounded-s hover:bg-light-gray"
                >
                  <div className="p-2 flex items-center gap-2 text-accent-red">
                    <Trash size={16} />
                    <span className="text-sm">Delete call</span>
                  </div>
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
