export class PerformanceMetricsHelper {
  static orderMap = {
    playbookFulfilledPercentage: 1,
    averageSpeechPace: 2,
    averageTalkPercentage: 3,
    averageQuestionsAsked: 4,
    averageFillerWords: 5,
  };

  static metricResultText = (metricStatus) => {
    switch (metricStatus) {
      case null: {
        return {
          text: '-',
          color: '#5C6A82',
        };
      }
      case 'good':
        return {
          text: 'Great 👍',
          color: '#0FB600',
        };
      case 'bad':
        return {
          text: 'Opportunity ⚡',
          color: '#E74029',
        };
      case 'okay':
        return {
          text: 'Okay 👌',
          color: '#F89B11',
        };
    }
  };

  static metricTexts = {
    playbookFulfilledPercentage: (value) => ({
      header: 'Sales playbook',
      value: value ? `Completed in ${value}% of calls` : 'No calls last week',
    }),
    averageSpeechPace: (value) => ({
      header: 'Speech pace',
      value: value ? `${value} words per minute` : 'No calls last week',
    }),
    averageTalkPercentage: (value) => ({
      header: 'Talk ratio',
      value: value ? `Speaker spoke ${value}% of time` : 'No calls last week',
    }),
    averageQuestionsAsked: (value) => ({
      header: 'Questions asked',
      value: value ? `${value} questions` : 'No calls last week',
    }),
    averageFillerWords: (value) => ({
      header: 'Filler words',
      value: value ? `${value} filler words` : 'No calls last week',
    }),
  };

  static rangesByMetric = {
    playbookFulfilledPercentage: (metricResult) => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult >= 80:
          return 'good';
        case metricResult < 50:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageSpeechPace: (metricResult) => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult >= 110 && metricResult <= 170:
          return 'good';
        case metricResult > 200:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageTalkPercentage: (metricResult) => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult < 40:
          return 'good';
        case metricResult > 60:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageLongestMonologue: (metricResult) => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult > 60:
          return 'good';
        case metricResult < 30:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageQuestionsAsked: (metricResult) => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult >= 18:
          return 'good';
        case metricResult < 12:
          return 'bad';
        default:
          return 'okay';
      }
    },
    averageFillerWords: (metricResult) => {
      if (!metricResult) return null;

      switch (true) {
        case metricResult < 2:
          return 'good';
        case metricResult > 20:
          return 'bad';
        default:
          return 'okay';
      }
    },
  };

  static setMetricsInfo(metrics) {
    const metricsWithStatuses = [];

    Object.keys(metrics).forEach((key) => {
      const metricValue = metrics[key];
      const calculateStatusFunction = this.rangesByMetric[key];
      const textFunction = this.metricTexts[key];

      if (!calculateStatusFunction || !textFunction) return;

      const status = calculateStatusFunction(metricValue);

      metricsWithStatuses.push({
        key,
        order: this.orderMap[key] || 999,
        status,
        value: metricValue,
        text: {
          ...textFunction(metricValue),
          result: this.metricResultText(status),
        },
      });
    });

    return metricsWithStatuses;
  }
}
