import { API } from '@/api/base';
import { toast } from '@/components/Toast/toaster';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  inviteAssistantAgain: backendURL + '/api/calendar/events/reinvite-bot',
};

export const inviteAssistantAgain = async (callId: string) => {
  const response = await API(endpoints.inviteAssistantAgain, {
    method: 'POST',
    body: JSON.stringify({
      callId,
    }),
  });

  const data = await response.json();

  if (!response.ok) {
    toast.error({
      title: 'Failed to invite assistant again',
      subtitle: data.message || 'Unknown error occurred',
    });
    return;
  }

  return data;
};
