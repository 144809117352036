import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  toggleCalendarEventRecording: (eventId: string) =>
    backendURL + `/api/calendar/events/${eventId}/toggle`,
};

export const toggleCalendarEventRecording = async (eventId: string) => {
  await API(endpoints.toggleCalendarEventRecording(eventId), {
    method: 'PATCH',
  });
};
