import { IPlaybook } from '../../interfaces/playbook.interface';
import { IPlaybookItem } from '../../interfaces/playbook-item.interface';

export interface Sortable {
  [k: string]: any;
}

export function isSellerEvaluation(category: string) {
  return category.toLowerCase() === 'seller evaluation';
}

export function isProspectEvaluation(category: string) {
  return category.toLowerCase() === 'prospect evaluation';
}

export const matchesCategory = (
  item: Pick<IPlaybookItem, 'category'>,
  category: string,
) => {
  return item.category.toLowerCase() === category.toLowerCase();
};

export const listRefKey = (category: string, playbook: IPlaybook) =>
  `${category}-${playbook.id}`;

export const initSortableLists = (
  categories: string[],
  listsRef: React.MutableRefObject<Map<string, HTMLUListElement>>,
  sortablesRef: React.MutableRefObject<Map<string, Sortable>>,
) => {
  // @ts-ignore
  const Sortable = window.Sortable;

  for (const category of categories) {
    const list = listsRef.current.get(category);
    const sortable = new Sortable(list, {
      group: {
        name: category,
        sort: true,
      },
      animation: 150,
    });

    sortablesRef.current.set(category, sortable);
  }
};
