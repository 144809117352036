// Data for main page tabs specifying the tabs used
import React from 'react';

import { InsightsAcrossCallsPage } from '@/pages/InsightsAcrossCallsPage';

import CallsTabPage from '../pages/CallsTabPage';
import LeadershipDashboardPage from '../pages/LeadershipDashboard';
import LeadershipTeamPage from '../pages/LeadershipTeam';

export const userTabsData = [
  {
    id: 0,
    name: 'Calls',
    path: '/calls',
    element: <CallsTabPage />,
  },
  {
    id: 1,
    name: 'insights',
    path: '/insights',
    element: <InsightsAcrossCallsPage />,
  },
];

export const leadershipTabsData = [
  {
    id: 0,
    name: 'dashboard',
    path: '/dashboard',
    element: <LeadershipDashboardPage />,
  },
  {
    id: 1,
    name: 'team',
    path: '/team',
    element: <LeadershipTeamPage />,
  },
];
