import React from 'react';

import cn from '@/utils/cn';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  isError?: boolean;
}

export const Label: React.FC<LabelProps> = ({
  children,
  className,
  isError,
  ...restProps
}) => {
  return (
    <label
      {...restProps}
      className={cn(
        'font-semibold text-[10px] text-dark',
        isError && 'text-accent-red',
        className,
      )}
    >
      {children}
    </label>
  );
};
