import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { callDetailsSaga } from './saga';

export const initialState = {
  loading: false,
  call: null,
  callQualitativeAnalysis: null,
  callQualitativeAnalysisLoading: false,
  changeSpeakerModalOpen: false,
  refetchCall: false,
  fetchCallFailed: false,
};

export const callDetailsSlice = createSlice({
  name: 'callDetails',
  initialState,
  reducers: {
    fetchCall(state, action) {
      state.loading = true;
    },
    fetchCallSuccess(state, action) {
      state.loading = false;
      state.call = action.payload;
    },
    fetchCallFailed(state, action) {
      state.loading = false;
      state.message = action.payload;
      state.fetchCallFailed = true;
    },
    setDefaultFetchCallFailed(state, action) {
      state.fetchCallFailed = false;
    },
    fetchCallQualitativeAnalysis(state, action) {
      state.callQualitativeAnalysisLoading = true;
    },
    fetchCallQualitativeAnalysisSuccess(state, action) {
      state.callQualitativeAnalysisLoading = false;
      state.callQualitativeAnalysis = action.payload;
    },
    fetchCallQualitativeAnalysisFailed(state, action) {
      state.callQualitativeAnalysisLoading = false;
      state.message = action.payload;
    },
    updateCall(state, action) {
      state.loading = true;
    },
    updateCallSuccess(state, action) {
      state.loading = false;
      state.call = {
        ...state.call,
        ...action.payload,
      };
    },
    updateCallFailed(state, action) {
      state.loading = false;
      state.message = action.payload;
    },
    openChangeSpeakerModal(state, action) {
      state.changeSpeakerModalOpen = true;
    },
    closeChangeSpeakerModal(state, action) {
      state.changeSpeakerModalOpen = false;
    },
  },
});

export const { actions: callDetailsActions } = callDetailsSlice;

export const useCallDetailsSlice = () => {
  useInjectReducer({
    key: callDetailsSlice.name,
    reducer: callDetailsSlice.reducer,
  });
  useInjectSaga({ key: callDetailsSlice.name, saga: callDetailsSaga });
  return { actions: callDetailsSlice.actions };
};
