import { Line } from 'react-chartjs-2';

export const options = ({
  recommendedRangeStart,
  recommendedRangeEnd,
  percentageView = false,
  onAnimationComplete,
}: {
  recommendedRangeStart?: number;
  recommendedRangeEnd?: number;
  percentageView: boolean;
  onAnimationComplete?: () => void;
}) => {
  const resultOptions: React.ComponentProps<typeof Line>['options'] = {
    clip: false,
    maintainAspectRatio: false,
    interaction: { mode: 'index' },
    animation: {
      onComplete: onAnimationComplete,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        left: -24,
      },
    },
    scales: {
      x: {
        ticks: {
          padding: 5,
        },
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        offset: false,
        beginAtZero: true,
        title: {
          display: true,
          color: '#000',
          font: {
            weight: 'bold',
            size: 12,
          },
        },
        ticks: {
          // min: 0,
          count: 4,
          callback: (item: number) => (percentageView ? `${item}%` : item),
        },
        beforeBuildTicks: function (axis: any) {
          if (percentageView) {
            axis.options.ticks.stepSize = 25;
            return;
          }

          let stepSize;
          const roundTo = 3;

          if (axis.max > 0) stepSize = Math.ceil(axis.max / roundTo) * roundTo;
          else if (axis.max < 0)
            stepSize = Math.floor(axis.max / roundTo) * roundTo;
          else stepSize = roundTo;

          axis.options.ticks.stepSize = stepSize;
        },
        grid: {
          tickWidth: 0,
        },
        border: {
          dash: [5, 3],
          width: 0,
        },
        afterDataLimits(scale) {
          scale.max += 2;
          scale.min = scale.min === 0 ? 0 : scale.min - 1;
        },
      },
    },
  };

  if (recommendedRangeStart !== undefined && resultOptions.plugins) {
    resultOptions.plugins.annotation = {
      annotations: {
        box1: {
          drawTime: 'beforeDatasetsDraw',
          type: 'box',
          yMin: recommendedRangeStart,
          yMax: recommendedRangeEnd,
          backgroundColor: 'rgba(44, 108, 246, 0.06)',
          borderColor: 'transparent',
        },
      },
    };
  }

  if (percentageView === true && resultOptions?.scales?.['y']) {
    resultOptions.scales['y'].min = 0;
    resultOptions.scales['y'].max = 100;
  }

  return resultOptions;
};
