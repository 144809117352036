import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { getProspectInsights } from '@/api/insights/getProspectInsights';
import { ChartLineLegend } from '@/components/InsightsAcrossCallsPage/chart/ChartLineLegend';
import { EmptyBackgroundChart } from '@/components/InsightsAcrossCallsPage/chart/EmptyBackgroundChart';
import {
  InsightsAcrossCallChartRawDataItem,
  InsightsAcrossCallsChart,
} from '@/components/InsightsAcrossCallsPage/chart/InsightsAcrossCallsChart';
import { TemplateLoadingValueBlocks } from '@/components/InsightsAcrossCallsPage/consts/TemplateLoadingValueBlocks';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsStatistics } from '@/components/InsightsAcrossCallsPage/InsightsStatistics';
import { ChartValueItem } from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsMapper';
import {
  ProspectChartValueItem,
  ProspectEvaluationDataMapper,
  ProspectItemData,
} from '@/components/InsightsAcrossCallsPage/mappers/ProspectEvaluationDataMapper';
import { PersonalInsightsChartViewType } from '@/components/InsightsAcrossCallsPage/PersonalInsightsChartTooltip';
import cn from '@/utils/cn';

export const ProspectEvaluation = () => {
  const {
    selectedDateOption,
    setIsFirstLoading,
    isFirstLoading,
    rawChartData,
    isChartLoading,
    setIsPageDataLoading,
    selectedTimeRangeCallsCount,
  }: InsightsAcrossCallsStore = useInsightsAcrossCallsStore();

  const [chartValueItems, setChartValueItems] = useState<ChartValueItem[]>([]);
  const [selectedChartItem, setSelectedChartItem] =
    useState<ProspectChartValueItem>();
  const [chartData, setChartData] = useState<
    InsightsAcrossCallChartRawDataItem[]
  >([]);

  const prospectResult = useQuery<ProspectItemData[]>({
    queryFn: getProspectInsights(selectedDateOption?.value),
    queryKey: ['prospectInsights', selectedDateOption],
  });

  const prospectResultData: ProspectItemData[] | undefined =
    prospectResult.data;

  useEffect(() => {
    setIsPageDataLoading(prospectResult.isLoading);
  }, [prospectResult.isLoading]);

  useEffect(() => {
    if (!prospectResultData) return;

    const newChartValueItems: ProspectChartValueItem[] =
      ProspectEvaluationDataMapper.map(prospectResultData);

    setChartValueItems(newChartValueItems);
    if (!selectedChartItem) {
      const selectedItem: ProspectChartValueItem | undefined =
        newChartValueItems.find((el) => el.selected);
      setSelectedChartItem(selectedItem);
    }
    setIsFirstLoading(false);
  }, [prospectResultData]);

  useEffect(() => {
    if (!rawChartData?.length || !selectedChartItem) return;

    // @ts-ignore
    const newChartData: InsightsAcrossCallChartRawDataItem[] = rawChartData.map(
      (el) => ({
        ...el,
        value: Number(el[selectedChartItem.key]),
        items: el.items.map((call) => ({
          value: Number(call[selectedChartItem.key]),
          ...call,
        })),
      }),
    );

    setChartData(newChartData);
  }, [rawChartData, selectedChartItem]);

  const isDataLoading = isChartLoading || prospectResult.isLoading;
  const showStatisticBlocks = chartValueItems.length > 0;

  return (
    <>
      {(showStatisticBlocks || isDataLoading) && (
        <InsightsStatistics
          isFirstLoading={isFirstLoading || !showStatisticBlocks}
          beforeDataLoadItems={TemplateLoadingValueBlocks.Prospect}
          isLoading={prospectResult.isLoading}
          chartValueItems={chartValueItems}
          setSelectedChartItem={setSelectedChartItem}
        />
      )}
      <div
        className={cn(
          'w-full border-[1px] border-[#ECECEC] p-6',
          showStatisticBlocks || isDataLoading
            ? 'rounded-bl-[20px] rounded-br-[20px]'
            : 'rounded-[20px]',
        )}
      >
        <div className="flex flex-row justify-between mb-8">
          {/*<CallsComparisonTooltip*/}
          {/*  isLoading={isDataLoading}*/}
          {/*  currentValue={selectedChartItem?.value}*/}
          {/*  prevValue={selectedChartItem?.prevValue}*/}
          {/*/>*/}
          <ChartLineLegend
            isLoading={isDataLoading || !showStatisticBlocks}
            explanation={selectedChartItem?.chartLegend}
          />
        </div>
        {isDataLoading ||
        !selectedChartItem ||
        !selectedTimeRangeCallsCount ||
        !showStatisticBlocks ? (
          <EmptyBackgroundChart
            isLoading={isDataLoading}
            playbookItemsState={{
              hasPlaybookItemsOptions: showStatisticBlocks,
              errorText: 'Prospect Evaluation',
            }}
          />
        ) : (
          <InsightsAcrossCallsChart
            chartData={chartData}
            selectedMetric={selectedChartItem?.key}
            percentageView={true}
            type={PersonalInsightsChartViewType.Prospect}
          />
        )}
      </div>
    </>
  );
};
