import React from 'react';

import { DemoTranscriptSentences } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { formatTimestamp } from '@/utils/formatDate';
import { mapSpeakerletterToNumber } from '@/utils/speakerMapping';

export const DemoCallTranscript = ({ call }: any) => {
  const transcriptText = DemoTranscriptSentences.map((sentence) => {
    return {
      active: false,
      content: sentence.text.split(' ').map((word) => ({
        content: word,
        done: false,
        selected: false,
      })),
      done: false,
      selected: null,
      start: sentence.start,
      textContent: `${sentence.speaker}: ${sentence}`,
    };
  });

  return (
    <div className="mt-5">
      <div className="bg-white p-6 border-1 border-[#CBD2E0] rounded-2xl border mt-5">
        <div className="relative">
          <div
            className="absolute bottom-0 h-[69%] w-full"
            style={{
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
            }}
          >
            <div className="flex flex-col justify-end items-center h-full">
              <span className="text-dark font-semibold text-sm">
                Rest of transcript will populate here
              </span>
              <span className="text-dark font-normal text-sm mt-0.5">
                This is just a dummy data example 😉
              </span>
            </div>
          </div>
          <div>
            {transcriptText.map((text, index) => {
              const textSpeaker = text?.textContent.split(':')[0];
              return (
                <div
                  key={index}
                  className="mb-4"
                  id={text.selected ? 'selected' : ''}
                >
                  <div>
                    <div className="flex items-center gap-1 mb-1">
                      <div
                        className="rounded-full w-2 h-2"
                        style={{
                          backgroundColor:
                            textSpeaker === call.mainSpeaker
                              ? '#1774F8'
                              : '#2D3648',
                        }}
                      ></div>
                      <p className="text-sm font-semibold">
                        Speaker {mapSpeakerletterToNumber(textSpeaker)}{' '}
                        {call.mainSpeaker === textSpeaker && ' (Seller)'}
                      </p>
                      <span className="ml-1.5 text-[#717D96] text-sm">
                        {formatTimestamp(text.start)}
                      </span>
                    </div>
                    <p
                      className={`${text.active ? 'bg-[#1774F80F] rounded-l p-[4px] text-black' : ''} ${
                        text.selected
                          ? 'bg-[#1774F80F] rounded-[10px] py-1 px-2'
                          : ''
                      }`}
                    >
                      {text?.content.map((w, index) => (
                        <span
                          key={index}
                          className={`${w.done ? 'font-semibold' : ''} ${w.selected ? 'text-[#2C6CF6]' : ''}`}
                        >
                          {w?.content}{' '}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
