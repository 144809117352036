import { Spinner } from 'flowbite-react';
import { useShallow } from 'zustand/react/shallow';

import { DisplayRecordingCallDuration } from '@/widgets/RecordWidget/components/DisplayRecordingCallDuration';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

export const Record: React.FC = () => {
  const { isRecording, isCallUploading } = useCallRecordingStore(
    useShallow((state) => ({
      isRecording: state.isRecording,
      isCallUploading: state.isCallUploading,
    })),
  );

  const { isBotRecording } = useBotRecordingStore(
    useShallow((state) => ({
      isBotRecording: state.isBotRecording,
    })),
  );

  return (
    <div className="flex flex-col items-center gap-4">
      <Spinner className="fill-accent-blue h-16 w-16" />
      <div className="flex flex-col items-center gap-2">
        <p className="text-dark font-bold text-xl">
          {(isRecording || isBotRecording) && 'Recording...'}
          {isCallUploading && 'Saving your call...'}
        </p>
        <p className="text-main-gray-dark font-medium text-sm">
          {(isRecording || isBotRecording) && (
            <DisplayRecordingCallDuration
              format="formatTimestamp"
              recordingType={isBotRecording ? 'bot' : 'user'}
            />
          )}
          {isCallUploading &&
            'This will only take a few seconds, please do not close this page'}
        </p>
      </div>
    </div>
  );
};
