import React from 'react';

const InsightsMetricItem = ({ isSelected, metricInfo, setSelectedMetric }) => {
  return (
    <div
      className="mr-2 flex flex-row items-center pl-4 pt-2.5 pr-4 pb-2.5 rounded-md cursor-pointer"
      style={{
        backgroundColor: isSelected ? '#2c6cf60f' : '#F6F6F6',
        border: isSelected ? '1.5px solid #2C6CF6' : '1px solid #B9C7D7',
      }}
      onClick={() => setSelectedMetric(metricInfo)}
    >
      <div
        className="w-[6px] h-[6px] rounded-full"
        style={{
          backgroundColor: metricInfo.text.result.color,
        }}
      ></div>
      <span
        className="ml-1 text-sm"
        style={{
          color: isSelected ? '#1C1C1C' : '#5C6A82',
          fontWeight: isSelected ? 600 : 500,
        }}
      >
        {metricInfo.text.title}
      </span>
    </div>
  );
};

export default InsightsMetricItem;
