import React from 'react';
import { useDispatch } from 'react-redux';

import { useLiveFeedbackSlice } from '../../pages/LiveFeedbackPage/slice';

const EndLifeFeedbackConfirmation = ({ liveFeedbackChannel }) => {
  const dispatch = useDispatch();
  const { actions } = useLiveFeedbackSlice();

  const hideConfirmation = () => {
    dispatch(actions.setShowEndLiveFeedbackConfirmation(false));
  };

  const deleteCallAndClose = () => {
    liveFeedbackChannel.postMessage({ stopRecording: true });
  };

  return (
    <div className="absolute h-[100%] w-[100%] z-10">
      <div className="flex flex-col items-center justify-center h-[100%]">
        <span className="font-semibold text-sm text-[#FFF]">
          Stop recording?
        </span>
        <span className="mt-1.5 text-[10px] font-normal text-[#FFF]">
          Call must be at least 10min long to be eligible for feedback
        </span>
        <button
          className="mt-4 border-[0.5px] border-[#9B1A1A] rounded-3xl w-[280px] h-[32px] text-[#FFF] font-medium text-sm bg-gradient-to-r from-[#9F1C1C] to-[#670000]"
          onClick={deleteCallAndClose}
        >
          Delete Call
        </button>
        <button
          className="mt-1 border-[0.5px] border-[#ffffff52] rounded-3xl w-[280px] h-[32px] text-[#FFF] font-medium text-sm bg-gradient-to-r from-[#ffffff29] to-[#ffffff0d]"
          onClick={hideConfirmation}
        >
          Keep recording
        </button>
      </div>
    </div>
  );
};

export default EndLifeFeedbackConfirmation;
