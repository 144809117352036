import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.callsTab || initialState;

export const selectIsCollapsed = createSelector(
  [selectSlice],
  (state) => state.isCollapsed,
);
