import { Spinner } from 'flowbite-react';
import React from 'react';

export const TopQuestionsLoadingState: React.FC = () => {
  const emptyItems = new Array(4).fill(0);

  return (
    <>
      {emptyItems.map((el, index) => (
        <div
          key={index}
          className="flex flex-row items-center pl-4 pt-2 pb-3 border-1 border rounded-[20px] mb-1 bg-white border-[#ECECEC] shadow-feedback-qualitative-card-closed"
        >
          <div className="flex items-center h-[30px]">
            <Spinner className="fill-[#2C6CF6] h-5" />
          </div>
          <div className="ml-2 mt-1 w-[560px] h-3 rounded-[10px] bg-[#ECECEC]"></div>
        </div>
      ))}
    </>
  );
};
