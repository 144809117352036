import {
  CategoryScale,
  Chart,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
dayjs.extend(dayjsUtcPlugin);

import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { InsightsAcrossCallsChartDatasetsConst } from '@/components/InsightsAcrossCallsPage/consts/InsightsAcrossCallsChartDatasetsConst';
import { options } from '@/components/InsightsAcrossCallsPage/consts/InsightsAcrossCallsChartOptionsConst';
import { InsightsAcrossCallsChartRecommendedRangesConst } from '@/components/InsightsAcrossCallsPage/consts/InsightsAcrossCallsChartRecommendedRangesConst';
import { useInsightsAcrossCallsStore } from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsAcrossCallsTimeRangeOptionsValue } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';
import {
  CallDetailsItem,
  DateCallsInfo,
} from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsAcrossCallsChartMapper';
import {
  PersonalInsightsChartTooltip,
  PersonalInsightsChartViewType,
} from '@/components/InsightsAcrossCallsPage/PersonalInsightsChartTooltip';
import { useSubscription } from '@/hooks/useSubscription';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  annotationPlugin,
);

export type InsightsAcrossCallChartRawDataItemDetails = CallDetailsItem & {
  value: number;
};

export type InsightsAcrossCallChartRawDataItem = Omit<
  DateCallsInfo,
  'items'
> & {
  value: number;
  items: InsightsAcrossCallChartRawDataItemDetails[];
};

interface InsightsAcrossCallsChartParams {
  chartData: InsightsAcrossCallChartRawDataItem[];
  selectedMetric: string;
  type: PersonalInsightsChartViewType;
  percentageView?: boolean;
}

export const InsightsAcrossCallsChart: React.FC<
  InsightsAcrossCallsChartParams
> = ({
  type,
  percentageView = false,
  selectedMetric,
  chartData,
}: InsightsAcrossCallsChartParams) => {
  const { subscriptionData } = useSubscription();
  const { selectedDateOption } = useInsightsAcrossCallsStore();

  const [chartInstance, setChartInstance] = useState<Chart | null>(null);

  const [dataPoints, setDataPoints] = useState<{ x: number; y: number }[]>([]);

  const labels = chartData.map((el) => {
    if (!selectedDateOption) return el.dateGroupKey;

    switch (selectedDateOption.value) {
      case InsightsAcrossCallsTimeRangeOptionsValue.Week:
      case InsightsAcrossCallsTimeRangeOptionsValue.TwoWeeks:
      case InsightsAcrossCallsTimeRangeOptionsValue.Month:
      case InsightsAcrossCallsTimeRangeOptionsValue.ThreeMonths:
        return dayjs.utc(el.dateGroupKey).format('MMM DD');
    }
  });

  const datasets = chartData
    ? InsightsAcrossCallsChartDatasetsConst(chartData.map((el) => el.value))
    : [];

  const calculateDataPoints = useCallback(() => {
    if (!chartInstance) return;
    const meta = chartInstance.getDatasetMeta(0);
    const canvas = chartInstance.canvas;
    const rect = canvas.getBoundingClientRect();
    const chartPosition = { x: rect.left, y: rect.top };

    const points = meta.data.map((data) => ({
      x: data.x + chartPosition.x + window.scrollX,
      y: data.y + chartPosition.y + window.scrollY,
    }));

    setDataPoints(points);
  }, [chartInstance]);

  const chartOptions = options({
    percentageView,
    recommendedRangeStart:
      InsightsAcrossCallsChartRecommendedRangesConst[selectedMetric]?.[0],
    recommendedRangeEnd:
      InsightsAcrossCallsChartRecommendedRangesConst[selectedMetric]?.[1],
    onAnimationComplete: () => {
      calculateDataPoints();
    },
  });

  useEffect(() => {
    window.addEventListener('scroll', calculateDataPoints);

    return () => {
      window.removeEventListener('scroll', calculateDataPoints);
    };
  }, [calculateDataPoints]);

  return (
    <div className="h-[371px] mt-6">
      <Line
        options={{
          ...chartOptions,
          onResize: (chartInstance) => {
            setChartInstance(chartInstance);
          },
        }}
        data={{
          datasets,
          labels,
        }}
      />

      {dataPoints.map((point, index) => {
        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: point.x,
              top: point.y,
              transform: 'translate(-50%, -50%)',
            }}
            className="w-3.5 h-3.5 rounded-full flex items-center justify-center"
          >
            {!subscriptionData?.isDemoMode && (
              <WithHoverTooltip
                tooltip={
                  <PersonalInsightsChartTooltip
                    selectedMetric={selectedMetric}
                    type={type}
                    data={chartData[index]}
                  />
                }
                placement="bottom"
                tooltipContainerClassName="p-0 m-0"
              >
                <div className="w-3.5 h-3.5 rounded-full" />
              </WithHoverTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};
