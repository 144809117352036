import React, { useState } from 'react';
import {
  CheckCircle as CheckCircleIcon,
  Mail as MailIcon,
} from 'react-feather';

import { Button } from '@/components/Button/Button';

import { useSendPasswordResetEmail } from '../hooks/useSendPasswordResetEmail';

interface CheckInboxStepProps {
  email: string;
}

export const CheckInboxStep: React.FC<CheckInboxStepProps> = ({ email }) => {
  const [emailResent, setEmailResent] = useState(false);

  const { sendPasswordResetEmail } = useSendPasswordResetEmail();

  const resendEmail = () => {
    sendPasswordResetEmail(email);
    setEmailResent(true);
  };

  return (
    <>
      <MailIcon height={48} width={48} className="mb-6 text-accent-blue" />
      <h2 className="font-bold text-2xl mb-4">Check your inbox</h2>
      <p className="text-dark text-sm mb-4">
        We just sent a reset link to <b>{email}</b>
      </p>
      <p className="text-dark text-sm mb-6">
        Please check your spam folder if you can't find the email.
      </p>

      {emailResent ? (
        <div className="flex items-center text-accent-green">
          <CheckCircleIcon height={20} width={20} className="mr-1" />
          <span>We sent a new email</span>
        </div>
      ) : (
        <div>
          <span className="text-dark text-sm font-medium">
            Didn't get the email?
          </span>
          <Button onClick={resendEmail} variant="link">
            Resend
          </Button>
        </div>
      )}
    </>
  );
};
