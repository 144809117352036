import { Spinner } from 'flowbite-react';
import React from 'react';

export const UpdatingInsightsState: React.FC = () => {
  return (
    <div className="flex justify-center flex-col items-center">
      <div className="mb-4">
        <Spinner className="fill-[#2C6CF6] h-16 w-16" />
      </div>
      <div className="flex flex-col items-center">
        <p className="text-xl font-bold mb-2">
          Updating Insights based on your new sales playbook...
        </p>
        <p className="text-[#758DB9] font-normal text-sm">
          Your sales playbook has recently been updated, so we are
          re-calculating these values.
        </p>
      </div>
    </div>
  );
};
