import React, { useEffect, useState } from 'react';
import { Info, Square } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Api } from '@/api/api';
import { Button } from '@/components/Button/Button';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { MAX_CALL_LENGTH_MIN, MIN_CALL_LENGTH_MIN } from '@/constants';
import { callDetailsActions } from '@/pages/CallDetailsPage/slice';
import { homeActions } from '@/pages/HomePage/slice';
import cn from '@/utils/cn';
import { formatDurationMinSecond } from '@/utils/formatDate';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';

import { DisplayRecordingCallDuration } from './components/DisplayRecordingCallDuration';
import { CallTooShortModal } from './modals/CallTooShortModal';
import { StopRecordingModal } from './modals/StopRecordingModal';

export const BotRecordWidget: React.FC = () => {
  const {
    isBotRecording,
    elapsedSeconds,
    showStopRecordingModal,
    recordingStartedAt,
    closeStopRecordingModal,
    requestRecordingStop,
    stopRecording,
    reset,
    callId,
  } = useBotRecordingStore();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isStopCallLoading, setIsStopCallLoading] = useState(false);

  const getCallDurationSeconds = () => {
    return recordingStartedAt
      ? (new Date().getTime() - recordingStartedAt?.getTime()) / 1000
      : 0;
  };

  const isCallTooShort =
    getCallDurationSeconds() < Number(MIN_CALL_LENGTH_MIN) * 60;

  const finishRecording = async () => {
    console.log('callId', callId);

    if (!callId) return;

    stopRecording();
    setIsStopCallLoading(true);
    await Api.calendar.stopEventRecording(callId, isCallTooShort);
    setIsStopCallLoading(false);

    dispatch(homeActions.fetchCalls(null));
    dispatch(callDetailsActions.fetchCall({ id: callId }));

    reset();

    if (isCallTooShort) {
      navigate('/calls/');
    }
  };

  const secondsLeft = Number(MAX_CALL_LENGTH_MIN) * 60 - elapsedSeconds;

  useEffect(() => {
    if (secondsLeft < 0) {
      finishRecording();
    }
  }, [secondsLeft]);

  if (!isBotRecording) {
    return (
      <StopRecordingModal
        show={showStopRecordingModal}
        onClose={closeStopRecordingModal}
        onAccept={finishRecording}
        isLoading={isStopCallLoading}
        subtitle="Your call will be saved and analyzed and Assistant will leave the meeting"
      />
    );
  }

  const showCountdown = secondsLeft < 5 * 60;

  return (
    <>
      {showStopRecordingModal && (
        <>
          <CallTooShortModal
            show={isCallTooShort}
            onClose={closeStopRecordingModal}
            onAccept={finishRecording}
          />
          <StopRecordingModal
            show={!isCallTooShort}
            onClose={closeStopRecordingModal}
            onAccept={finishRecording}
            isLoading={isStopCallLoading}
            subtitle="Your call will be saved and analyzed and Assistant will leave the meeting"
          />
        </>
      )}
      <div className="z-30 fixed left-0 bottom-8">
        <div
          className={cn(
            'flex items-center gap-2 pl-10 py-4 bg-dark transition-all',
            showCountdown
              ? 'w-[296px] rounded-tr-[20px]'
              : 'rounded-r-[20px] w-[205px]',
          )}
        >
          <WithHoverTooltip tooltip="Stop Recording" placement="bottom-start">
            <Button
              variant="danger"
              className="w-10 h-10 p-0 rounded-full"
              onClick={requestRecordingStop}
            >
              <Square size={20} />
            </Button>
          </WithHoverTooltip>

          <div className="flex flex-col justify-center item-start">
            <div className="font-bold text-base	text-main-gray">
              Recording...
            </div>
            <div className="font-normal text-sm	text-[#B9C7D7]">
              <DisplayRecordingCallDuration
                format="formatTimestamp"
                recordingType="bot"
              />
            </div>
          </div>
        </div>
        <div
          className={cn(
            'w-full h-10 inline-flex items-center gap-1 text-gray-200 text-xs font-medium bg-[#252525] rounded-br-[20px] pl-10 py-3 transition-all',
            showCountdown ? 'visible opacity-100' : 'invisible opacity-0',
          )}
        >
          <img src="/hourglass-end-1.svg" className="h-3 w-3" alt="" />
          <span>
            Recording will stop in{' '}
            {formatDurationMinSecond(Math.max(secondsLeft, 0))}
          </span>
          <WithHoverTooltip
            tooltip="We only analyze calls up to 60min long"
            placement="right"
          >
            <Info size={12} className="cursor-pointer" />
          </WithHoverTooltip>
        </div>
      </div>
    </>
  );
};
