import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

export const initialState = {
  isCollapsed: false,
};

export const callsTabSlice = createSlice({
  name: 'callsTab',
  initialState,
  reducers: {
    setIsCollapsed(state, action) {
      state.isCollapsed = action.payload;
    },
  },
});

export const { actions: callsTabActions } = callsTabSlice;

export const useCallsTabSlice = () => {
  useInjectReducer({
    key: callsTabSlice.name,
    reducer: callsTabSlice.reducer,
  });
  return { actions: callsTabSlice.actions };
};
