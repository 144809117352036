import React from 'react';
import { AlertOctagon, CheckCircle, X } from 'react-feather';

import cn from '@/utils/cn';

import { IconButton } from '../Button/IconButton';

export interface ToastProps extends React.ComponentProps<'div'> {
  variant?: 'success' | 'error';
  title: string;
  subtitle?: string;
  onClose: () => void;
  renderRightSlot?: () => React.ReactNode;
}

export const Toast: React.FC<ToastProps> = ({
  variant = 'success',
  title,
  subtitle,
  onClose,
  renderRightSlot,
  className,
  ...restProps
}) => {
  return (
    <div
      className={cn(
        'bg-white rounded-lg flex justify-between items-center p-6 shadow-notification min-w-[800px]',
        className,
      )}
      {...restProps}
    >
      <div className="flex items-center gap-2">
        {variant === 'success' && (
          <CheckCircle size={24} className="text-accent-green" />
        )}
        {variant === 'error' && (
          <AlertOctagon size={24} className="text-accent-red" />
        )}
        <div className="flex flex-col gap-1">
          <p className="text-dark text-base font-extrabold min-w-[400px]">
            {title}
          </p>
          {subtitle && (
            <p className="text-main-gray-dark text-xs font-normal">
              {subtitle}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center w-fit">
        {renderRightSlot?.()}
      </div>
    </div>
  );
};
