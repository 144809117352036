import React from 'react';

import cn from '@/utils/cn';

export const getScoreLevel = (percent: number) => {
  if (percent >= 60) return 'good';
  if (percent >= 20) return 'mid';
  return 'bad';
};

export const getScoreTextColor = (percent: number) => {
  const level = getScoreLevel(percent);

  if (level === 'good') return 'text-accent-green';
  if (level === 'mid') return 'text-accent-yellow';
  return 'text-accent-red';
};

interface ScoreCircleProps {
  percent: number;
  showText?: boolean;
  size?: number;
  width?: number;
  className?: string;
}

export const ScoreCircle: React.FC<ScoreCircleProps> = ({
  percent,
  showText,
  size = 18,
  width = 2,
  className,
}) => {
  const strokeDasharray = 3.14 * size;
  const strokeDashoffset = strokeDasharray * ((100 - percent) / 100);

  const roundedPercent = Math.round(percent);

  const textColor = getScoreTextColor(percent);

  return (
    <div className={cn('relative', textColor, className)}>
      {showText && (
        <span
          className={cn(
            'absolute z-10 font-bold text-center',
            percent === 100 ? 'text-[6px]' : 'text-[8px]',
          )}
          style={{
            width: size,
            height: size,
            lineHeight: `${size}px`,
            letterSpacing: '-0.3px',
          }}
        >
          {roundedPercent}
        </span>
      )}

      <svg
        className="-rotate-90"
        width={size}
        height={size}
        viewBox={`${-width / 2} ${-width / 2} ${size + width} ${size + width}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r={size / 2}
          cx={size / 2}
          cy={size / 2}
          stroke="#ECECEC"
          strokeWidth={width}
          fill="transparent"
        />

        <circle
          r={size / 2}
          cx={size / 2}
          cy={size / 2}
          stroke="currentColor"
          strokeWidth={width}
          strokeLinecap="round"
          fill="transparent"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </div>
  );
};
