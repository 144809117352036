import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { MILLIS_PER_MIN } from '@/constants';

import App from './App';
import { initLocalVersion, reloadOnNewVersion } from './auto-update';
import reportWebVitals from './reportWebVitals';
import { configureAppStore } from './store/configureStore';

// Main redux setup boilerplate - connects app root component to redux store
const store = configureAppStore();
const root = ReactDOM.createRoot(document.getElementById('root')!);

const antiClickjackCss = `
    body {
      display:none !important;
    }
`;

root.render(
  <Provider store={store}>
    <style id="antiClickjack">{antiClickjackCss}</style>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Auto-update
initLocalVersion().then(() => {
  const checkVersionsIntervalMin =
    parseInt(process.env.REACT_APP_CHECK_VERSIONS_INTERVAL_MIN as string, 10) ||
    15;
  setInterval(reloadOnNewVersion, checkVersionsIntervalMin * MILLIS_PER_MIN);
});
