import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useSignUpSlice } from '../../pages/SignUpPage/slice';

const LinkToSignIn = () => {
  const dispatch = useDispatch();
  const { actions } = useSignUpSlice();
  const navigate = useNavigate();

  const toSignIn = () => {
    dispatch(actions.setEmailNotUsed(null));
    navigate('/auth/login');
  };

  return (
    <div onClick={toSignIn} className="text-[#152543] font-medium text-sm">
      <button className="text-[#152543] rounded-lg border-1 border border-[rgba(21, 37, 67, 0.16)] p-2.5 text-sm font-semibold">
        Sign in
      </button>
    </div>
  );
};

export default LinkToSignIn;
