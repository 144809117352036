import { useState } from 'react';

import { DemoMetrics } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { calculateAttributeDisplay } from '@/utils/attributeDisplay';
import cn from '@/utils/cn';

import FillerWordsList from '../FillerWordsList';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import PerformanceFeedback from './PerformanceFeedback';

// Call details component for call feedback (numeric / deterministic data cards)
const Feedback = ({ call }) => {
  const callMetrics = call.isDemoCall ? DemoMetrics : call.metrics;

  const mainSpeakerWordsPerMinute = callMetrics?.find(
    (analytic) => analytic.type === 'mainSpeakerWordsPerMinute',
  )?.value;
  const mainSpeakerTalkPercentage = callMetrics?.find(
    (analytic) => analytic.type === 'mainSpeakerTalkPercentage',
  )?.value;
  const longestProspectMonologueDuration = callMetrics?.find(
    (analytic) => analytic.type === 'longestMonologueDurationSeconds',
  )?.value;
  const questionsAsked = callMetrics?.find(
    (analytic) => analytic.type === 'questionsAsked',
  )?.value;
  const fillerWordsTotal = callMetrics
    ?.filter((analytics) => analytics?.type.startsWith('fillerWords'))
    .map((analytic) => analytic.value)
    .reduce((a, b) => Number(a) + Number(b), 0);

  const fillerWordsDetails = () => {
    return callMetrics
      ?.filter((analytics) => analytics?.type.startsWith('fillerWords'))
      .map((analytic) => {
        let keyName = analytic.type
          .split('fillerWords')
          .join('')
          .split('Count')[0];
        return {
          key: keyName,
          value: analytic.value,
        };
      })
      .reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {});
  };
  if (!call) return null;
  return (
    <div>
      <SectionTitle className="mt-8">Speech metrics</SectionTitle>
      <div className="flex gap-1 mt-1">
        <Card
          attribute="mainSpeakerWordsPerMinute"
          value={mainSpeakerWordsPerMinute}
        />
        <Card
          attribute="mainSpeakerTalkPercentage"
          value={mainSpeakerTalkPercentage}
        />
        <Card
          attribute="longestProspectMonologueDuration"
          value={longestProspectMonologueDuration}
        />
        <Card attribute="questionsAsked" value={questionsAsked} />
        <Card
          attribute="jargon"
          value={fillerWordsTotal}
          infoHover={true}
          hoverComponentData={fillerWordsDetails()}
          hoverComponent={FillerWordsList}
        />
      </div>

      <div className="mt-10">
        <PerformanceFeedback call={call} />
      </div>
    </div>
  );
};

export default Feedback;

const Card = ({
  attribute,
  value,
  infoHover,
  hoverComponent,
  hoverComponentData,
}) => {
  const Component = hoverComponent ?? null;
  const [showInfo, setShowInfo] = useState(false);
  const display = calculateAttributeDisplay(attribute, value);
  return (
    <div className="p-5 rounded-2xl bg-white border-1 border border-[#ECECEC] flex-1 flex flex-col gap-2 justify-between items-start relative shadow-feedback-quantitative-card">
      <p className="flex items-center gap-1 text-dark font-semibold text-sm">
        {display?.displayAttributeName}
      </p>
      <span
        className={cn(
          'text-lg font-semibold',
          display?.color && `text-${display.color}`,
        )}
      >
        {display?.highlightText}
      </span>
      <div
        className={cn(
          'text-xs text-main-gray-dark',
          infoHover && 'cursor-pointer underline hover:text-[#0654C2]',
        )}
        onMouseEnter={() => infoHover && setShowInfo(true)}
        onMouseLeave={() => infoHover && setShowInfo(false)}
      >
        <p>{display?.displayAttributeDetails}</p>
      </div>
      {showInfo && (
        <div
          className="rounded-[10px] border border-1 border-[#ECECEC] text-[#4F4F4F] px-2 py-3 text-base absolute -bottom-12 right-0 shadow-feedback-quantitative-card"
          style={{ backgroundColor: '#fff' }}
        >
          <Component fillerWords={hoverComponentData} />
        </div>
      )}
    </div>
  );
};
