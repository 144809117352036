export const ChevronDownIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M2.5 4.25L5 6.75L7.5 4.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
