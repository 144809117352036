import React, { useState } from 'react';

import { Button } from '@/components/Button/Button';
import { PasswordInput } from '@/components/Input/PasswordInput';
import { Label } from '@/components/Label/Label';
import { validatePassword } from '@/utils/validators/validatePassword';

import { useResetPassword } from '../hooks/useResetPassword';

interface SetPasswordStepProps {
  resetPasswordToken: string;
  onPasswordChange: (password: string) => void;
}

export const SetPasswordStep: React.FC<SetPasswordStepProps> = ({
  resetPasswordToken,
  onPasswordChange,
}) => {
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const { resetPassword, isPending } = useResetPassword();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validatePassword(password)) {
      setError('Your password must contain 8-20 characters');
      return;
    }

    setError(null);

    await resetPassword({ resetPasswordToken, password });

    onPasswordChange(password);
  };

  return (
    <>
      <h2 className="font-bold text-2xl mb-4">Set new password</h2>
      <p className="text-dark text-sm mb-6">
        Choose a new password for signing into SilkChart
      </p>

      <form onSubmit={handleSubmit} className="w-[400px]">
        <Label isError={!!error} htmlFor="new_password_input">
          Password
        </Label>
        <PasswordInput
          onChange={handleChange}
          name="password"
          id="new_password_input"
          placeholder="Enter new password"
          className="mb-4"
          isError={!!error}
        />

        {error && (
          <p className="mb-4 text-accent-red font-medium text-sm text-center">
            {error}
          </p>
        )}

        <Button className="w-full" isLoading={isPending} type="submit">
          Set new password
        </Button>
      </form>
    </>
  );
};
