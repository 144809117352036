import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { selectUser } from '@/pages/HomePage/slice/selectors';

import AverageInsightsBlock from '../../components/LeadershipDashboardPage/AverageInsights/AverageInsightsBlock';
import AveragePerformanceBlock from '../../components/LeadershipDashboardPage/AveragePerformance/AveragePerformanceBlock';
import RepsAttentionBlock from '../../components/LeadershipDashboardPage/RepsAttention/RepsAttentionBlock';
import NoCallsBanner from '../../components/LeadershipPage/shared/NoCallsBanner';
import TimeRangeOptions from '../../components/LeadershipPage/TimeRangeOptionsComponent';
import { useLeadershipDashboardSlice } from './slice';
import {
  selectDashboardPageLoading,
  selectShowNoCallsBanner,
} from './slice/selectors';

const LeadershipDashboardPage = () => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipDashboardSlice();
  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const showNoCallsBanner = useSelector(selectShowNoCallsBanner);
  const dashboardPageLoading = useSelector(selectDashboardPageLoading);

  useEffect(() => {
    dispatch(actions.setDashboardPageLoading(true));
  }, []);

  return (
    <div className="relative">
      {dashboardPageLoading && (
        <div
          className="absolute flex justify-center items-center w-[100%] bg-white z-10"
          style={{
            height: '100%',
          }}
        >
          <ClipLoader color="rgb(37 99 235)" />
        </div>
      )}
      <div
        className="pb-14 pl-[72px] pr-[72px] overflow-y-auto overflow-x-hidden"
        style={{
          height: 'calc(100vh - 70px)',
        }}
      >
        <div className="mt-8 flex flex-row justify-between items-center">
          <span className="text-2xl font-semibold	text-[#000]">Dashboard</span>
          <TimeRangeOptions className="h-[40px] w-[135px] bg-accent-blue flex justify-center items-center h-[100%]" />
        </div>
        {showNoCallsBanner && !useMock && <NoCallsBanner />}
        <AveragePerformanceBlock />
        {/*<PersonalRecommendationsBlock />*/}
        <AverageInsightsBlock />
        <RepsAttentionBlock />
      </div>
    </div>
  );
};

export default LeadershipDashboardPage;
