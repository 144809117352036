import { toast as sonnerToast } from 'sonner';

import { Button } from '../Button/Button';
import { Toast, ToastProps } from './Toast';

type ShowToastProps = Omit<ToastProps, 'onClose' | 'renderRightSlot'> & {
  renderRightSlot?: (params: { closeToast: () => void }) => React.ReactNode;
};

export const toast = {
  custom({ renderRightSlot, ...restProps }: ShowToastProps) {
    return sonnerToast.custom(
      (t) => {
        const closeToast = () => {
          sonnerToast.dismiss(t);
        };

        return (
          <Toast
            onClose={closeToast}
            renderRightSlot={() => renderRightSlot?.({ closeToast })}
            {...restProps}
          />
        );
      },
      { duration: Infinity, dismissible: false },
    );
  },

  error({
    title = 'Oops, something went wrong!',
    subtitle = 'Please try again or contact support@silkchart.com',
    renderRightSlot = ({ closeToast }) => {
      return (
        <Button variant="secondary" onClick={closeToast}>
          OK
        </Button>
      );
    },
    ...restProps
  }: Partial<ShowToastProps> = {}) {
    return this.custom({
      variant: 'error',
      title,
      subtitle,
      renderRightSlot,
      ...restProps,
    });
  },

  success({
    renderRightSlot = ({ closeToast }) => {
      return (
        <Button variant="secondary" onClick={closeToast}>
          Done
        </Button>
      );
    },
    ...restProps
  }: Partial<ShowToastProps> & Pick<ShowToastProps, 'title' | 'subtitle'>) {
    return this.custom({
      variant: 'success',
      renderRightSlot,
      ...restProps,
    });
  },
};
