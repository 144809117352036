import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { leadershipTeamSaga } from './saga';

export const initialState = {
  teamPerformance: null,
  activePlaybookMetrics: [],
  teamTableLoading: false,
  teamTablePageLoading: true,
  teamTableColumnsLoading: false,
  showTeamTableColumnOptions: [],
};

export const leadershipTeamSlice = createSlice({
  name: 'leadershipTeam',
  initialState,
  reducers: {
    updateTeamColumnsOptions(state, action) {},
    updateTeamColumnsOptionsFailed(state, action) {},
    fetchTeamColumnsOptions(state, action) {
      state.teamTableLoading = true;
    },
    fetchTeamColumnsOptionsSuccess(state, action) {
      state.teamTableLoading = false;
      state.showTeamTableColumnOptions = action.payload;
    },
    fetchTeamColumnsOptionsFailed(state, action) {
      state.teamTableLoading = false;
    },
    fetchTeamPerformanceSuccess(state, action) {
      state.teamPerformance = action.payload.teamPerformance;
      state.activePlaybookMetrics = action.payload.activePlaybookMetrics;
      state.teamTableLoading = false;
      state.teamTablePageLoading = false;
    },
    setTeamTablePageLoading(state, action) {
      state.teamTablePageLoading = action.payload;
    },
    updateShowColumnOption(state, action) {},
    toggleShowColumnOption(state, action) {
      const option = state.showTeamTableColumnOptions.find(
        (el) =>
          el.key === action.payload.key && el.type === action.payload.type,
      );

      if (!option) return;

      option.show = action.payload.show;
    },
    resetShowTeamTableColumnOptions(state, action) {
      state.showTeamTableColumnOptions = [];
    },
    resetActivePlaybookMetrics(state, action) {
      state.activePlaybookMetrics = [];
    },
    resetTeamPerformance(state, action) {
      state.teamPerformance = null;
    },

    // actions
    fetchTeamPerformance(state, action) {
      state.teamTableLoading = true;
    },
    fetchTeamTableColumnOptions(state, action) {
      state.teamTableLoading = true;
    },
  },
});

export const { actions: leadershipTeamActions } = leadershipTeamSlice;

export const useLeadershipTeamSlice = () => {
  useInjectReducer({
    key: leadershipTeamSlice.name,
    reducer: leadershipTeamSlice.reducer,
  });
  useInjectSaga({ key: leadershipTeamSlice.name, saga: leadershipTeamSaga });
  return { actions: leadershipTeamSlice.actions };
};
