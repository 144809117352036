import React from 'react';

import { RecordButton } from '@/components/Recording/shared/RecordButton';

export interface NewMeetingRecordingItemProps {
  setAddCalendarEventView: (flag: boolean) => void;
}

export const NewMeetingRecordingItem: React.FC<
  NewMeetingRecordingItemProps
> = ({ setAddCalendarEventView }) => {
  const handleRecord = () => {
    setAddCalendarEventView(true);
  };

  return (
    <div className="flex flex-row justify-between items-center py-4 px-5 border border-1 border-main-gray rounded-[20px]">
      <span className="text-dark tracking-[-0.14px] font-semibold text-sm">
        New recording
      </span>

      <RecordButton variant="secondary" onClick={handleRecord} />
    </div>
  );
};
