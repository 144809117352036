import { put, takeLatest } from 'redux-saga/effects';

import { signUpActions as actions } from '.';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function* doCheckIfUserExists(action) {
  try {
    const response = yield fetch(backendURL + '/api/auth/checkUser', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: action.payload.email,
      }),
    });
    let data = yield response.json();

    if (response.status === 200) {
      yield put(
        actions.checkUserSuccess({
          exists: data.exists,
          verifiedEmail: action.payload.email,
        }),
      );
    } else {
      yield put(actions.checkUserFailed(data));
    }
  } catch {
    yield put(actions.checkUserFailed('Send invitation Failed'));
  }
}

export function* signUpSaga() {
  yield takeLatest(actions.checkIfEmailNotUsed.type, doCheckIfUserExists);
}
