import React from 'react';

import { eventTracker } from '@/amplitude/eventTracker';
import { MetricsEvaluation } from '@/components/InsightsAcrossCallsPage/MetricsEvaluation';
import { PlaybookEvaluation } from '@/components/InsightsAcrossCallsPage/PlaybookEvaluation';
import { ProspectEvaluation } from '@/components/InsightsAcrossCallsPage/ProspectEvaluation';
import { TopQuestions } from '@/components/InsightsAcrossCallsPage/TopQuestions';

export enum InsightsAcrossCallsTabs {
  Seller = 'seller',
  Prospect = 'prospect',
  Metrics = 'metrics',
  TopQuestions = 'top-questions',
}

export const insightsTabs = [
  {
    key: InsightsAcrossCallsTabs.Seller,
    panel: <PlaybookEvaluation />,
    title: 'Seller Performance',
    trackClick: eventTracker.insights.sellerEvaluationTabClick,
  },
  {
    key: InsightsAcrossCallsTabs.Prospect,
    panel: <ProspectEvaluation />,
    title: 'Prospect Evaluation',
    trackClick: eventTracker.insights.prospectEvaluationTabClick,
  },
  {
    key: InsightsAcrossCallsTabs.Metrics,
    panel: <MetricsEvaluation />,
    title: 'Speech Metrics',
    trackClick: eventTracker.insights.metricsTabClick,
  },
  {
    key: InsightsAcrossCallsTabs.TopQuestions,
    panel: <TopQuestions />,
    title: 'Objection Handling',
    trackClick: eventTracker.insights.topQuestionsTabClick,
  },
];
