export class TeamTableColumnsFilterHelper {
  static defaultOptions = [
    {
      key: 'mainSpeakerWordsPerMinute',
      name: 'Speech Pace',
    },
    {
      key: 'mainSpeakerTalkPercentage',
      name: 'Seller Speaking',
    },
    {
      key: 'longestMonologueDurationSeconds',
      name: 'Longest Monologue',
    },
    {
      key: 'questionsAsked',
      name: 'Question Asked',
    },
    {
      key: 'fillerWordsCount',
      name: 'Filler Words',
    },
    {
      key: 'playbookFulfilledPercentage',
      name: 'Sales Playbook',
    },
  ];

  static filterOptions(options) {
    return options
      .map((option) => {
        const defaultColumn = this.defaultOptions.find(
          (el) => el.key === option.key,
        );

        if (defaultColumn) {
          option.name = defaultColumn.name;
        }

        return option;
      })
      .filter((el) => el !== null);
  }
}
