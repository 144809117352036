import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '@/pages/HomePage/slice/selectors';

const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const StripePricingTable: React.FC = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!user || !user.organizationUserId) {
    return <></>;
  }

  return React.createElement('stripe-pricing-table', {
    'pricing-table-id': STRIPE_PRICING_TABLE_ID,
    'publishable-key': STRIPE_PUBLISHABLE_KEY,
    'client-reference-id': user.organizationUserId,
    'customer-email': user.email,
  });
};
