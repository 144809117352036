import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { HelpModal } from '@/components/HelpModal/HelpModal';
import { RevertImpersonationBanner } from '@/components/HomePage/RevertImpersonationBanner';
import { SubscribeModal } from '@/components/subscription/SubscribeModal/SubscribeModal';
import { useUploadModalStore } from '@/components/UploadCallModal/hooks/useUploadModalStore';
import { UploadCallModal } from '@/components/UploadCallModal/UploadCallModal';
import { BotRecordWidget } from '@/widgets/RecordWidget/BotRecordWidget';

import Header from '../../components/Shared/Header';
import { RecordWidget } from '../../widgets/RecordWidget/RecordWidget';

// Page for logged in homepage
// Displays insights and calls tabs, and manages call upload
const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isUploadModalOpen, closeUploadModal } = useUploadModalStore();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/calls');
    }
  }, [location.pathname, navigate]);

  return (
    <div className="min-h-screen bg-white">
      <SubscribeModal />
      {isUploadModalOpen && (
        <UploadCallModal
          isOpen={isUploadModalOpen}
          onClose={closeUploadModal}
        />
      )}
      <RecordWidget />
      <BotRecordWidget />
      <HelpModal />
      <div>
        <Header />
        <RevertImpersonationBanner />
        <Outlet />
      </div>
    </div>
  );
};

export default HomePage;
