import { useEffect } from 'react';
const backendURL = process.env.REACT_APP_BACKEND_URL;

export function UnimpersonatePage() {
  useEffect(() => {
    const revokeImpersonateToken = async () => {
      const response = await fetch(backendURL + '/api/users/unimpersonate', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      });
      const body = await response.json();
      localStorage.setItem('authToken', body.jwt);
      window.location.href = '/';
    };
    revokeImpersonateToken();
  });

  return <></>;
}
