import React from 'react';
import { Trash } from 'react-feather';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';

interface CallTooShortModalProps {
  show: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const CallTooShortModal: React.FC<CallTooShortModalProps> = ({
  show,
  onClose,
  onAccept,
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="min-w-[448px]">
        <Modal.Title>This call will not be saved</Modal.Title>
        <Modal.Subtitle>
          Call must be at least 10min long to be eligible for feedback
        </Modal.Subtitle>

        <div className="flex justify-end gap-1 mt-6">
          <Button variant="secondary" className="w-fit" onClick={onClose}>
            Keep recording
          </Button>
          <Button variant="danger" className="w-fit" onClick={onAccept}>
            <Trash size={16} />
            Delete Call
          </Button>
        </div>
      </div>
    </Modal>
  );
};
