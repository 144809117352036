import React from 'react';

import { ChartLegendEmpty } from '@/components/InsightsAcrossCallsPage/chart/ChartLegendEmpty';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';

interface ChartRangeLegendProps {
  explanation: string;
  isLoading?: boolean;
}

export const ChartRangeLegend: React.FC<ChartRangeLegendProps> = ({
  explanation,
  isLoading = false,
}: ChartRangeLegendProps) => {
  const { selectedTimeRangeCallsCount }: InsightsAcrossCallsStore =
    useInsightsAcrossCallsStore();

  return (
    <div className="flex flex-row items-center h-5">
      {isLoading || !selectedTimeRangeCallsCount ? (
        <ChartLegendEmpty />
      ) : (
        <>
          <div className="w-4 h-4 bg-[#2C6CF60F] border-[1px] border-[#2C6CF652] rounded-sm"></div>
          <span className="ml-1 text-[#5C6A82] font-normal text-sm">
            {explanation}
          </span>
        </>
      )}
    </div>
  );
};
