import React from 'react';

import cn from '@/utils/cn';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;

  leftSlot?: React.ReactNode;
  leftSlotContainerClassName?: string;

  rightSlot?: React.ReactNode;
  rightSlotContainerClassName?: string;
}

export const Input: React.FC<InputProps> = ({
  isError,
  leftSlot,
  leftSlotContainerClassName,
  rightSlot,
  rightSlotContainerClassName,
  className,
  ...restProps
}) => {
  return (
    <div className="relative w-full">
      <input
        {...restProps}
        className={cn(
          'peer h-10 px-4 w-full font-medium rounded-lg border border-main-gray outline-none focus:border-accent-blue placeholder-gray-dark placeholder-opacity-75 placeholder:text-base placeholder:font-normal',
          isError && 'border-accent-red focus:border-accent-red',
          leftSlot && 'pl-10',
          rightSlot && 'pr-10',
          className,
        )}
      />
      {leftSlot && (
        <div
          className={cn(
            'h-5 w-5 absolute top-2.5 left-3 peer-focus:text-accent-blue',
            isError && 'text-accent-red peer-focus:text-accent-red',
            leftSlotContainerClassName,
          )}
        >
          {leftSlot}
        </div>
      )}
      {rightSlot && (
        <div
          className={cn(
            'h-5 w-5 absolute top-2.5 right-3 peer-focus:text-accent-blue',
            isError && 'text-accent-red peer-focus:text-accent-red',
            rightSlotContainerClassName,
          )}
        >
          {rightSlot}
        </div>
      )}
    </div>
  );
};
