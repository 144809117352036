import { create } from 'zustand';

import { Api } from '../../api/api';
import { IPlaybook } from '../../interfaces/playbook.interface';
import { IPlaybookItem } from '../../interfaces/playbook-item.interface';
import { isSellerEvaluation } from './utils';

interface CustomPlaybookState {
  playbooks: IPlaybook[];
  selected: IPlaybookItem[];
  remaining: IPlaybookItem[];
  all: IPlaybookItem[];
  categories: string[];
  sellerEvaluationLimit: number;
  isInitialStateFetched: boolean;
  isSortableScriptLoaded: boolean;
  saving: boolean;
}

interface CustomPlaybookActions {
  fetchInitialState: () => Promise<void>;
  updateCustomPlaybook: (slugs: string[]) => Promise<{ playbookId: string }>;
  setActivePlaybook: (activePlaybookId: string) => Promise<void>;
  setIsSortableScriptLoaded: (isSortableScriptLoaded: boolean) => void;
  addItem: (item: IPlaybookItem) => void;
  removeItem: (item: IPlaybookItem) => void;
  setSaving: (saving: boolean) => void;
}

const isCustom = (playbook: IPlaybook) => playbook.name.endsWith('custom');

const getInitialState = (): CustomPlaybookState => ({
  playbooks: [],
  selected: [],
  remaining: [],
  all: [],
  categories: ['Seller Evaluation', 'Prospect Evaluation'],
  sellerEvaluationLimit: 15,
  isInitialStateFetched: false,
  isSortableScriptLoaded: false,
  saving: false,
});

export const useCustomPlaybookStore = create<
  CustomPlaybookState & CustomPlaybookActions
>((set, get) => ({
  ...getInitialState(),
  fetchInitialState: async () => {
    const [allItems, customPlaybookItems, availablePlaybooks] =
      await Promise.all([
        Api.playbook.getItems(),
        Api.playbook.getCustomPlaybook(),
        Api.playbook.getAvailablePlaybooks(),
      ]);

    const isItemInPlaybook = (item: IPlaybookItem) =>
      customPlaybookItems.findIndex(
        (playbookItem) => item.id === playbookItem.id,
      ) !== -1;

    const remainingItems = allItems.filter((item) => !isItemInPlaybook(item));

    set({
      playbooks: availablePlaybooks.filter((playbook) => !isCustom(playbook)),
      all: allItems,
      remaining: remainingItems,
      selected: customPlaybookItems,
      isInitialStateFetched: true,
    });
  },
  updateCustomPlaybook: async (slugs: string[]) => {
    const { all } = get();

    const items = slugs.map(
      (slug) => all.find((item) => item.slug === slug) as IPlaybookItem,
    );

    return await Api.playbook.updateCustomPlaybook(items);
  },
  setActivePlaybook: async (activePlaybookId: string) => {
    await Api.playbook.setActivePlaybook(activePlaybookId);
  },
  setIsSortableScriptLoaded: (isSortableScriptLoaded: boolean) => {
    set({ isSortableScriptLoaded });
  },
  addItem: (item: IPlaybookItem) => {
    const { selected: items, sellerEvaluationLimit: limit } = get();

    const count = items.filter(({ category }) =>
      isSellerEvaluation(category),
    ).length;

    if (isSellerEvaluation(item.category) && count >= limit) return;

    set((state) => ({
      selected: state.selected.find((selected) => selected.id === item.id)
        ? state.selected
        : [...state.selected, item],
    }));
  },
  removeItem: (item: IPlaybookItem) => {
    set((state) => ({
      selected: state.selected.filter((selected) => selected.id !== item.id),
    }));
  },
  setSaving: (saving: boolean) => set({ saving }),
}));
