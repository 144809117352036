import { useGoogleLogin } from '@react-oauth/google';

import { Api } from '@/api/api';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { toast } from '@/components/Toast/toaster';

export const useGoogleLoginWrapper = () => {
  const {
    setShowCalendarConnectedScreen,
    setAreCredentialsValid,
    setIsLoading,
    setIsCalendarConnected,
  } = useRecordingCalendarStore();

  return {
    googleLogin: useGoogleLogin({
      onSuccess: async (tokenResponse) => {
        if (
          !tokenResponse.scope
            .split(' ')
            .includes(
              'https://www.googleapis.com/auth/calendar.events.readonly',
            )
        ) {
          toast.error({
            title: 'Calendar access denied',
            subtitle: 'Please grant access to your calendar',
          });

          return;
        }

        setIsLoading(true);
        await Api.auth.googleAuthRedirect(tokenResponse.code);
        setIsLoading(false);

        setShowCalendarConnectedScreen(true);
        setAreCredentialsValid(true);
        setIsCalendarConnected(true);
      },
      flow: 'auth-code',
      scope:
        'email profile https://www.googleapis.com/auth/calendar.events.readonly',
      enable_serial_consent: false,
    }),
  };
};
