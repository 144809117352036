import React from 'react';

import cn from '@/utils/cn';

import { LoaderIcon } from '../Shared/icons/LoaderIcon';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'link' | 'danger' | 'ghost';
  isLoading?: boolean;
  contentWrapperClassName?: string;
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  children,
  className,
  contentWrapperClassName,
  disabled,
  isLoading,
  type = 'button',
  ...restProps
}) => {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={cn(
        'h-9 px-4 py-2 rounded-lg transition-all duration-300 font-semibold text-sm text-center relative',
        variant === 'primary' &&
          'bg-accent-blue text-white hover:bg-accent-dark-blue',
        variant === 'secondary' && 'bg-light-gray hover:bg-main-gray text-dark',
        variant === 'link' &&
          'w-fit h-fit px-1 py-0.5 text-accent-blue hover:text-accent-dark-blue duration-75',
        variant === 'danger' &&
          'bg-accent-red text-white hover:bg-accent-dark-red',
        variant === 'ghost' &&
          'text-main-gray-dark bg-transparent hover:bg-light-gray active:bg-main-gray',

        disabled &&
          'bg-[#EDEDED] hover:bg-[#EDEDED] text-main-gray-dark text-opacity-40',
        className,
      )}
      {...restProps}
    >
      <div
        className={cn(
          'inline-flex w-full h-full justify-center items-center transition-all gap-1',
          isLoading && 'opacity-0',
          contentWrapperClassName,
        )}
      >
        {children}
      </div>
      {isLoading && (
        <div className="absolute w-4 h-4 top-1/2 left-1/2 -ml-2 -mt-2">
          <LoaderIcon className="animate-spin" />
        </div>
      )}
    </button>
  );
};
