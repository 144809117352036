import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectShowTeamTableColumnOptions } from '@/pages/LeadershipTeam/slice/selectors';

import ToggleColumn from './ToggleColumn';

const groupColumnsByTypeAndAddTitle = (options) => {
  const predefinedGroupedSections = {
    performanceMetrics: {
      items: [],
      title: 'Performance Metrics',
    },
    playbookOptions: {
      items: [],
      title: 'Sales Playbook',
    },
  };

  options.forEach((column) => {
    const section = predefinedGroupedSections[column.type];
    if (!section) return;

    section.items.push(column);
  });

  return predefinedGroupedSections;
};

const EditColumnsMenu = () => {
  const showTeamTableColumnOptions = useSelector(
    selectShowTeamTableColumnOptions,
  );

  const [groupedOptions, setGroupedOptions] = useState(undefined);

  useEffect(() => {
    if (!showTeamTableColumnOptions) return;

    setGroupedOptions(
      groupColumnsByTypeAndAddTitle(showTeamTableColumnOptions),
    );
  }, [showTeamTableColumnOptions]);

  if (!groupedOptions) return;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <div className="flex items-center cursor-pointer rounded-[10px] bg-[#2C6CF6] px-3 py-2.5">
            <span className="text-[#FFF] text-sm font-semibold">
              Edit columns
            </span>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-100 right-0 mt-0.5 w-[244px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="pb-2">
            {Object.keys(groupedOptions).map((group, index) => (
              <>
                <div className="ml-4 mb-1 mt-3 flex">
                  <span className="text-[#5C6A82] text-[10px] font-medium">
                    {groupedOptions[group].title}
                  </span>
                </div>
                {groupedOptions[group].items.map((el, index) => (
                  <Menu.Item
                    as="div"
                    key={index}
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="p-2 ml-2 flex flex-row justify-between">
                      <span className="text-sm text-[#1C1C1C] font-medium">
                        {el.shortName || el.name}
                      </span>
                      <ToggleColumn item={el} />
                    </div>
                  </Menu.Item>
                ))}
                {index < Object.keys(groupedOptions).length - 1 && (
                  <div className="bg-[#D9D9D9] h-[1px]"></div>
                )}
              </>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default EditColumnsMenu;
