import React from 'react';
import { CheckCircle as CheckCircleIcon } from 'react-feather';
import { useDispatch } from 'react-redux';

import { Button } from '@/components/Button/Button';
import { homeActions } from '@/pages/HomePage/slice';

export const SuccessStep: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <>
      <CheckCircleIcon
        height={48}
        width={48}
        className="mb-6 text-accent-green"
      />
      <h2 className="font-bold text-2xl mb-4">
        You’ve successfully set a new password!{' '}
      </h2>
      <p className="text-dark text-sm mb-6">
        Get started with SilkChart below.
      </p>

      <Button
        className="w-[400px]"
        onClick={() => {
          dispatch(
            homeActions.updatePasswordSuccess({
              token: localStorage.getItem('accessToken'),
            }),
          );
          // TODO: update routing
          window.location.href = '/';
        }}
      >
        Sign In
      </Button>
    </>
  );
};
