import React from 'react';

import { ChartLegendEmpty } from '@/components/InsightsAcrossCallsPage/chart/ChartLegendEmpty';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';

interface ChartLineLegendProps {
  explanation?: string;
  isLoading?: boolean;
}

export const ChartLineLegend: React.FC<ChartLineLegendProps> = ({
  explanation,
  isLoading = false,
}: ChartLineLegendProps) => {
  const { selectedTimeRangeCallsCount }: InsightsAcrossCallsStore =
    useInsightsAcrossCallsStore();

  return (
    (explanation || !selectedTimeRangeCallsCount) && (
      <div className="flex flex-row items-center h-5">
        {isLoading || !selectedTimeRangeCallsCount ? (
          <ChartLegendEmpty />
        ) : (
          <>
            <div className="w-6 h-1.5 bg-[#2C6CF6] rounded-[4px]"></div>
            <span className="ml-1 text-[#5C6A82] font-normal text-sm">
              {explanation}
            </span>
          </>
        )}
      </div>
    )
  );
};
