import { API } from '@/api/base';
import { Response } from '@/api/response';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  getCalendarEvents: backendURL + '/api/calendar/events',
};

export interface CalendarEventResponse {
  id: string;
  isRecordingEnabled: boolean;
  title: string;
  start: string; // Date
  end: string; // Date
}

export const getCalendarEvents = () => async () => {
  const response = await API(endpoints.getCalendarEvents, {
    method: 'GET',
  });

  const result: Response<CalendarEventResponse[]> = await response.json();

  return result.data;
};
