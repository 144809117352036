import React from 'react';
import { Calendar } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { useGoogleLoginWrapper } from '@/components/Recording/hooks/useGoogleLoginWrapper';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { SyncingCalendarLoader } from '@/components/Recording/SyncingCalendarLoader';

export const ConnectYourCalendar: React.FC = () => {
  const { isLoading, setIsCalendarConnectionSkipped } =
    useRecordingCalendarStore();

  const { googleLogin } = useGoogleLoginWrapper();

  const handleSkip = () => {
    setIsCalendarConnectionSkipped(true);
  };

  if (isLoading) {
    return <SyncingCalendarLoader />;
  }

  return (
    <div className="flex flex-col py-6 px-8">
      <div className="mb-4 flex items-center justify-center">
        <div className="h-16 w-16 rounded-full bg-accent-blue/10 flex items-center justify-center">
          <Calendar color={'#2C6CF6'} size={32} />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-2 mb-6">
        <span className="text-dark text-xl font-bold">
          Connect your work calendar
        </span>
        <span className="text-main-gray-dark text-sm font-medium">
          Adding a calendar allows us to automatically import your meetings to
          SilkChart and record them
        </span>
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <Button
          className="w-[208px] px-3 py-2.5 flex items-center justify-center"
          variant="primary"
          onClick={googleLogin}
        >
          <Calendar color={'#FFFFFF'} size={16} />
          <span className="text-white text-[13px] font-semibold">
            Connect Google Calendar
          </span>
        </Button>
        <Button
          className="w-[208px] px-3 py-2.5 flex items-center justify-center"
          variant="secondary"
          onClick={handleSkip}
        >
          <span className="text-dark text-[13px] font-semibold">
            Skip for now
          </span>
        </Button>
      </div>
    </div>
  );
};
