import React from 'react';
import { AlertTriangle, Check } from 'react-feather';

import { Banner } from '@/components/Banner/Banner';
import { Button } from '@/components/Button/Button';
import { useHelpModalStore } from '@/components/HelpModal/useHelpModalStore';
import { useInsightsAcrossCallsStore } from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { RecordLink } from '@/components/Shared/RecordLink';
import { UploadLink } from '@/components/Shared/UploadLink';
import { useModal } from '@/hooks/useModal';
import { useSubscription } from '@/hooks/useSubscription';

import { useSubscribeModalStore } from '../SubscribeModal/useSubscribeModalStore';

interface SubscriptionBannerProps {
  className?: string;
}

export const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({
  className,
}) => {
  const {
    subscriptionData,
    subscriptionEndsToday,
    callsLimitReached,
    isPending: isSubscriptionDataLoading,
    showPaymentSuccess,
    setShowPaymentSuccess,
    subscriptionDaysLeft,
    showDefaultTrialBanner,
    setShowDefaultTrialBanner,
    openStripeCustomerPortal,
  } = useSubscription();

  const { openSubscribeModal } = useSubscribeModalStore();

  const { isOpen, close } = useModal(true);

  if (!isOpen || isSubscriptionDataLoading) {
    return <></>;
  }

  if (showPaymentSuccess) {
    return (
      <SuccessBanner
        className={className}
        onActionClick={() => {
          setShowPaymentSuccess(false);
          close();
        }}
      />
    );
  }

  if (!subscriptionData) {
    return <></>;
  }

  const { isTrial, isCanceled, isExpired, subscriptionPlanName, callsLimit } =
    subscriptionData;

  const trialEndsToday = isTrial && subscriptionEndsToday;
  const trialExpired = isTrial && isExpired;

  if (isCanceled) {
    return (
      <CanceledSubscriptionBanner
        className={className}
        onActionClick={openSubscribeModal}
      />
    );
  }

  if (callsLimitReached) {
    return (
      <ReachedCallsLimitBanner
        className={className}
        onActionClick={openStripeCustomerPortal}
        planName={subscriptionPlanName}
        callsLimit={callsLimit}
      />
    );
  }

  if (isTrial) {
    if (trialExpired) {
      return (
        <ExpiredTrialBanner
          className={className}
          onActionClick={openSubscribeModal}
        />
      );
    }

    if (trialEndsToday) {
      return (
        <TrialEndsTodayBanner
          className={className}
          onActionClick={openSubscribeModal}
        />
      );
    }

    if (subscriptionData.isDemoMode) {
      return <DemoModeActiveBanner className={className} />;
    }

    if (showDefaultTrialBanner) {
      return (
        <DefaultTrialBanner
          className={className}
          onActionClick={openSubscribeModal}
          onClose={() => {
            setShowDefaultTrialBanner(false);
            close();
          }}
          subscriptionDaysLeft={subscriptionDaysLeft}
        />
      );
    }
  }

  return <></>;
};

interface CustomBannerProps {
  className?: string;
  onClose?: () => void;
  onActionClick?: () => void;
}

const DemoModeActiveBanner: React.FC<CustomBannerProps> = ({ className }) => {
  return (
    <Banner
      className={className}
      title={"You're in demo mode of Insights"}
      subtitle={
        <span>
          <UploadLink /> or <RecordLink /> your first call to see your own
          Insights
        </span>
      }
      leftSlot={
        <Banner.IconContainer variant="primary">
          <img height={18} width={18} src="/emoji/eyes.png" />
        </Banner.IconContainer>
      }
    />
  );
};

const DefaultTrialBanner: React.FC<
  CustomBannerProps & { subscriptionDaysLeft: number }
> = ({ className, onActionClick, onClose, subscriptionDaysLeft }) => {
  return (
    <Banner
      className={className}
      title={`Try SilkChart free for ${subscriptionDaysLeft} days!`}
      subtitle={
        <p>
          <Button className="pl-0" variant="link" onClick={onActionClick}>
            Upgrade now
          </Button>
          to continue to enjoy the best of SilkChart after free trial
        </p>
      }
      leftSlot={
        <Banner.IconContainer variant="primary">
          <img height={18} width={18} src="/emoji/hourglass.png" />
        </Banner.IconContainer>
      }
      onClose={onClose}
    />
  );
};

const ReachedCallsLimitBanner: React.FC<
  CustomBannerProps & { planName: string; callsLimit: number }
> = ({ className, onActionClick, planName, callsLimit }) => {
  return (
    <Banner
      className={className}
      variant="danger"
      title="You have reached the limit of uploaded or recorded calls"
      subtitle={
        <p>
          You’ve used all of the {callsLimit} calls included in your {planName}{' '}
          plan. Please
          <Button variant="link" onClick={onActionClick}>
            upgrade
          </Button>
          to upload or record more calls.
        </p>
      }
      leftSlot={
        <Banner.IconContainer variant="danger">
          <AlertTriangle size={18} />
        </Banner.IconContainer>
      }
      rightSlot={<Button onClick={onActionClick}>Upgrade plan</Button>}
    />
  );
};

const TrialEndsTodayBanner: React.FC<CustomBannerProps> = ({
  className,
  onActionClick,
}) => {
  return (
    <Banner
      className={className}
      title="Your trial ends today!"
      subtitle="Subscribe now to keep coaching yourself to better sales performance."
      leftSlot={
        <Banner.IconContainer variant="primary">
          <img height={18} width={18} src="/emoji/hourglass.png" />
        </Banner.IconContainer>
      }
      rightSlot={<Button onClick={onActionClick}>Subscribe</Button>}
    />
  );
};

const ExpiredTrialBanner: React.FC<CustomBannerProps> = ({
  className,
  onActionClick,
}) => {
  const { openHelpModal } = useHelpModalStore();
  return (
    <Banner
      variant="danger"
      className={className}
      title={
        <p className="flex items-center">
          Your free trial expired
          <img src="/emoji/weary.png" className="h-5 w-5 ml-1" />
        </p>
      }
      subtitle={
        <p>
          Please
          <Button variant="link" onClick={onActionClick}>
            Subscribe
          </Button>
          to continue analyzing and recording sales calls or
          <Button variant="link" onClick={openHelpModal}>
            Contact us
          </Button>
          if you have any questions.
        </p>
      }
      leftSlot={
        <Banner.IconContainer variant="danger">
          <AlertTriangle size={18} />
        </Banner.IconContainer>
      }
      rightSlot={<Button onClick={onActionClick}>Get full access</Button>}
    />
  );
};

const SuccessBanner: React.FC<CustomBannerProps> = ({
  className,
  onActionClick,
}) => {
  return (
    <Banner
      variant="success"
      className={className}
      title="Congratulations!"
      subtitle="You can continue to enjoy full access to SilkChart"
      leftSlot={
        <Banner.IconContainer variant="success">
          <img src="/emoji/tada.png" height="18px" width="18px" />
        </Banner.IconContainer>
      }
      rightSlot={
        <Button onClick={onActionClick}>
          <Check size={16} /> Done
        </Button>
      }
    />
  );
};

const CanceledSubscriptionBanner: React.FC<CustomBannerProps> = ({
  className,
  onActionClick,
}) => {
  const { openHelpModal } = useHelpModalStore();
  return (
    <Banner
      variant="danger"
      className={className}
      title="You do not have a subscription, so you can’t upload or record new calls"
      subtitle={
        <p>
          Please
          <Button variant="link" onClick={onActionClick}>
            Subscribe
          </Button>
          to continue analyzing and recording sales calls or
          <Button variant="link" onClick={openHelpModal}>
            Contact us
          </Button>
          if you have any questions.
        </p>
      }
      leftSlot={
        <Banner.IconContainer variant="danger">
          <AlertTriangle size={18} />
        </Banner.IconContainer>
      }
      rightSlot={<Button onClick={onActionClick}>Get full access</Button>}
    />
  );
};
