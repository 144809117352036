import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import { RecordButton } from '@/components/Shared/RecordButton';
import { UploadButton } from '@/components/Shared/UploadButton';
import cn from '@/utils/cn';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

interface DemoCallActionButtonsProps extends React.ComponentProps<'div'> {}

export const DemoCallActionButtons: React.FC<DemoCallActionButtonsProps> = ({
  className,
  ...restProps
}) => {
  const { isRecording } = useCallRecordingStore(
    useShallow((state) => ({
      isRecording: state.isRecording,
    })),
  );

  return (
    <div className={cn('flex flex-col gap-1 w-full', className)} {...restProps}>
      <UploadButton
        title="Upload call"
        className="w-full text-dark bg-main-gray hover:bg-[#E0E2E4]"
        variant="secondary"
      />
      <RecordButton
        title="Record call"
        className={cn(
          !isRecording && 'text-dark',
          'w-full bg-main-gray hover:bg-[#E0E2E4]',
        )}
      />
    </div>
  );
};
