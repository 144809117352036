import React from 'react';

import { useGoogleLoginWrapper } from '@/components/Recording/hooks/useGoogleLoginWrapper';

export const ConnectCalendarTooltip: React.FC = () => {
  const { googleLogin } = useGoogleLoginWrapper();

  return (
    <div className="flex flex-row items-center justify-center bg-accent-blue/10 p-4">
      <span className="text-dark text-sm">
        <span
          className="text-accent-blue text-sm font-semibold cursor-pointer"
          onClick={googleLogin}
        >
          Connect Calendar
        </span>{' '}
        to automatically import your meetings
      </span>
    </div>
  );
};
