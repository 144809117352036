import React from 'react';

import { DemoTopQuestions } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { SectionTitle } from '@/components/SectionTitle/SectionTitle';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import { formatTimestamp } from '@/utils/formatDate';

interface DemoQuestionsParams {
  callId: string;
}

// TODO: reuse existing <Questions> component
export const DemoQuestions: React.FC<DemoQuestionsParams> = ({ callId }) => {
  const { navigateToTranscript } = useNavigateToTranscript();

  return (
    <div>
      <div key="prospects-top-questions" className="h-full col-span-10">
        <SectionTitle className="mb-2 mt-5">
          Prospect’s top questions and objections
        </SectionTitle>
        <div className="flex flex-col gap-1.5">
          {DemoTopQuestions.map(({ id, summary, answer, timestamp }) => (
            <div key={id}>
              <CollapsibleItem title={summary}>
                <div className="bg-[#F6F6F6] rounded-[14px] p-[14px]">
                  <div className="flex flex-row mb-2 items-center">
                    <p className="text-xs text-[#5C6A82] font-semibold">
                      Your answer
                    </p>
                    <p
                      className="text-[#2C6CF6] text-xs font-normal cursor-pointer flex ml-[3px]"
                      onClick={() =>
                        navigateToTranscript({
                          callId,
                          text: answer,
                          timestamp: Number(timestamp),
                        })
                      }
                    >
                      at {formatTimestamp(timestamp)}
                    </p>
                  </div>
                  <p className="text-black text-sm font-semibold">{answer}</p>
                </div>
              </CollapsibleItem>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
