import { ChevronLeft, Menu } from 'react-feather';

import cn from '@/utils/cn';

interface CallsListTogglerProps {
  isOpen: boolean;
  toggle: () => void;
}

export const CallsListToggleButton: React.FC<CallsListTogglerProps> = ({
  isOpen,
  toggle,
}) => {
  return (
    <div className="inline-block mt-10 ml-8 z-50 select-none cursor-pointer">
      <div
        onClick={toggle}
        className={cn(
          isOpen
            ? 'bg-main-gray hover:bg-[#E0E2E4]'
            : 'bg-light-gray hover:bg-main-gray',
          'flex gap-1 justify-center items-center rounded-lg-1.5 py-2.5 px-3 h-9',
        )}
      >
        {isOpen && <ChevronLeft size={16} />}
        {!isOpen && <Menu size={16} />}
        <span className="text-dark text-sm font-semibold">Calls</span>
      </div>
    </div>
  );
};
