import { useEffect, useRef } from 'react';

// Helper function for using timers - calls first argument function after second argument delay

const useInterval = (callback: () => void, delay?: number) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (!delay) return;

    function tick() {
      savedCallback.current?.();
    }

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
