import { AlertTriangle, Plus } from 'react-feather';
import { useDispatch } from 'react-redux';

import { Button } from '@/components/Button/Button';
import { useRequestPermissionAgain } from '@/components/CallDetailsPage/hooks/use-request-permission-again';
import { useCallDetailsSlice } from '@/pages/CallDetailsPage/slice';
import { useHomeSlice } from '@/pages/HomePage/slice';

export interface RequestPermissionFailedProps {
  callId: string;
}

export const RequestPermissionFailed: React.FC<
  RequestPermissionFailedProps
> = ({ callId }) => {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { actions: callDetailsActions } = useCallDetailsSlice();

  const { requestBotRecordingPermission, isPending } =
    useRequestPermissionAgain();

  const requestPermissionAgain = async () => {
    const updatedCall = await requestBotRecordingPermission(callId);

    if (updatedCall) {
      dispatch(actions.fetchCalls(null));
      dispatch(callDetailsActions.fetchCall({ id: updatedCall.id }));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center bg-accent-yellow/10 h-[72px] w-[72px] rounded-full mb-4">
        <AlertTriangle size={40} className="text-accent-yellow" />
      </div>
      <div className="flex flex-col items-center justify-center mb-6">
        <span className="mb-2 text-xl text-dark font-semibold">
          Host didn’t accept request to record meeting
        </span>
        <span className="mb-1 text-sm text-main-gray-dark font-medium">
          Please allow SilkChart Assistant to record the meeting
        </span>
      </div>
      <div className="flex flex-row items-center gap-1">
        <Button
          variant="primary"
          onClick={requestPermissionAgain}
          isLoading={isPending}
        >
          <Plus size={16} className="text-white" />
          <span className="text-white">Request Recording Permission Again</span>
        </Button>
      </div>
    </div>
  );
};
