import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { useHomeSlice } from '@/pages/HomePage/slice';
import {
  selectCallTranscript,
  selectTranscriptLoading,
} from '@/pages/HomePage/slice/selectors';
import { formatTimestamp } from '@/utils/formatDate';
import { mapSpeakerletterToNumber } from '@/utils/speakerMapping';
import { validParamId } from '@/utils/validParamId';

const getStartEnd = (str, sub) => [
  str.indexOf(sub),
  str.indexOf(sub) + sub?.length + 1,
];

// Component for displaying transcript of calls
const CallTranscript = ({ call, currentAudioTime, selectedBlock }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const callTranscript = useSelector(selectCallTranscript);
  const loading = useSelector(selectTranscriptLoading);

  const listRef = useRef(null);

  useEffect(() => {
    if (validParamId(id)) {
      dispatch(actions.fetchCallTranscript(id));
    }
  }, [id]);

  const formatTranscript = () => {
    const utterances = callTranscript?.utterances;
    if (!utterances) return [];

    return utterances.map((utterance) => {
      const [startIndex, endIndex] = getStartEnd(
        utterance.text,
        selectedBlock?.text,
      );

      let num = 0;

      return {
        active:
          utterance.start / 1000 <= currentAudioTime &&
          utterance.end / 1000 >= currentAudioTime,
        textContent: `${utterance.speaker}: ${utterance.words}`,
        content: utterance.words.map((w) => {
          num += w.text.length + 1;

          const selected =
            utterance.start === selectedBlock?.timestamp
              ? ((startIndex === 0 && num >= startIndex) || num > startIndex) &&
                num <= endIndex
              : false;

          return {
            content: w.text,
            done: w.end / 1000 < currentAudioTime,
            selected,
          };
        }),
        start: utterance.start,
        done: utterance.end / 1000 < currentAudioTime,
        selected: utterance.start === selectedBlock?.timestamp,
      };
    });
  };

  const transcriptText = formatTranscript();

  useEffect(() => {
    if (!selectedBlock || loading || currentAudioTime) return;

    const timer = setTimeout(() => {
      listRef.current?.querySelector('#selected')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedBlock, loading, currentAudioTime]);

  if (loading) {
    return (
      <div className="flex justify-center">
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  return (
    <div>
      {callTranscript && (
        <div ref={listRef}>
          {transcriptText.map((text, index) => {
            const textSpeaker = text?.textContent.split(':')[0];
            return (
              <div
                key={index}
                className="mb-4"
                id={text.selected ? 'selected' : ''}
              >
                <div>
                  <div className="flex items-center gap-1 mb-1">
                    <div
                      className="rounded-full w-2 h-2"
                      style={{
                        backgroundColor:
                          textSpeaker === call.mainSpeaker
                            ? '#1774F8'
                            : '#2D3648',
                      }}
                    ></div>
                    <p className="text-sm font-semibold">
                      Speaker {mapSpeakerletterToNumber(textSpeaker)}{' '}
                      {call.mainSpeaker === textSpeaker && ' (Seller)'}
                    </p>
                    <span className="ml-1.5 text-[#717D96] text-sm">
                      {formatTimestamp(text.start)}
                    </span>
                  </div>
                  <p
                    className={`${text.active ? 'bg-[#1774F80F] rounded-l p-[4px] text-black' : ''} ${
                      text.selected
                        ? 'bg-[#1774F80F] rounded-[10px] py-1 px-2'
                        : ''
                    }`}
                  >
                    {text?.content.map((w, index) => (
                      <span
                        key={index}
                        className={`${w.done ? 'font-semibold' : ''} ${w.selected ? 'text-[#2C6CF6]' : ''}`}
                      >
                        {w?.content}{' '}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CallTranscript;
