import { AlertTriangle, Plus } from 'react-feather';
import { useDispatch } from 'react-redux';

import { Button } from '@/components/Button/Button';
import { useInviteAssistantAgain } from '@/components/CallDetailsPage/hooks/use-invite-assistant-again';
import { useCallDetailsSlice } from '@/pages/CallDetailsPage/slice';
import { useHomeSlice } from '@/pages/HomePage/slice';

export interface InviteAssistantAgainProps {
  callId: string;
}

export const InviteAssistantAgain: React.FC<InviteAssistantAgainProps> = ({
  callId,
}) => {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { actions: callDetailsActions } = useCallDetailsSlice();

  const { inviteAssistantAgain, isPending } = useInviteAssistantAgain();

  const handleClick = async () => {
    const updatedCall = await inviteAssistantAgain(callId);

    if (updatedCall) {
      dispatch(actions.fetchCalls(null));
      dispatch(callDetailsActions.fetchCall({ id: updatedCall.id }));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center bg-accent-yellow/10 h-[72px] w-[72px] rounded-full mb-4">
        <AlertTriangle size={40} className="text-accent-yellow" />
      </div>
      <div className="flex flex-col items-center justify-center mb-6">
        <span className="mb-2 text-xl text-dark font-semibold">
          SilkChart Assistant can’t join the meeting
        </span>
        <span className="mb-1 text-sm text-main-gray-dark font-medium">
          SilkChart Assistant was not allowed to enter by host or was removed by
          the host
        </span>
      </div>
      <div className="flex flex-row items-center gap-1">
        <Button variant="primary" onClick={handleClick} isLoading={isPending}>
          <Plus size={16} className="text-white" />
          <span className="text-white">Invite Assistant Again</span>
        </Button>
      </div>
    </div>
  );
};
