import { create } from 'zustand';

import { TimeRangeOption } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';
import { DateCallsInfo } from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsAcrossCallsChartMapper';

export interface InsightsAcrossCallsStore {
  hasAtLeastOneCall: boolean | null;
  setHasAtLeastOneCall: (hasAtLeastOneCall: boolean) => void;
  rawChartData: DateCallsInfo[];
  setRawChartData: (rawChartData: DateCallsInfo[]) => void;
  isPageDataLoading: boolean;
  setIsPageDataLoading: (isPageDataLoading: boolean) => void;
  isChartLoading: boolean;
  setIsChartLoading: (isChartLoading: boolean) => void;
  isFirstLoading: boolean;
  setIsFirstLoading: (isFirstLoading: boolean) => void;
  selectedTimeRangeCallsCount: number | null;
  setSelectedTimeRangeCallsCount: (selectedTimeRangeCallsCount: number) => void;
  selectedDateOption: TimeRangeOption | null;
  setSelectedDateOption: (selectedDateOption: TimeRangeOption) => void;
  callsInProcessingState: number | null;
  setCallsInProcessingState: (callsInProcessingState: number) => void;
  organizationLastReanalyzedIndex: number | null;
  setOrganizationLastReanalyzedIndex: (
    organizationLastReanalyzedIndex: number,
  ) => void;
  callsWithoutAllPlaybookResultsCount: number | null;
  setCallsWithoutAllPlaybookResultsCount: (
    callsWithoutAllPlaybookResultsCount: number,
  ) => void;
  callsWithAllPlaybookResultsCount: number | null;
  setCallsWithAllPlaybookResultsCount: (
    callsWithAllPlaybookResultsCount: number,
  ) => void;
}

export const useInsightsAcrossCallsStore = create<InsightsAcrossCallsStore>(
  (set) => ({
    hasAtLeastOneCall: null,
    setHasAtLeastOneCall: (hasAtLeastOneCall: boolean) =>
      set(() => ({ hasAtLeastOneCall })),
    rawChartData: [],
    setRawChartData: (rawChartData: DateCallsInfo[]) =>
      set(() => ({ rawChartData })),
    isChartLoading: true,
    setIsChartLoading: (isChartLoading: boolean) =>
      set(() => ({ isChartLoading })),
    isPageDataLoading: true,
    setIsPageDataLoading: (isPageDataLoading: boolean) =>
      set(() => ({ isPageDataLoading })),
    isFirstLoading: true,
    setIsFirstLoading: (isFirstLoading: boolean) =>
      set(() => ({ isFirstLoading })),
    selectedDateOption: null,
    setSelectedDateOption: (selectedDateOption: TimeRangeOption) =>
      set(() => ({
        selectedDateOption,
        totalCallsCount: null,
        hasAtLeastOneCall: null,
        selectedTimeRangeCallsCount: null,
      })),
    selectedTimeRangeCallsCount: null,
    setSelectedTimeRangeCallsCount: (selectedTimeRangeCallsCount: number) =>
      set(() => ({ selectedTimeRangeCallsCount })),
    callsInProcessingState: null,
    setCallsInProcessingState: (callsInProcessingState: number) =>
      set(() => ({ callsInProcessingState })),
    organizationLastReanalyzedIndex: null,
    setOrganizationLastReanalyzedIndex: (
      organizationLastReanalyzedIndex: number,
    ) => set(() => ({ organizationLastReanalyzedIndex })),
    callsWithoutAllPlaybookResultsCount: null,
    setCallsWithoutAllPlaybookResultsCount: (
      callsWithoutAllPlaybookResultsCount: number,
    ) => set(() => ({ callsWithoutAllPlaybookResultsCount })),
    callsWithAllPlaybookResultsCount: null,
    setCallsWithAllPlaybookResultsCount: (
      callsWithAllPlaybookResultsCount: number,
    ) => set(() => ({ callsWithAllPlaybookResultsCount })),
  }),
);
