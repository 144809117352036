// ========================================
// Unit conversion
// ========================================
export const BYTES_PER_MB = 2 ** 20;
export const MILLIS_PER_MIN = 60 * 1000;
export const MILLIS_PER_SEC = 1000;

// ========================================
// Record flow
// ========================================
export const MIN_CALL_LENGTH_MIN =
  process.env.REACT_APP_MIN_CALL_LENGTH_MIN || 10;
export const MAX_CALL_LENGTH_MIN =
  process.env.REACT_APP_MAX_CALL_LENGTH_MIN || 60;
export const NEAR_MAX_CALL_LENGTH_MIN =
  process.env.REACT_APP_NEAR_MAX_CALL_LENGTH_MIN || 50;
export const UPLOAD_TYPE = {
  direct: 'direct',
  part: 'part',
};
export const MIN_PART_MB = 5.5;
export const THRESHOLD_PART_UPLOAD_MB = 6.0;
export const RECORD_TIMESLICE = 1_000;
export const RECORD_TICK = 1_000;
export const LIVE_FEEDBACK_CHUNK_DURATION = 120_000;

// ========================================
// Hardcoded URLs
// ========================================
export const CHROME_DOWNLOAD_URL = 'https://www.google.com/chrome/';
export const CHROME_SUPPORT_MEDIA_DEVICES_URL =
  'https://support.google.com/chrome/answer/2693767';

// ========================================
// Call processing
// ========================================
// Consider a bit more than 10 minutes to account for uploading / retrying jobs
export const EXPECTED_MAX_TIME_TO_PROCESS_CALL_MS = 15 * 60 * 1_000;

// ========================================
// Playbook Key Drivers
// ========================================
export const KEYDRIVER_ITEMS = ['objection', 'demoPersonalizedOnNeed'];
