import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useHomeSlice } from '../../pages/HomePage/slice';
import { selectCallAudio } from '../../pages/HomePage/slice/selectors';
import { validParamId } from '../../utils/validParamId';

// Audio player component for playback of call

const AudioPlayer = ({ setCurrentAudioTime, call }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();

  const onTimeUpdate = (event) => {
    setCurrentAudioTime(event.target.currentTime);
  };

  useEffect(() => {
    if (validParamId(id)) {
      dispatch(actions.fetchCallAudio(id));
    }
  }, [id]);

  const callAudio = useSelector(selectCallAudio);
  return (
    <audio
      controls
      src={callAudio}
      className="w-full"
      onTimeUpdate={onTimeUpdate}
    />
  );
};

export default AudioPlayer;
