import React from 'react';

const RecommendedMinimumLine = ({ repsCount }) => {
  if (!repsCount) return;

  const lineHeightPx = 48 + (repsCount - 1) * 64;
  const spaceBetweenDashesPx = 5;
  const dashesLengthPx = 9;

  const fullDashesCount = Math.floor(
    lineHeightPx / (dashesLengthPx + spaceBetweenDashesPx),
  );

  return (
    <div
      className="absolute flex flex-col justify-between"
      style={{
        height: `${lineHeightPx}px`,
        width: '2px',
        left: 'calc(80% - 91px)',
        top: '25px',
      }}
    >
      {new Array(fullDashesCount).fill(undefined).map((el, index) => (
        <div
          key={index}
          className="bg-[#2C6CF6] rounded-full"
          style={{ height: `${dashesLengthPx}px`, width: '2px' }}
        ></div>
      ))}
    </div>
  );
};

export default RecommendedMinimumLine;
