import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AudioPlayer from '../HomePage/AudioPlayer';
import CallTranscript from '../HomePage/CallTranscript';

// Call details page for displaying transcript
const Transcript = ({ call }) => {
  const [currentAudioTime, setCurrentAudioTime] = useState(0);

  const [searchParams] = useSearchParams();

  const timestamp = searchParams.get('timestamp');
  const text = searchParams.get('text');

  const selectedBlock = {
    timestamp: Number(timestamp),
    text,
  };

  return (
    <div className="mt-5">
      <div className="bg-white p-1 border-1 border-[#CBD2E0] rounded-2xl border">
        <AudioPlayer call={call} setCurrentAudioTime={setCurrentAudioTime} />
      </div>
      <div className="bg-white p-6 border-1 border-[#CBD2E0] rounded-2xl border mt-5">
        <CallTranscript
          call={call}
          selectedBlock={selectedBlock}
          currentAudioTime={currentAudioTime}
        />
      </div>
    </div>
  );
};

export default Transcript;
