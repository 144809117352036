import React, { useState } from 'react';
import { Info } from 'react-feather';

import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import cn from '@/utils/cn';

interface GraphValueBlockProps {
  order: number;
  items: number;
  displayValue: string;
  valueColor: string;
  title: string;
  hoverDescription?: string;
  selected: boolean;
  valueExplanationText?: string;
  clickHandler: (order: number) => void;
  emptyState?: boolean;
  isLoading?: boolean;
  maxItemsInView: number;
}

export const ChartValueBlock: React.FC<GraphValueBlockProps> = ({
  order,
  items,
  hoverDescription,
  title,
  displayValue,
  selected,
  valueColor,
  valueExplanationText,
  clickHandler,
  emptyState = false,
  isLoading = false,
  maxItemsInView,
}: GraphValueBlockProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={() => clickHandler(order)}
      className={cn(
        'snap-start relative flex grow-0 shrink-0 bg-[#B9C7D71A]',
        selected && 'bg-white',
        isHovered && 'bg-white',
        items === 1 && 'rounded-tl-[20px] rounded-tr-[20px]',
      )}
      style={{
        width: `${(1 / items) * 100}%`,
        minWidth: `${100 / maxItemsInView}%`,
      }}
    >
      <div
        className={cn(
          'flex flex-col relative px-4 py-5 w-full',
          isLoading ? 'gap-[18px]' : 'gap-[10px]',
        )}
      >
        <div className="flex flex-row relative items-center">
          <span
            className={cn(
              'text-xs font-medium truncate whitespace-nowrap',
              selected ? 'text-dark' : 'text-main-gray-dark',
            )}
          >
            {title}
          </span>
          {hoverDescription && isHovered && (
            <WithHoverTooltip
              tooltip={
                <span className="inline-block w-auto max-w-[212px]">
                  {hoverDescription}
                </span>
              }
            >
              <Info size={14} className="ml-0.5 text-main-gray-2" />
            </WithHoverTooltip>
          )}
        </div>
        <div
          className="flex flex-row"
          style={{
            alignItems: isLoading ? 'center' : 'end',
          }}
        >
          <span
            className={
              'text-xl font-medium tracking-[-0.6px] leading-[1.25rem]'
            }
            style={{
              color: emptyState
                ? selected
                  ? '#1C1C1C'
                  : '#5C6A82'
                : valueColor,
            }}
          >
            {isLoading ? (
              <div className="w-20 h-3 rounded-[10px] bg-main-gray"></div>
            ) : (
              displayValue
            )}
          </span>
          {valueExplanationText && !isLoading && (
            <span className="ml-1 text-[10px] font-normal tracking-[-0.3px] text-main-gray-dark">
              {' ' + valueExplanationText}
            </span>
          )}
        </div>
      </div>
      {selected && (
        <div className="absolute bottom-0 h-1 w-full bg-[#2C6CF6]"></div>
      )}
    </div>
  );
};
