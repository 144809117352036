export const UsersIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        d="M8.5 11V10C8.5 9.46957 8.28929 8.96086 7.91421 8.58579C7.53914 8.21071 7.03043 8 6.5 8H2.5C1.96957 8 1.46086 8.21071 1.08579 8.58579C0.710714 8.96086 0.5 9.46957 0.5 10V11"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6C5.60457 6 6.5 5.10457 6.5 4C6.5 2.89543 5.60457 2 4.5 2C3.39543 2 2.5 2.89543 2.5 4C2.5 5.10457 3.39543 6 4.5 6Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10.9995V9.99945C11.4997 9.55632 11.3522 9.12584 11.0807 8.77561C10.8092 8.42538 10.4291 8.17524 10 8.06445"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.06445C8.43021 2.1746 8.81152 2.4248 9.08382 2.77561C9.35612 3.12641 9.50392 3.55787 9.50392 4.00195C9.50392 4.44604 9.35612 4.87749 9.08382 5.2283C8.81152 5.5791 8.43021 5.8293 8 5.93945"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
