import { BarChart2 } from 'react-feather';

import { RecordLink } from '@/components/Shared/RecordLink';
import { UploadLink } from '@/components/Shared/UploadLink';

const EmptyState = () => {
  return (
    <div className="flex justify-center flex-col items-center h-full">
      <BarChart2 size={64} color="#1774F8" />
      <p className="text-lg font-bold">No calls to analyze yet</p>
      <p className="text-[#758DB9] font-medium text-sm">
        <RecordLink /> or <UploadLink /> your first call to see performance
        insights
      </p>
    </div>
  );
};

export default EmptyState;
