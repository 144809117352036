import React from 'react';
import { Upload } from 'react-feather';

import { eventTracker } from '@/amplitude/eventTracker';
import { useUploadModalStore } from '@/components/UploadCallModal/hooks/useUploadModalStore';
import { useSubscription } from '@/hooks/useSubscription';

import { Button } from '../Button/Button';
import { WithHoverTooltip } from '../HoverTooltip/WithHoverTooltip';

interface UploadButtonProps {
  title?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  title = 'Upload',
  className,
  variant = 'primary',
}: UploadButtonProps) => {
  const { openUploadModal } = useUploadModalStore();

  const { canCreateNewCalls, isPending: subscriptionLoading } =
    useSubscription();

  const handleUploadClick = () => {
    openUploadModal();
    eventTracker.upload.uploadButtonClick();
  };

  const isLoading = subscriptionLoading;

  return (
    <WithHoverTooltip
      tooltip={
        canCreateNewCalls ? undefined : 'Upgrade your plan to upload new calls'
      }
      placement="bottom-end"
    >
      <Button
        onClick={handleUploadClick}
        variant={variant}
        className={className}
        isLoading={isLoading}
        disabled={!canCreateNewCalls}
      >
        <Upload size={16} />
        <span className="font-semibold text-[13px]">{title}</span>
      </Button>
    </WithHoverTooltip>
  );
};
