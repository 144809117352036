let localVersion = null;

const getLocalVersion = () => localVersion;
const setLocalVersion = (version) => (localVersion = version);
const removeLocalVersion = () => (localVersion = null);

const getCDNVersion = async () => {
  try {
    const response = await fetch(window.location.origin, {
      method: 'HEAD',
    });
    const etag = response.headers.get('ETag');
    return etag;
  } catch (err) {
    return null;
  }
};

export const initLocalVersion = async () => {
  if (getLocalVersion()) return;

  const version = await getCDNVersion();
  if (version) setLocalVersion(version);
};

export const reloadOnNewVersion = async () => {
  const currentVersion = getLocalVersion();
  const latestVersion = await getCDNVersion();

  if (currentVersion && latestVersion && currentVersion !== latestVersion) {
    removeLocalVersion();
    window.location.href = window.location.href + '?t=' + new Date().getTime();
  }
};
