import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Clock } from 'react-feather';

import { Api } from '@/api/api';
import { CalendarEventResponse } from '@/api/calendar/get-calendar-events';
import { SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE } from '@/components/Recording/AddAssistantSuccess';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { RecordButton } from '@/components/Recording/shared/RecordButton';
import cn from '@/utils/cn';
import { getCookie } from '@/utils/cookie';

export interface MeetingItemProps {
  calendarEvent: CalendarEventResponse;
  onClose: () => void;
}

const Ellipse = () => (
  <div className="w-1 h-1 bg-main-gray-dark rounded-full opacity-[.24]"></div>
);

export const MeetingItem: React.FC<MeetingItemProps> = ({
  calendarEvent,
  onClose,
}) => {
  const { setIsEventAdded } = useRecordingCalendarStore();

  const [isLoading, setIsLoading] = useState(false);

  const isMeetingInProgress = dayjs(new Date()).isAfter(calendarEvent.start);

  const handleRecord = async () => {
    setIsEventAdded(false);
    setIsLoading(true);
    await Api.calendar.joinRecorderToEvent(calendarEvent.id);
    setIsLoading(false);
    setIsEventAdded(true);

    const showSuccessView = getCookie(SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE);

    if (showSuccessView === 'false') {
      setIsEventAdded(false);
      onClose();
    }
  };

  return (
    <div
      className={cn(
        'flex flex-row justify-between items-center py-4 px-5 mb-1 border-[1px] rounded-[20px]',
        isMeetingInProgress
          ? 'border-accent-blue bg-accent-blue/10'
          : 'border-main-gray',
      )}
    >
      <div>
        <span className="text-dark tracking-[-0.14px] font-semibold text-sm">
          {calendarEvent.title}
        </span>
        <div className="flex flex-row items-center gap-2">
          {isMeetingInProgress && (
            <>
              <span className="text-accent-blue text-xs font-normal tracking-[-0.12px]">
                Now
              </span>
              <Ellipse />
            </>
          )}
          <div className="flex flex-row items-center">
            <Clock color={'#5C6A82'} size={12} />
            <span className="text-main-gray-dark text-xs font-normal tracking-[-0.12px] ml-1">
              {dayjs(calendarEvent.start).format('h:mma')}
            </span>
          </div>
        </div>
      </div>
      {/*<ToggleMeetRecording calendarEvent={calendarEvent} />*/}
      <RecordButton
        onClick={handleRecord}
        variant="primary"
        isLoading={isLoading}
      />
    </div>
  );
};
