import { useMutation, useQueryClient } from '@tanstack/react-query';

import { requestBotRecordingPermission as requestBotRecordingPermissionAPI } from '@/api/calendar/request-bot-recording-permission';

export const useRequestPermissionAgain = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: requestBotRecordingPermission, ...rest } = useMutation({
    mutationFn: requestBotRecordingPermissionAPI,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calls'] });
    },
  });

  return { requestBotRecordingPermission, ...rest };
};
