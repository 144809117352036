import { create } from 'zustand';

interface SubscribeModalStore {
  isSubscribeModalOpen: boolean;
  openSubscribeModal: () => void;
  closeSubscribeModal: () => void;
}

export const useSubscribeModalStore = create<SubscribeModalStore>((set) => ({
  isSubscribeModalOpen: false,
  openSubscribeModal: () => set(() => ({ isSubscribeModalOpen: true })),
  closeSubscribeModal: () => set(() => ({ isSubscribeModalOpen: false })),
}));
