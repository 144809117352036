import React from 'react';

import { AddFirstCallState } from '@/components/InsightsAcrossCallsPage/chart/AddFirstCallState';
import { CallsProcessingState } from '@/components/InsightsAcrossCallsPage/chart/CallsProcessingState';
import { EmptyPlaybookItemsState } from '@/components/InsightsAcrossCallsPage/chart/EmptyPlaybookItemsState';
import { NoCallsForTimeRange } from '@/components/InsightsAcrossCallsPage/chart/NoCallsForTimeRange';
import { TryDemoMode } from '@/components/InsightsAcrossCallsPage/chart/TryDemoMode';
import { UpdatingInsightsState } from '@/components/InsightsAcrossCallsPage/chart/UpdatingInsightsState';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { TopQuestionsEmptyState } from '@/components/InsightsAcrossCallsPage/TopQuestionsEmptyState';

export interface InsightsEmptyStateParams {
  playbookItemsState?: {
    hasPlaybookItemsOptions?: boolean;
    errorText: string;
  };
  isQuestionsView?: boolean;
}

export const InsightsEmptyState: React.FC<InsightsEmptyStateParams> = ({
  isQuestionsView,
  playbookItemsState,
}: InsightsEmptyStateParams) => {
  const {
    callsInProcessingState,
    selectedTimeRangeCallsCount,
    hasAtLeastOneCall,
    organizationLastReanalyzedIndex,
    callsWithoutAllPlaybookResultsCount,
    callsWithAllPlaybookResultsCount,
  }: InsightsAcrossCallsStore = useInsightsAcrossCallsStore();

  if (
    callsWithoutAllPlaybookResultsCount !== null &&
    callsWithAllPlaybookResultsCount !== null &&
    organizationLastReanalyzedIndex !== null &&
    organizationLastReanalyzedIndex > 1 &&
    callsWithAllPlaybookResultsCount === 0 &&
    callsWithoutAllPlaybookResultsCount > 0
  )
    return <UpdatingInsightsState />;
  if (playbookItemsState?.hasPlaybookItemsOptions === false)
    return <EmptyPlaybookItemsState title={playbookItemsState.errorText} />;
  if (callsInProcessingState && callsInProcessingState > 0)
    return <CallsProcessingState />;
  if (hasAtLeastOneCall === false) return <TryDemoMode />;
  if (selectedTimeRangeCallsCount === 0) return <NoCallsForTimeRange />;
  if (isQuestionsView) return <TopQuestionsEmptyState />;

  return;
};
