import { Call } from '../../interfaces/call.interface';
import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  reanalyzeCall: backendURL + '/api/calls/:callId/reanalyze',
};

export const reanalyzeCall = async (call: Pick<Call, 'id'>) => {
  const response = await API(
    endpoints.reanalyzeCall.replace(':callId', call.id),
    {
      method: 'POST',
    },
  );
  return response;
};
