export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isChrome() {
  return (
    /Chrome/i.test(navigator.userAgent) &&
    !/Edge/i.test(navigator.userAgent) &&
    !/OPR/i.test(navigator.userAgent)
  );
}

export function isOpera() {
  return /OPR/i.test(navigator.userAgent);
}

export function getChromeVersion(): string | null {
  const userAgent = navigator.userAgent;
  const match = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/i);

  if (!match) return null;

  return match[1];
}

export function getChromeVersionMajor() {
  const chromeVersion = getChromeVersion();
  if (!chromeVersion) {
    return -1;
  }

  return parseInt(chromeVersion.split('.')[0], 10);
}

export function getBrowserName() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'Google Chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'Mozilla Firefox';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'Apple Safari';
  } else if (userAgent.includes('Edg')) {
    return 'Microsoft Edge';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
    return 'Internet Explorer';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    return 'Opera';
  } else {
    return null;
  }
}
