import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.playbookSettings || initialState;

export const selectLoading = createSelector(
  [selectSlice],
  (state) => state.loading,
);

export const selectActionPlaybookTemplate = createSelector(
  [selectSlice],
  (state) => state.playbookTemplate,
);

export const selectActionUserSettings = createSelector(
  [selectSlice],
  (state) => state.userSettings,
);

export const selectPlaybookItems = createSelector(
  [selectSlice],
  (state) => state.playbookItems,
);

export const selectAvailablePlaybooks = createSelector(
  [selectSlice],
  (state) => state.availablePlaybooks,
);

export const selectSelectedPlaybook = createSelector(
  [selectSlice],
  (state) => state.selectedPlaybook,
);

export const selectDefaultPlaybook = createSelector(
  [selectSlice],
  (state) => state.defaultPlaybook,
);
