import {
  BaseInsightsMapper,
  ChartValueItem,
  PlaybookEvaluationColors,
} from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsMapper';
import {
  BasicMetricsEvaluationHelper,
  DetailedFillerWordsMetrics,
  MetricColorMapperFunc,
  PossibleMetrics,
} from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';
import { secondsToMinSec } from '@/utils/secondsToMinSec';

export interface MetricChartValueItem extends ChartValueItem {
  chartLegend?: string;
  key: PossibleMetrics;
}

export type MetricItemData = {
  key: PossibleMetrics & DetailedFillerWordsMetrics;
  value: number;
  prevValue: number | null;
};

type ValueMapperFunc = (value: number) => string;

type CustomExplanationText = {
  [key in PossibleMetrics]?: string;
};

type CustomShortNameText = {
  [key in PossibleMetrics]: string;
};

type CustomDescriptionText = {
  [key in PossibleMetrics]: string;
};

type CustomChartLegendText = {
  [key in PossibleMetrics]: string;
};

type CustomValueMappers = {
  [key in PossibleMetrics]: ValueMapperFunc;
};

export class MetricEvaluationDataMapper extends BaseInsightsMapper {
  static defaultExplanationText: string = 'Avg.';
  static defaultValueMapper: ValueMapperFunc = (value: number): string =>
    `${value}%`;

  // add custom values for items if needed
  static customExplanationText: CustomExplanationText = {};

  static customShortNameText: CustomShortNameText = {
    [PossibleMetrics.MainSpeakerWordsPerMinute]: 'Speech pace',
    [PossibleMetrics.MainSpeakerTalkPercentage]: 'Talk ratio',
    [PossibleMetrics.LongestMonologueDurationSeconds]:
      'Longest customer monologue',
    [PossibleMetrics.QuestionsAsked]: 'Questions asked',
    [PossibleMetrics.FillerWordsCount]: 'Filler words',
  };

  static customDescriptionText: CustomDescriptionText = {
    [PossibleMetrics.MainSpeakerWordsPerMinute]:
      'Words per minute spoken by seller during conversation',
    [PossibleMetrics.MainSpeakerTalkPercentage]:
      'Share of conversation during which seller spoke',
    [PossibleMetrics.LongestMonologueDurationSeconds]:
      'Longest customer statement (in seconds)',
    [PossibleMetrics.QuestionsAsked]:
      'Number of open questions asked during conversation',
    [PossibleMetrics.FillerWordsCount]:
      'How often speaker used words that have no meaning but fill pauses, such as "um" or "like."',
  };

  // add custom values for items if needed
  static customValueMappers: CustomValueMappers = {
    [PossibleMetrics.MainSpeakerWordsPerMinute]: (value: number): string =>
      `${value} wpm`,
    [PossibleMetrics.MainSpeakerTalkPercentage]: (value: number): string =>
      `${value}%`,
    [PossibleMetrics.LongestMonologueDurationSeconds]: (
      value: number,
    ): string => secondsToMinSec(value),
    [PossibleMetrics.QuestionsAsked]: (value: number): string => `${value}`,
    [PossibleMetrics.FillerWordsCount]: (value: number): string => `${value}`,
  };

  static customChartLegendMappers: CustomChartLegendText = {
    [PossibleMetrics.MainSpeakerWordsPerMinute]: 'Words per minute',
    [PossibleMetrics.MainSpeakerTalkPercentage]: '% of time seller spoke',
    [PossibleMetrics.LongestMonologueDurationSeconds]:
      'Longest customer monologue (sec)',
    [PossibleMetrics.QuestionsAsked]: 'Number of questions seller asked',
    [PossibleMetrics.FillerWordsCount]: 'Filler words per call',
  };

  static getValueMapper = (key: PossibleMetrics): ValueMapperFunc => {
    return this.customValueMappers[key] || this.defaultValueMapper;
  };

  static getValueExplanationText = (key: PossibleMetrics): string => {
    return this.customExplanationText[key] || this.defaultExplanationText;
  };

  static map(data: MetricItemData[]): MetricChartValueItem[] {
    const possibleKeys = Object.values(PossibleMetrics);
    const filteredKeys = data.filter((el) => possibleKeys.includes(el.key));

    return filteredKeys.map((item, index) => {
      const valueMapper: ValueMapperFunc = this.getValueMapper(item.key);
      const valueExplanationText: string = this.getValueExplanationText(
        item.key,
      );
      const title: string = this.customShortNameText[item.key];
      const chartLegend: string = this.customChartLegendMappers[item.key];
      const valueColorMapper: MetricColorMapperFunc =
        BasicMetricsEvaluationHelper.mapColorByMetricKey[item.key];
      const description: string = this.customDescriptionText[item.key];

      if (item.value) {
        return {
          value: Number(item.value),
          prevValue: item.prevValue === null ? null : Number(item.prevValue),
          key: item.key,
          displayValue: valueMapper(item.value),
          valueColor: valueColorMapper(item.value),
          title,
          valueExplanationText,
          selected: index === 0,
          chartLegend,
          description,
        };
      } else {
        return {
          value: 0,
          prevValue: null,
          key: item.key,
          displayValue: valueMapper(0),
          valueColor: PlaybookEvaluationColors.Grey,
          title,
          valueExplanationText,
          selected: index === 0,
          chartLegend,
          description,
          emptyState: true,
        };
      }
    });
  }
}
