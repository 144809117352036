import { PossibleMetrics } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

type InsightsAcrossCallsChartRecommendedRangesConstType = {
  [key in string]: number[];
};

export const InsightsAcrossCallsChartRecommendedRangesConst: InsightsAcrossCallsChartRecommendedRangesConstType =
  Object.freeze({
    [PossibleMetrics.MainSpeakerWordsPerMinute]: [110, 170],
    [PossibleMetrics.MainSpeakerTalkPercentage]: [0, 40],
    [PossibleMetrics.LongestMonologueDurationSeconds]: [60],
    [PossibleMetrics.QuestionsAsked]: [18],
    [PossibleMetrics.FillerWordsCount]: [0, 2],
  });
