import React from 'react';
import { PhoneMissed } from 'react-feather';

const NoCallsBanner = () => {
  return (
    <div
      className="p-4 border border-1 border-[#2C6CF6] rounded-lg mt-4"
      style={{
        background:
          'linear-gradient(0deg, rgba(44, 108, 246, 0.08) 0%, rgba(44, 108, 246, 0.08) 100%), #FFF',
        boxShadow:
          '0px 2px 4px -1px rgba(24, 39, 75, 0.02), 0px 1px 4px -1px rgba(24, 39, 75, 0.06)',
      }}
    >
      <div className="flex flex-row">
        <div className="flex justify-center items-center">
          <PhoneMissed size={24} color={'#2C6CF6'} />
        </div>
        <div className="flex flex-col ml-4">
          <span className="font-bold text-[#1C1C1C] text-sm">
            Your team had no calls last week
          </span>
          <span className="font-normal text-[#5C6A82] text-xs">
            Your team can upload their previous calls from the previous week, so
            we can analyze them and show you the insights here.
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoCallsBanner;
