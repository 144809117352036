import { SVGProps } from 'react';

export const QuestionMarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M4 4.67064C4.32261 3.7802 4.95939 3.02934 5.79754 2.55107C6.63569 2.07279 7.62112 1.89796 8.57931 2.05754C9.5375 2.21712 10.4066 2.70081 11.0327 3.42294C11.6588 4.14508 12.0014 5.05905 12 6.00298C12 8.66766 7.88336 10 7.88336 10"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13V14H8.00667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
