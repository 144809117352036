import { Plus, Trash } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { PlaybookItemTitle } from '@/components/CallDetailsPage/Playbook/PlaybookItemTitle';

import { IPlaybookItem } from '../../interfaces/playbook-item.interface';
import { useCustomPlaybookStore } from './useCustomPlaybookStore';

interface ItemProps {
  item: IPlaybookItem;
  inSelectedList: boolean;
  isChosen: boolean;
}

interface AddButtonProps extends Pick<ItemProps, 'item' | 'isChosen'> {}

interface RemoveButtonProps extends Pick<ItemProps, 'item'> {}

export const Item: React.FC<ItemProps> = ({
  item,
  inSelectedList,
  isChosen,
}) => {
  return (
    <div className="flex justify-between items-center gap-1 w-full">
      <PlaybookItemTitle item={item} />
      {!inSelectedList && <AddButton item={item} isChosen={isChosen} />}
      {inSelectedList && <RemoveButton item={item} />}
    </div>
  );
};

const AddButton: React.FC<AddButtonProps> = ({ item, isChosen }) => {
  const addItem = useCustomPlaybookStore((state) => state.addItem);
  return isChosen ? (
    <p className="text-main-gray-2 text-xs font-semibold">Added</p>
  ) : (
    <Button variant="link" onClick={() => addItem(item)}>
      <Plus size={14} />
      Add
    </Button>
  );
};

const RemoveButton: React.FC<RemoveButtonProps> = ({ item }) => {
  const removeItem = useCustomPlaybookStore((state) => state.removeItem);
  return (
    <Button
      variant="link"
      className="text-accent-red hover:text-accent-dark-red"
      onClick={() => removeItem(item)}
    >
      <Trash size={16} />
    </Button>
  );
};
