import React from 'react';
import { AlertCircle } from 'react-feather';

import cn from '@/utils/cn';

import { CallUploadError } from '../UploadCallModal';
import { SUPPORTED_AUDIO_FORMATS } from './SelectFileStep';

const getErrorMessages = (
  error: CallUploadError,
): { title: string; description: string } => {
  if (error === CallUploadError.InvalidFileType) {
    return {
      title: 'The file format is incorrect',
      description: SUPPORTED_AUDIO_FORMATS.join(', '),
    };
  }

  if (error === CallUploadError.ShortDuration) {
    return {
      title: 'This call is too short',
      description:
        'Please only upload calls that are between 10min to 60min in length',
    };
  }

  if (error === CallUploadError.LongDuration) {
    return {
      title: 'This call is too long',
      description:
        'Please only upload calls that are between 10min to 60min in length',
    };
  }

  if (error === CallUploadError.InvalidFileSize) {
    return {
      title: 'This file is too large and cannot be uploaded',
      description:
        'Please upload audio call files that are less than 200 MB in size',
    };
  }

  if (error === CallUploadError.CallsLimit) {
    return {
      title: 'Subscription limit exceeded',
      description:
        'Please upgrade your subscription or wait next billing month to be able to upload more calls',
    };
  }

  return {
    title: 'Oops! Looks like something went wrong',
    description: 'Data transfer error, please try again',
  };
};

interface ErrorStepProps {
  uploadError: CallUploadError | null;
  onTryAgainClick?: () => void;
}

export const ErrorStep: React.FC<ErrorStepProps> = ({
  uploadError,
  onTryAgainClick,
}) => {
  const { title, description } = getErrorMessages(
    uploadError || CallUploadError.UnknownError,
  );
  return (
    <div className="flex justify-center items-center rounded-lg border border-dashed flex-col h-full">
      <AlertCircle size="28" className="text-accent-red mb-2" />
      <div className="mb-6 flex flex-col justify-center items-center">
        <span className="text-base text-dark font-medium mb-1">{title}</span>

        {uploadError === CallUploadError.InvalidFileType && (
          <span className="text-main-gray-dark text-sm font-semibold mb1">
            Please upload one of these audio file formats:
          </span>
        )}

        <span
          className={cn(
            'text-sm font-medium text-main-gray-dark max-w-[560px] text-center',
            uploadError === CallUploadError.InvalidFileType && 'font-light',
          )}
        >
          {description}
        </span>
      </div>
      <button
        onClick={onTryAgainClick}
        className="bg-accent-blue hover:bg-blue-700 transition-colors text-white text-sm font-bold px-3 py-2.5 rounded-xl"
      >
        Try Again
      </button>
    </div>
  );
};
