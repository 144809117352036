import { create } from 'zustand';

interface UploadModalStore {
  isUploadModalOpen: boolean;
  openUploadModal: () => void;
  closeUploadModal: () => void;
}

export const useUploadModalStore = create<UploadModalStore>((set) => ({
  isUploadModalOpen: false,
  openUploadModal: () => set(() => ({ isUploadModalOpen: true })),
  closeUploadModal: () => set(() => ({ isUploadModalOpen: false })),
}));
