import { useSelector } from 'react-redux';

import { DemoPlaybookItemsResults } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { PlaybookCard } from '@/components/CallDetailsPage/Playbook/PlaybookCard';
import { selectLoading } from '@/pages/PlaybookSettingsPage/slice/selectors';

import { SectionTitle } from '../SectionTitle/SectionTitle';
import { ReanalyzeCallBanner } from './ReanalyzeCallBanner';

const Playbook = ({ call }) => {
  const loading = useSelector(selectLoading);

  const playbookItems = call.isDemoCall
    ? DemoPlaybookItemsResults
    : call.playbook?.playbookItems;

  const disabledPlaybookItemIds =
    call.playbook?.playbookItemsDisabledByUser?.map(
      (el) => el.playbookItemId,
    ) || [];

  const activePlaybookItems = (playbookItems || []).filter(
    (item) => call.isDemoCall || !disabledPlaybookItemIds.includes(item.id),
  );

  const sellerEvaluationItems = activePlaybookItems.filter(
    (item) => item.category === 'Seller evaluation',
  );

  const prospectEvaluationItems = activePlaybookItems.filter(
    (item) => item.category === 'Prospect evaluation',
  );

  const activePlaybookStats = call.playbook.playbooksStats.find(
    (el) => el.playbookId === call.lastReanalyzedPlaybookId,
  );

  const callHasActualPlaybookResults =
    call.lastReanalyzedIndex === call.organization.lastReanalyzedIndex ||
    call.lastReanalyzedPlaybookId ===
      call.organization.lastReanalyzedPlaybookId;

  return (
    <div>
      {!loading &&
        (!callHasActualPlaybookResults ||
          (call.organization.lastReanalyzedIndex === 1 &&
            activePlaybookStats?.processingItems > 0)) && (
          <ReanalyzeCallBanner call={call} />
        )}
      <div className="flex flex-col h-full gap-4 pb-5">
        <div className="h-full mt-5">
          <SectionTitle className="mb-2">Seller Performance</SectionTitle>
          <div className="flex flex-col gap-1.5">
            {sellerEvaluationItems.map((item) => (
              <PlaybookCard
                item={item}
                answer={item.result}
                key={item.name}
                callId={call.id}
              />
            ))}
          </div>
        </div>

        <div className="h-full mt-5">
          <SectionTitle className="mb-2">Prospect Evaluation</SectionTitle>
          <div className="flex flex-col gap-1.5">
            {prospectEvaluationItems.map((item) => (
              <PlaybookCard
                item={item}
                answer={item.result}
                key={item.name}
                callId={call.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playbook;
