import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { leadershipDashboardSaga } from './saga';

export const initialState = {
  averagePerformance: null,
  teamInsights: null,
  topReps: [],
  worstReps: [],
  repsLoading: false,
  showNoCallsBanner: false,
  dashboardPageLoading: true,
};

export const leadershipDashboardSlice = createSlice({
  name: 'leadershipDashboard',
  initialState,
  reducers: {
    fetchAveragePerformanceSuccess(state, action) {
      state.averagePerformance = action.payload.averagePerformanceMetrics;
      state.dashboardPageLoading = false;
    },
    fetchRepsPerformanceSuccess(state, action) {
      state.showNoCallsBanner =
        !action.payload.topReps?.length && !action.payload.worstReps?.length;
      state.topReps = action.payload.topReps;
      state.worstReps = action.payload.worstReps;
      state.repsLoading = false;
      state.dashboardPageLoading = false;
    },
    fetchTeamInsightsSuccess(state, action) {
      state.teamInsights = action.payload.teamInsights;
      state.dashboardPageLoading = false;
    },
    setDashboardPageLoading(state, action) {
      state.dashboardPageLoading = action.payload;
    },

    // actions
    fetchAveragePerformance(state, action) {},
    fetchRepsPerformance(state, action) {
      state.repsLoading = true;
    },
    fetchTeamInsights(state, action) {},
  },
});

export const { actions: leadershipDashboardActions } = leadershipDashboardSlice;

export const useLeadershipDashboardSlice = () => {
  useInjectReducer({
    key: leadershipDashboardSlice.name,
    reducer: leadershipDashboardSlice.reducer,
  });
  useInjectSaga({
    key: leadershipDashboardSlice.name,
    saga: leadershipDashboardSaga,
  });
  return { actions: leadershipDashboardSlice.actions };
};
