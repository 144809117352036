import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSubscription } from '@/hooks/useSubscription';

interface SubscriptionSuccessPageProps {}

export const SubscriptionSuccessPage: React.FC<
  SubscriptionSuccessPageProps
> = () => {
  const navigate = useNavigate();

  const { setShowPaymentSuccess, isPending } = useSubscription();

  useEffect(() => {
    setShowPaymentSuccess(true);
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPending) {
    return <div>Loading...</div>;
  }

  return <></>;
};
