import { JoiningYourMeeting } from '@/components/CallDetailsPage/JoiningYourMeeting';
import { RequestPermissionFailed } from '@/components/CallDetailsPage/RequestPermissionFailed';
import { WaitingPermissionToJoin } from '@/components/CallDetailsPage/WaitingPermissionToJoin';
import { BotRecordingStatus } from '@/enums/bot-recording-status.enum';

export interface BotJoiningProps {
  botRecordingStatus?: BotRecordingStatus | null;
  callId: string;
}

export const BotJoining: React.FC<BotJoiningProps> = ({
  botRecordingStatus,
  callId,
}) => {
  if (botRecordingStatus === null) {
    return <JoiningYourMeeting />;
  }

  if (botRecordingStatus === BotRecordingStatus.InCallNotRecording) {
    return <WaitingPermissionToJoin />;
  }

  if (botRecordingStatus === BotRecordingStatus.RecordingPermissionDenied) {
    return <RequestPermissionFailed callId={callId} />;
  }

  return <JoiningYourMeeting />;
};
