import { PlaybookEvaluationColors } from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsMapper';
import { MetricChartValueItem } from '@/components/InsightsAcrossCallsPage/mappers/MetricEvaluationDataMapper';
import { PlaybookChartValueItem } from '@/components/InsightsAcrossCallsPage/mappers/PlaybookEvaluationDataMapper';
import { ProspectChartValueItem } from '@/components/InsightsAcrossCallsPage/mappers/ProspectEvaluationDataMapper';
import { PossibleMetrics } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

interface TemplateLoadingValueBlocks {
  Playbook: PlaybookChartValueItem[];
  Prospect: ProspectChartValueItem[];
  Metrics: MetricChartValueItem[];
}

export const TemplateLoadingValueBlocks: TemplateLoadingValueBlocks = {
  Playbook: [
    {
      key: 'overall',
      title: 'Overall',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: '',
      selected: true,
      prevValue: null,
    },
    {
      key: 'objections',
      title: 'Handles objections',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'personalizesDemo',
      title: 'Personalizes demo',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'introBanter',
      title: 'Intro Banter',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'setsAgenda',
      title: 'Sets Agenda',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'demoStory',
      title: 'Demo tells a story',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'alignNextSteps',
      title: 'Aligns on the next steps',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'booksMeet',
      title: 'Books next meeting',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
  ],
  Prospect: [
    {
      key: 'hasBudget',
      title: 'Budget',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: true,
      prevValue: null,
    },
    {
      key: 'hasAuthority',
      title: 'Authority',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'hasNeed',
      title: 'Need',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: 'hasTimeline',
      title: 'Timeline',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
  ],
  Metrics: [
    {
      key: PossibleMetrics.MainSpeakerWordsPerMinute,
      title: 'Speech pace',
      value: 0,
      displayValue: '0 wpm',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: true,
      prevValue: null,
    },
    {
      key: PossibleMetrics.MainSpeakerTalkPercentage,
      title: 'Talk ratio',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: PossibleMetrics.LongestMonologueDurationSeconds,
      title: 'Longest customer monologue',
      value: 0,
      displayValue: '0 sec.',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: PossibleMetrics.QuestionsAsked,
      title: 'Questions asked',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
    {
      key: PossibleMetrics.FillerWordsCount,
      title: 'Filler words',
      value: 0,
      displayValue: '0%',
      emptyState: true,
      valueColor: PlaybookEvaluationColors.Grey,
      valueExplanationText: 'Avg.',
      selected: false,
      prevValue: null,
    },
  ],
};
