import { Dialog, DialogProps, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { X } from 'react-feather';

import cn from '@/utils/cn';

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
  initialFocusRef?: DialogProps<'div'>['initialFocus'];
  panelOverflow?: boolean;
  verticallyCentered?: boolean;
  showCloseButton?: boolean;
  closeButtonSize?: number;
  containerClassName?: string;
}

export const Modal: React.FC<ModalProps> & {
  Title: typeof ModalTitle;
  Subtitle: typeof ModalSubtitle;
} = ({
  children,
  show,
  onClose,
  initialFocusRef,
  panelOverflow = false,
  verticallyCentered = true,
  showCloseButton,
  closeButtonSize,
  containerClassName,
}) => {
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={onClose}
          initialFocus={initialFocusRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={cn(
                'flex items-center justify-center p-4 text-center',
                verticallyCentered && 'min-h-full',
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cn(
                    'w-full max-w-fit transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                    panelOverflow ? 'overflow-visible' : 'overflow-hidden',
                    containerClassName,
                  )}
                >
                  {showCloseButton && (
                    <X
                      size={closeButtonSize || 24}
                      color="#5C6A82"
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={onClose}
                    />
                  )}
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

interface ModalTitleProps extends React.HTMLAttributes<HTMLParagraphElement> {}

const ModalTitle: React.FC<ModalTitleProps> = ({ children, className }) => {
  return (
    <p className={cn('text-dark text-base font-bold', className)}>{children}</p>
  );
};

interface ModalSubtitleProps
  extends React.HTMLAttributes<HTMLParagraphElement> {}

const ModalSubtitle: React.FC<ModalSubtitleProps> = ({
  children,
  className,
}) => {
  return (
    <p className={cn('text-main-gray-dark text-xs font-normal', className)}>
      {children}
    </p>
  );
};

Modal.Title = ModalTitle;
Modal.Subtitle = ModalSubtitle;

export default Modal;
