import './App.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import { amplitudeService } from '@/amplitude/amplitude.service';
import { ImpersonatePage } from '@/pages/Impersonate';
import { UnimpersonatePage } from '@/pages/Unimpersonate';
import { parseJwt } from '@/utils/parseJWT';

import { ToastProvider } from './components/Toast/ToastProvider';
import { AuthPage } from './pages/AuthPage/AuthPage';
import CallsTabPage from './pages/CallsTabPage';
import { CustomPlaybookSettingsPage } from './pages/CustomPlaybookSettingsPage/CustomPlaybookSettingsPage';
import HomePage from './pages/HomePage';
import { useHomeSlice } from './pages/HomePage/slice';
import {
  selectAppLoaded,
  selectLoggedIn,
  selectUser,
} from './pages/HomePage/slice/selectors';
import LeadershipPage from './pages/LeadershipPage';
import LiveFeedbackPage from './pages/LiveFeedbackPage';
import PlaybookSettingsPage from './pages/PlaybookSettingsPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage/ResetPasswordPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import { SubscriptionSuccessPage } from './pages/subscription/SubscriptionSuccessPage/SubscriptionSuccessPage';
import UnsubscribePage from './pages/UnsubscribePage';
import { leadershipTabsData, userTabsData } from './utils/tabsData';

const queryClient = new QueryClient();

// Main app component - manages routing to applicable pages
// Manages login state and redirects to login if trying to access a protected page when not logged in

const publicRoutes = [
  {
    path: '/auth',
    element: <AuthPage />,
    children: [
      {
        path: '/auth/login',
        element: <SignInPage />,
      },
      {
        path: '/auth/register',
        element: <SignUpPage />,
      },
    ],
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/unsubscribe',
    element: <UnsubscribePage />,
  },
  {
    path: '*',
    element: <Navigate to="/auth/register" replace />,
  },
];

const userRoutes = [
  {
    path: '/',
    element: <HomePage />,
    children: [
      ...userTabsData,
      {
        path: '/calls/:id',
        element: <CallsTabPage />,
      },
      {
        path: '/playbook',
        element: <PlaybookSettingsPage />,
      },
    ],
  },
  {
    path: '/subscription/success',
    element: <SubscriptionSuccessPage />,
  },
  {
    path: '/live-feedback',
    element: <LiveFeedbackPage />,
  },
];

const leadershipRoutes = [
  {
    path: '/',
    element: <LeadershipPage />,
    children: [
      ...leadershipTabsData,
      {
        path: '/playbook/custom/new',
        element: <CustomPlaybookSettingsPage edit={false} />,
      },
      {
        path: '/playbook/custom/edit',
        element: <CustomPlaybookSettingsPage edit={true} />,
      },
      {
        path: '/playbook',
        element: <PlaybookSettingsPage />,
      },
    ],
  },
];

const adminRoutes = [
  {
    path: '/',
    element: <HomePage />,
    children: [
      ...userTabsData,
      {
        path: '/calls/:id',
        element: <CallsTabPage />,
      },
      {
        path: '/playbook/custom/new',
        element: <CustomPlaybookSettingsPage edit={false} />,
      },
      {
        path: '/playbook/custom/edit',
        element: <CustomPlaybookSettingsPage edit={true} />,
      },

      {
        path: '/playbook',
        element: <PlaybookSettingsPage />,
      },
    ],
  },
  {
    path: '/subscription/success',
    element: <SubscriptionSuccessPage />,
  },
  {
    path: '/live-feedback',
    element: <LiveFeedbackPage />,
  },
];

const impersonateRoutes = [
  {
    path: '/impersonate',
    element: <ImpersonatePage />,
  },
  {
    path: '/unimpersonate',
    element: <UnimpersonatePage />,
  },
];

const unimpersonateRoutes = [
  {
    path: '/unimpersonate',
    element: <UnimpersonatePage />,
  },
];

const publicRouter = createBrowserRouter(publicRoutes);
const userRouter = createBrowserRouter([...userRoutes, ...unimpersonateRoutes]);
const leadershipRouter = createBrowserRouter([
  ...leadershipRoutes,
  ...impersonateRoutes,
]);
const adminRouter = createBrowserRouter([...adminRoutes, ...impersonateRoutes]);

const getRouter = (user) => {
  if (!user) return publicRouter;
  if (user.role === 'admin') return adminRouter;
  if (user.role === 'leader') return leadershipRouter;
  if (user.role === 'user') return userRouter;
};

const inAuthPage = () => {
  return window.location.pathname.startsWith('/auth');
};

const inPublicPage = () => {
  return publicRoutes.some((route) =>
    window.location.pathname.startsWith(route.path),
  );
};

function App() {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const loggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const appLoaded = useSelector(selectAppLoaded);
  const router = getRouter(user);

  useEffect(() => {
    if (self === top) {
      var antiClickjack = document.getElementById('antiClickjack');

      antiClickjack.parentNode.removeChild(antiClickjack);
    } else {
      top.location = self.location;
    }

    dispatch(actions.checkLogin());
  }, []);

  useEffect(() => {
    if (appLoaded && !loggedIn && !inPublicPage()) {
      window.location.href = '/auth/register';
    }

    if (appLoaded && loggedIn && inAuthPage()) {
      window.location.href = '/';
    }

    if (appLoaded && !user) {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) return;

      const jwt = parseJwt(authToken);
      if (!jwt) return;

      dispatch(actions.fetchUserData({ email: jwt.email }));
    }
  }, [loggedIn, appLoaded]);

  useEffect(() => {
    const jwt = parseJwt(localStorage.getItem('authToken'));
    if (jwt) {
      const email = jwt.email;
      amplitudeService.setUserId(email);
    }
  });

  if (!appLoaded || (!user && loggedIn)) return null;
  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </GoogleOAuthProvider>
      <ToastProvider />
    </div>
  );
}

export default App;
