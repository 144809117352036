import React from 'react';
import { Check, ChevronRight, ChevronUp, X } from 'react-feather';
import { ClipLoader } from 'react-spinners';

import { Button } from '@/components/Button/Button';
import { KeyDriverTag } from '@/components/CallDetailsPage/Playbook/KeyDriverTag';
import { PlaybookItemTitle } from '@/components/CallDetailsPage/Playbook/PlaybookItemTitle';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { InfoTooltip } from '@/components/HoverTooltip/InfoTooltip';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { KEYDRIVER_ITEMS } from '@/constants';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';

import { SeeHowFeedbackCard } from '../SeeHow/SeeHowFeedbackCard';
import { QuestionMarkIcon } from './icons/QuestionMark.icon';

export enum PlaybookItemAnswer {
  False = 'false',
  True = 'true',
  Unsure = 'unsure',
}

interface PlaybookCardProps {
  answer: PlaybookItemAnswer | null;
  item: any;
  callId: string;
}

export const PlaybookCard: React.FC<PlaybookCardProps> = ({
  item,
  answer,
  callId,
}: PlaybookCardProps) => {
  const seeHowResult = item.seeHowResult;

  const { navigateToTranscript } = useNavigateToTranscript();

  return (
    <CollapsibleItem
      data-testid={item.slug}
      hideDefaultChevron
      triggerDisabled={item.result === 'true'}
      title={({ isOpen }) => (
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center gap-2">
            {answer ? (
              <PlaybookCardIcon answer={answer} />
            ) : (
              <div className="w-7 h-7">
                <ClipLoader size={28} color="rgb(37 99 235)" />
              </div>
            )}
            <div className="inline-flex justify-start items-center gap-1">
              <PlaybookItemTitle item={item} />
              {KEYDRIVER_ITEMS.includes(item.name) && <KeyDriverTag />}
            </div>

            {item.tooltip && (
              <div className="pointer-events-auto flex items-center">
                <InfoTooltip tooltip={item.tooltip} />
              </div>
            )}
          </div>
          {seeHowResult && (
            <div className="w-[70px] flex justify-end">
              {item.result === 'true' ? (
                <Button
                  variant="link"
                  className="text-xs font-semibold pointer-events-auto"
                  onClick={() => {
                    navigateToTranscript({
                      callId,
                      timestamp: item.seeHowResult?.userPhraseTimestamp,
                      text: item.seeHowResult?.userPhrase,
                    });
                  }}
                >
                  {formatTimestamp(item.seeHowResult?.userPhraseTimestamp)}
                  <ChevronRight size={14} strokeWidth={2.5} />
                </Button>
              ) : (
                <Button variant="link" className="text-xs font-semibold">
                  {isOpen ? (
                    <ChevronUp size={14} strokeWidth={2.5} />
                  ) : (
                    'See how'
                  )}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    >
      {seeHowResult && (
        <div>
          <div className="w-full border-t my-4" />
          <div className="text-sm mb-2">
            {item.seeHowResult?.generalFeedback}
          </div>
          <div className="flex gap-2">
            <SeeHowFeedbackCard
              callId={callId}
              variant="userQuote"
              quote={item.seeHowResult?.userPhrase}
              timestamp={item.seeHowResult?.userPhraseTimestamp}
            />
            <SeeHowFeedbackCard
              callId={callId}
              variant="suggestion"
              quote={item.seeHowResult?.userPhraseSuggestion}
            />
          </div>
        </div>
      )}
    </CollapsibleItem>
  );
};

interface PlaybookCardIconProps {
  answer: PlaybookItemAnswer;
}

const PlaybookCardIcon: React.FC<PlaybookCardIconProps> = ({ answer }) => {
  return (
    <WithHoverTooltip
      tooltip={answer === PlaybookItemAnswer.Unsure ? 'Unsure' : undefined}
    >
      <div
        className={cn(
          'flex items-start box-content w-4 h-4 p-1.5 rounded-lg-1.5 border border-solid',
          answer === PlaybookItemAnswer.True &&
            'bg-playbook-green-1 border-playbook-green-2 text-accent-green',
          answer === PlaybookItemAnswer.Unsure &&
            'bg-playbook-yellow-1 border-playbook-yellow-2 text-accent-yellow',
          answer === PlaybookItemAnswer.False &&
            'bg-playbook-red-1 border-playbook-red-2 text-accent-red',
        )}
      >
        {answer === PlaybookItemAnswer.True && <Check size={16} />}
        {answer === PlaybookItemAnswer.Unsure && (
          <QuestionMarkIcon height={16} width={16} />
        )}
        {answer === PlaybookItemAnswer.False && <X size={16} />}
      </div>
    </WithHoverTooltip>
  );
};
