import React, { useEffect, useState } from 'react';
import { CheckCircle, Circle, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import cn from '@/utils/cn';
import { validatePassword } from '@/utils/validators/validatePassword';

import { useHomeSlice } from '../../pages/HomePage/slice';
import { selectRegisterErrorMessage } from '../../pages/HomePage/slice/selectors';
import { useSignUpSlice } from '../../pages/SignUpPage/slice';
import { selectVerifiedEmail } from '../../pages/SignUpPage/slice/selectors';
import { Button } from '../Button/Button';
import { EmailInput } from '../Input/EmailInput';
import { Input } from '../Input/Input';
import { PasswordInput } from '../Input/PasswordInput';
import { Label } from '../Label/Label';

export const SignUpForm = () => {
  const dispatch = useDispatch();
  const { actions } = useSignUpSlice();
  const { actions: homeActions } = useHomeSlice();

  const verifiedEmail = useSelector(selectVerifiedEmail);
  const registerErrorMessage = useSelector(selectRegisterErrorMessage);

  const [formValues, setFormValues] = useState({
    email: verifiedEmail,
    password: '',
    firstName: '',
    lastName: '',
  });

  const navigate = useNavigate();

  const toSignIn = () => {
    dispatch(actions.setEmailNotUsed(null));
    navigate('/auth/login');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setEmailNotUsed(null));
    dispatch(actions.setVerifiedEmail(event.target.value));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(homeActions.register(formValues));
  };

  useEffect(() => {
    if (registerErrorMessage) {
      dispatch(homeActions.resetRegisterErrorMessage(null));
    }
  }, [formValues]);

  const passwordValid = validatePassword(formValues.password);

  return (
    <>
      {registerErrorMessage && (
        <div className="text-sm font-medium text-accent-red mb-6">
          {registerErrorMessage}
        </div>
      )}
      <form onSubmit={handleSubmit} className="w-[400px]">
        <div className="mb-3">
          <Label htmlFor="email">Work email address</Label>
          <EmailInput
            id="email"
            name="email"
            placeholder="Enter your work email"
            value={formValues.email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="flex gap-4 mb-3">
          <div>
            <Label htmlFor="firstName">First name</Label>
            <Input
              id="firstName"
              name="firstName"
              placeholder="First name"
              value={formValues.firstName}
              onChange={handleChange}
              required
              leftSlot={<User className="h-5 w-5 text-black-400" />}
            />
          </div>
          <div>
            <Label htmlFor="lastName">Last name</Label>
            <Input
              id="lastName"
              name="lastName"
              placeholder="Last name"
              value={formValues.lastName}
              onChange={handleChange}
              required
              leftSlot={<User className="h-5 w-5 text-black-400" />}
            />
          </div>
        </div>
        <div className="mb-4">
          <Label htmlFor="password">Password</Label>
          <PasswordInput
            id="password"
            name="password"
            placeholder="Create your password"
            value={formValues.password}
            onChange={handleChange}
            required
          />
        </div>
        {formValues.password && (
          <div className="mb-4">
            <span className="text-[#152543] text-sm font-bold">
              Your password must contain
            </span>
            <div className="flex flex-col gap-2 mt-2">
              <div
                className={cn(
                  'flex flex-row gap-2 text-sm font-medium`',
                  passwordValid ? 'text-accent-green' : 'text-accent-red',
                )}
              >
                {passwordValid ? (
                  <CheckCircle size={20} />
                ) : (
                  <Circle size={20} />
                )}
                <span className="text-sm font-medium">8-20 characters</span>
              </div>
            </div>
          </div>
        )}
        <Button
          type="submit"
          disabled={
            !formValues.email ||
            !passwordValid ||
            !formValues.lastName ||
            !formValues.firstName
          }
          className="w-full mb-6"
        >
          Continue
        </Button>
      </form>

      <p className="text-dark text-sm font-medium mb-6">
        Already have account?
        <Button variant="link" onClick={toSignIn}>
          Sign In
        </Button>
      </p>

      <p className="text-main-gray-dark text-xs font-medium">
        By continuing, you agree to our{' '}
        <Link to="https://www.silkchart.com/terms-of-use" target="_blank">
          <span className="text-dark">Terms of Use</span>
        </Link>{' '}
        and{' '}
        <Link to="https://www.silkchart.com/privacy-policy" target="_blank">
          <span className="text-dark">Privacy Policy</span>
        </Link>
      </p>
    </>
  );
};
