import React from 'react';

import { useOnboardingTooltipCookieStore } from './onboardingTooltipCookieStore';

interface TooltipData {
  id: string;
  title: string;
  description: React.ReactNode;
}

export const onboardingTooltipsData = [
  {
    id: 'welcome',
    title: 'Welcome to SilkChart!',
    description: (
      <p>
        This is an example call to play around with. Upload or <br />
        Record own calls to see your personalized insights.
      </p>
    ),
  },
  {
    id: 'edit-playbook',
    title: 'Edit playbook',
    description: (
      <p>
        Click here to pick an expert-recommended sales strategy or <br />
        create your own
      </p>
    ),
  },
  {
    id: 'playbook',
    title: 'Sales Playbook',
    description: (
      <p>
        We listen to your sales calls and track how well you’re executing <br />
        against your sales strategy or playbook.
      </p>
    ),
  },
  {
    id: 'feedback',
    title: 'Coaching Feedback',
    description: (
      <p>
        Speech performance metrics and AI sales coach feedback <br />
        about what went well and where you can improve.
      </p>
    ),
  },
  {
    id: 'questions',
    title: 'Prospect’s Questions',
    description: (
      <p>
        Top questions and objections that came up during your <br />
        call, and your answers to each.
      </p>
    ),
  },
  {
    id: 'insights',
    title: 'Insights',
    description: (
      <p>
        Use tabs below to see how well you’re adhering to sales best practices
        <br />
        over time, and the top questions/objections from prospects across all
        <br />
        calls.
      </p>
    ),
  },
] as const satisfies TooltipData[];

export type OnboardingTooltipId = (typeof onboardingTooltipsData)[number]['id'];

const LAST_TOOLTIP_INDEX = onboardingTooltipsData.length - 1;

export const useOnboardingTooltip = () => {
  const {
    tooltipIndex: activeTooltipIndex,
    setTooltipIndex,
    startOnboarding,
    finishOnboarding,
  } = useOnboardingTooltipCookieStore();

  const isOnboardingStarted = activeTooltipIndex >= 0;

  const isLastTooltip = activeTooltipIndex === LAST_TOOLTIP_INDEX;

  const activeTooltipData = onboardingTooltipsData[activeTooltipIndex];

  const next = () => {
    if (isLastTooltip) {
      finishOnboarding();
      return;
    }

    setTooltipIndex(activeTooltipIndex + 1);
  };

  return {
    isOnboardingStarted,
    startOnboarding,
    activeTooltipIndex,
    next,
    activeTooltipData,
    isLastTooltip,
  };
};
