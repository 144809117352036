import React from 'react';

import { useSubscription } from '@/hooks/useSubscription';

import Modal from '../../Shared/Modal';
import { StripePricingTable } from '../StripePricingTable/StripePricingTable';
import { useSubscribeModalStore } from './useSubscribeModalStore';

export const SubscribeModal: React.FC = () => {
  const { isSubscribeModalOpen, closeSubscribeModal } =
    useSubscribeModalStore();

  const { subscriptionData, subscriptionEndsToday, isPending, isError } =
    useSubscription();

  if (isPending || isError) {
    return <></>;
  }

  const trialIsExpired =
    subscriptionData?.isTrial && subscriptionData.isExpired;
  const trialEndsToday = subscriptionData?.isTrial && subscriptionEndsToday;

  const getText = () => {
    if (trialIsExpired) {
      return {
        title: 'Your trial has expired!',
        subtitle:
          'Subscribe today to continue  to improve your conversations with prospects',
      };
    }

    if (trialEndsToday) {
      return {
        title: 'Your free trial ends today!',
        subtitle:
          'Upgrade now to keep improving your conversations with prospects',
      };
    }

    return {
      title: 'Choose the plan that fits your needs',
      subtitle: 'Cancel or change any time',
    };
  };

  const { title, subtitle } = getText();

  return (
    <Modal
      show={isSubscribeModalOpen}
      onClose={closeSubscribeModal}
      showCloseButton
    >
      <div className="flex flex-col items-center mb-6">
        <h1 className="text-dark font-semibold text-2xl">{title}</h1>
        <h2 className="text-main-gray-dark text-sm">{subtitle}</h2>
      </div>
      <div className="w-[1000px]">
        <StripePricingTable />
      </div>
    </Modal>
  );
};
