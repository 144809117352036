const backendURL = process.env.REACT_APP_BACKEND_URL;

type SendPasswordResetEmailResponse =
  | {
      success: true;
    }
  | { success: false; error: string };

export const sendPasswordResetEmail = async (params: {
  email: string;
}): Promise<SendPasswordResetEmailResponse> => {
  const res = await fetch(backendURL + '/api/password/sendPasswordReset', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: params.email,
    }),
  });

  const data = await res.json();

  return data as SendPasswordResetEmailResponse;
};
