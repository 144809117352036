import React from 'react';
import { X } from 'react-feather';

import cn from '@/utils/cn';

export interface BannerProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  onClose?: () => void;

  variant?: 'primary' | 'danger' | 'success';
  className?: string;
}

export const Banner: React.FC<BannerProps> & {
  IconContainer: typeof BannerIconContainer;
} = ({
  variant = 'primary',
  title,
  subtitle,
  leftSlot,
  rightSlot,
  onClose,
  className,
}) => {
  return (
    <div
      className={cn(
        'flex justify-between items-center p-4 border border-1 rounded-lg text-dark text-sm',
        variant === 'primary' && 'border-accent-blue/10 bg-accent-blue/10',
        variant === 'danger' && 'border-accent-red/10 bg-accent-red/10',
        variant === 'success' && 'border-accent-green/10 bg-accent-green/10',
        className,
      )}
    >
      <div className="flex justify-center items-center">
        {leftSlot && (
          <div className="flex justify-center items-center mr-3">
            {leftSlot}
          </div>
        )}
        <div>
          <p className="font-semibold">{title}</p>
          <p>{subtitle}</p>
        </div>
      </div>

      <div className="flex justify-center items-center">
        {rightSlot && <div>{rightSlot}</div>}

        {onClose && (
          <button className="ml-3" onClick={onClose}>
            <X size="16px" className="text-main-gray-dark" />
          </button>
        )}
      </div>
    </div>
  );
};

interface BannerIconContainerProps {
  children: React.ReactNode;
  variant?: 'primary' | 'danger' | 'success';
}

const BannerIconContainer: React.FC<BannerIconContainerProps> = ({
  children,
  variant,
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center w-10 h-10 rounded-full',
        variant === 'primary' && 'bg-accent-blue/10 text-accent-blue',
        variant === 'danger' && 'bg-accent-red/10 text-accent-red',
        variant === 'success' && 'bg-accent-green/10 text-accent-green',
      )}
    >
      {children}
    </div>
  );
};

Banner.IconContainer = BannerIconContainer;
