import React from 'react';
import { ArrowRight, TrendingUp } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { useActivateDemoMode } from '@/components/InsightsAcrossCallsPage/hooks/useActivateDemoMode';

export const TryDemoMode: React.FC = () => {
  const { activateIsDemoMode, isPending, isSuccess } = useActivateDemoMode();
  const handleTryDemoModeClick = async () => {
    await activateIsDemoMode();
  };

  return (
    <div className="flex justify-center flex-col items-center gap-4">
      <div className="w-[72px] h-[72px] rounded-full bg-accent-blue/10 flex items-center justify-center">
        <TrendingUp size={40} className="text-accent-blue" />
      </div>
      <span className="font-bold text-xl text-dark">
        Not ready to add calls yet?
      </span>
      <Button
        disabled={isSuccess}
        isLoading={isPending}
        className="flex flex-row items-center"
        onClick={handleTryDemoModeClick}
      >
        <span className="text-white font-semibold text-sm">Try demo mode</span>
        <ArrowRight size={16} className="text-white" />
      </Button>
    </div>
  );
};
