import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { signUpSaga } from './saga';

export const initialState = {
  verifiedEmail: null,
  loading: false,
  emailNotUsed: null,
  error: null,
};

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    checkIfEmailNotUsed(state, action) {
      state.loading = true;
    },
    checkUserSuccess(state, action) {
      state.loading = false;
      state.emailNotUsed = action.payload.exists === false;
      state.verifiedEmail = action.payload.verifiedEmail;
    },
    checkUserFailed(state, action) {
      state.loading = false;
      state.error = action.payload.error;
    },
    setEmailNotUsed(state, action) {
      state.emailNotUsed = action.payload;
    },
    setVerifiedEmail(state, action) {
      state.verifiedEmail = action.payload;
    },
  },
});

export const { actions: signUpActions } = signUpSlice;

export const useSignUpSlice = () => {
  useInjectReducer({
    key: signUpSlice.name,
    reducer: signUpSlice.reducer,
  });
  useInjectSaga({ key: signUpSlice.name, saga: signUpSaga });
  return { actions: signUpSlice.actions };
};
