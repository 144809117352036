import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MAX_CALL_LENGTH_MIN, MIN_CALL_LENGTH_MIN } from '../../constants';
import { useLiveFeedbackSlice } from '../../pages/LiveFeedbackPage/slice';

const LiveFeedbackRecordingButton = ({
  intervalLeftSeconds,
  liveFeedbackChannel,
}) => {
  const dispatch = useDispatch();
  const { actions } = useLiveFeedbackSlice();

  const [tooltipShown, setTooltipShown] = useState(false);

  const stopRecording = () => {
    const callIsTooShort =
      intervalLeftSeconds > (MAX_CALL_LENGTH_MIN - MIN_CALL_LENGTH_MIN) * 60;

    if (callIsTooShort) {
      dispatch(actions.setShowEndLiveFeedbackConfirmation(true));
    } else {
      liveFeedbackChannel.postMessage({ stopRecording: true });
    }
  };

  return (
    <div
      className="absolute flex items-center justify-center z-[1] cursor-pointer"
      style={{
        width: '32px',
        height: '28px',
        left: 0,
        top: '194px',
        transform: 'translateY(-50%)',
        borderRadius: '0px 6px 6px 0px',
        borderTop: '0.5px solid rgba(255, 255, 255, 0.56)',
        borderRight: '0.5px solid rgba(255, 255, 255, 0.56)',
        borderBottom: '0.5px solid rgba(255, 255, 255, 0.56)',
        background:
          'linear-gradient(92deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.16) 100%)',
      }}
      onMouseEnter={() => setTooltipShown(true)}
      onMouseLeave={() => setTooltipShown(false)}
    >
      <img src="/record-button.svg" onClick={stopRecording} />
      {tooltipShown && (
        <div className="absolute w-[82px] h-[18px] left-[33px]">
          <img
            width="82px"
            height="18px"
            className="absolute max-h-[18px] max-w-[82px]"
            src="/record-button-tooltip.svg"
          />
          <div
            className="absolute w-[82px] h-[18px]"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'SF Pro Text',
              fontVariantNumeric: 'slashed-zero',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              letterSpacing: '-0.25px',
              color: 'var(--label-color-dark-primary, #DFDEDF)',
              left: '8px',
            }}
          >
            <span>End meeting</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveFeedbackRecordingButton;
