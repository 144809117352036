import { Line } from 'react-chartjs-2';

export const emptyChartOptions: React.ComponentProps<typeof Line>['options'] = {
  interaction: { mode: 'index' },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      title: {
        text: '%',
      },
      ticks: {
        stepSize: 25,
        color: '#B9C7D7',
        font: {
          weight: 500,
          size: 10,
        },
        callback: (value: string): string => {
          return `${value}%`;
        },
      },
      min: 0,
      max: 100,
      border: {
        dash: [5, 3],
        width: 0,
      },
      grid: {
        display: true,
        drawTicks: false,
      },
    },
  },
};
