import { IPlaybookItem } from '../../interfaces/playbook-item.interface';
import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  getPlaybookItems: backendURL + '/api/organization/items',
};

export const getItems = async (): Promise<IPlaybookItem[]> => {
  const response = await API(endpoints.getPlaybookItems, {
    method: 'GET',
  });
  const body = await response.json();
  return body.items as IPlaybookItem[];
};
