import { ArrowRight, Check } from 'react-feather';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';

interface RequestCustomItemsModalProps {
  show: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
}

export const RequestCustomItemsModal: React.FC<
  RequestCustomItemsModalProps
> = ({ show, onClose, showCloseButton }) => {
  const navigateToCalendly = () => {
    window.location.href = process.env.REACT_APP_CALENDLY_URL as string;
  };
  return (
    <Modal show={show} onClose={onClose} showCloseButton={showCloseButton}>
      <div className="flex flex-col items-center gap-6 max-w-md px-6">
        <div className="text-center text-dark text-2xl font-semibold">
          Upgrade to{' '}
          <Button variant="link" onClick={navigateToCalendly}>
            <span className="text-2xl font-semibold">Enterprise</span>
          </Button>{' '}
          to request your own custom playbook
        </div>
        <div>
          <p className="text-dark text-sm font-semibold">
            Upgrade to get access to advanced features
          </p>
          <ul className="text-dark text-sm font-normal">
            <li className="mt-2">
              <Check size={16} className="inline text-accent-blue mr-1" />
              Customizable playbook templates
            </li>
            <li className="mt-2">
              <Check size={16} className="inline text-accent-blue mr-1" />
              Centralized access & security controls
            </li>
            <li className="mt-2">
              <Check size={16} className="inline text-accent-blue mr-1" />
              Advanced admin capabilities per team
            </li>
            <li className="mt-2">
              <Check size={16} className="inline text-accent-blue mr-1" />
              Personalized reporting & analytics
            </li>
            <li className="mt-2">
              <Check size={16} className="inline text-accent-blue mr-1" />
              Dedicated Account Manager
            </li>
          </ul>
        </div>
        <Button variant="primary" onClick={navigateToCalendly}>
          Discuss Enterprise plan <ArrowRight size={16} />
        </Button>
      </div>
    </Modal>
  );
};
