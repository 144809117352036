import React from 'react';
import { WifiOff } from 'react-feather';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';

interface InternetConnectionLostModalProps {
  show: boolean;
  onClose: () => void;
}

export const InternetConnectionLostModal: React.FC<
  InternetConnectionLostModalProps
> = ({ show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex flex-col items-center min-w-[503px]">
        <WifiOff size={40} className="text-accent-red mb-4" />
        <Modal.Title className="mb-1">
          Oops! Internet connection was lost
        </Modal.Title>
        <Modal.Subtitle className="mb-4">
          Please check your connection.
        </Modal.Subtitle>

        <Button className="w-[100px]" onClick={onClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};
