import { Listbox, Transition } from '@headlessui/react';
import React from 'react';
import { ChevronDown } from 'react-feather';

import cn from '@/utils/cn';

export interface SelectOption {
  value: string | number;
  title: string;
  disabled?: boolean;
}

export interface SelectProps extends React.ComponentProps<'div'> {
  options: SelectOption[];
  value: SelectOption['value'];
  onChange: (newValue: unknown) => void;
  disabled?: boolean;
  buttonProps?: React.ComponentProps<typeof Listbox.Button>;
}

export const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  options,
  disabled,
  buttonProps,
  ...restProps
}) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      <div className="relative" {...restProps}>
        <Listbox.Button
          {...buttonProps}
          className={cn(
            'relative w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-accent-blue focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-accent-blue sm:text-sm',
            buttonProps?.className,
          )}
        >
          {({ open }) => {
            return (
              <>
                <span className="block truncate font-semibold">
                  {selectedOption?.title}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDown
                    size={16}
                    className={cn(
                      'text-main-gray-dark transition-transform',
                      open && 'rotate-180',
                    )}
                    aria-hidden="true"
                  />
                </span>
              </>
            );
          }}
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={({ active, disabled }) =>
                  cn(
                    'relative cursor-pointer select-none py-2.5 pl-4',
                    active && 'text-accent-blue',
                    disabled && 'text-gray-400',
                  )
                }
                value={option.value}
              >
                <span className="block truncate font-medium">
                  {option.title}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
