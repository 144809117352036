import { ChevronLeft, ChevronRight } from 'react-feather';

import cn from '@/utils/cn';

interface TabScrollProps extends React.ComponentProps<'button'> {
  direction: 'right' | 'left';
}

export const TabScroll: React.FC<TabScrollProps> = ({
  className,
  direction,
  onClick,
}) => (
  <button
    className={cn(
      'py-5 rounded-md text-main-gray-dark hover:text-dark hover:bg-main-gray',
      className,
    )}
    onClick={onClick}
  >
    {direction === 'right' && <ChevronRight size={12} />}
    {direction === 'left' && <ChevronLeft size={12} />}
  </button>
);
