import React from 'react';
import { CheckCircle } from 'react-feather';

const AuthBenefits = () => {
  return (
    <div
      className="hidden md:flex flex-1 items-center justify-center"
      style={{
        backgroundImage: "url('/signup-bg.png')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="w-[72%] mx-auto">
        <h2 className="text-[#152543] font-bold text-[38px] leading-[46px] mb-6">
          Up your sales game <br />
          with AI sales coaching
        </h2>
        <span className="flex gap-2 mb-4">
          <CheckCircle size={20} color="#1774F8" />
          <p className="text-base font-semibold text-[#152543] ">
            Personalized, actionable feedback after every call
          </p>
        </span>
        <span className="flex gap-2 mb-4">
          <CheckCircle size={20} color="#1774F8" />

          <p className="text-base font-semibold text-[#152543] ">
            Execute according to your sales playbook
          </p>
        </span>
        <span className="flex gap-2 mb-4">
          <CheckCircle size={20} color="#1774F8" />

          <p className="text-base font-semibold text-[#152543] ">
            Track progress and see results
          </p>
        </span>
        <span className="flex gap-2 mb-4">
          <CheckCircle size={20} color="#1774F8" />

          <p className="text-base font-semibold text-[#152543] ">
            Sign up in under 30 seconds
          </p>
        </span>
      </div>
    </div>
  );
};

export default AuthBenefits;
