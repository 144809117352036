import { Tab } from '@headlessui/react';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { ChevronDown, Search } from 'react-feather';

import { Input } from '@/components/Input/Input';
import cn from '@/utils/cn';

import { useSubscription } from '../../hooks/useSubscription';
import { IPlaybook } from '../../interfaces/playbook.interface';
import { IPlaybookItem } from '../../interfaces/playbook-item.interface';
import { Item } from './Item';
import { RequestCustomItemsBanner } from './RequestCustomItemsBanner';
import { useCustomPlaybookStore } from './useCustomPlaybookStore';
import { isSellerEvaluation, matchesCategory } from './utils';

interface PlaybookGroupedItemsWrapperProps {
  search: string;
  category: string;
  playbook: IPlaybook;
  children: JSX.Element;
}

interface PlaybookGroupedItemsProps {
  search: string;
  category: string;
  playbook: IPlaybook;
}

interface ItemsPoolProps {
  openRequestCustomItemsModal: () => void;
}

const isHiddenBySearch = (search: string, item: IPlaybookItem) => {
  return (
    search.length > 0 &&
    !item.description.toLowerCase().includes(search.toLowerCase())
  );
};

export const ItemsPool: React.FC<ItemsPoolProps> = ({
  openRequestCustomItemsModal,
}) => {
  const [search, setSearch] = useState('');
  const [categories, playbooks] = useCustomPlaybookStore((state) => [
    state.categories,
    state.playbooks,
  ]);
  const { subscriptionData } = useSubscription();
  const isEnterprise =
    subscriptionData?.subscriptionPlanName?.toLowerCase() === 'enterprise';
  return (
    <div
      data-testid="items-pool"
      className="pl-6 pt-8 pr-12 pb-8 bg-light-gray border-box w-[560px] h-[calc(100vh-70px)] overflow-y-scroll overflow-x-hidden hide-scrollbar"
    >
      <div className="flex flex-col items-start gap-6">
        <p className="text-dark text-[22px] font-bold">Select items to add</p>
        <div className="w-full">
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search item"
            leftSlot={<Search size={20} />}
          />
        </div>
        <Tab.Group>
          <Tab.List className="flex gap-6">
            {categories.map((category) => (
              // TODO: these classes are duplicated: move them to a shared file / component / tailwind class / etc
              <Tab
                key={category}
                className={({ selected }) =>
                  cn(
                    'flex flex-col items-center focus:ring-0 focus:outline-none',
                    selected &&
                      "after:content-[''] after:inline-block after:bg-accent-blue after:h-1 after:-mt-1 after:w-full after:rounded-full",
                  )
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={cn(
                        'text-sm py-[12px]',
                        selected
                          ? 'text-dark font-semibold'
                          : 'text-main-gray-dark font-medium',
                      )}
                    >
                      {category}
                    </span>
                  </>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="w-full">
            {categories.map((category) => (
              <Tab.Panel key={category} unmount={false}>
                {playbooks.map((playbook) => (
                  <PlaybookGroupedItemsWrapper
                    key={playbook.id}
                    search={search}
                    category={category}
                    playbook={playbook}
                  >
                    <PlaybookGroupedItems
                      search={search}
                      category={category}
                      playbook={playbook}
                    />
                  </PlaybookGroupedItemsWrapper>
                ))}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
        {!isEnterprise && (
          <RequestCustomItemsBanner
            openRequestCustomItemsModal={openRequestCustomItemsModal}
          />
        )}
      </div>
    </div>
  );
};

const PlaybookGroupedItemsWrapper: React.FC<
  PlaybookGroupedItemsWrapperProps
> = ({ playbook, category, search, children }) => {
  const items = useCustomPlaybookStore((state) => state.all);
  const hasItem =
    items
      .filter((item) => matchesCategory(item, category))
      .filter((item) => item.playbookId === playbook.id)
      .filter((item) => !isHiddenBySearch(search, item)).length > 0;
  if (isSellerEvaluation(category)) {
    return (
      <Collapsible
        className="grow"
        open={search.length > 0 ? hasItem : true}
        transitionTime={200}
        trigger={
          <div className="flex items-center gap-1 mb-2">
            <p className="text-main-gray-dark text-sm font-semibold select-none">
              {playbook.prettyName}
            </p>
            <ChevronDown size={14} className="arrow-down-icon keep-color" />
          </div>
        }
      >
        {children}
      </Collapsible>
    );
  } else {
    return <>{children}</>;
  }
};

const PlaybookGroupedItems: React.FC<PlaybookGroupedItemsProps> = ({
  category,
  playbook,
  search,
}) => {
  const [items, selected] = useCustomPlaybookStore((state) => [
    state.all,
    state.selected,
  ]);
  const visibleItems = items
    .filter((item) => matchesCategory(item, category))
    .filter((item) => item.playbookId === playbook.id);
  if (visibleItems.length === 0) return null;
  return (
    <ul className="available-items flex flex-col items-start gap-1.5 w-full min-h-10 mb-6">
      {visibleItems.map((item) => (
        <li
          key={item.id}
          data-id={item.slug}
          className={cn(
            'silkchart-li rounded-lg-1.5 self-stretch select-none',
            isHiddenBySearch(search, item) && 'display-none',
          )}
        >
          <Item
            item={item}
            inSelectedList={false}
            isChosen={selected.findIndex((s) => s.id === item.id) !== -1}
          />
        </li>
      ))}
    </ul>
  );
};
