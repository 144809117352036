import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckEmailForm } from '../../components/AuthPage/CheckEmailForm';
import { SignInForm } from '../../components/SignInPage/SignInForm';
import { selectEmailNotUsed } from '../SignUpPage/slice/selectors';

const SignInPage = () => {
  const navigate = useNavigate();
  const emailNotUsed = useSelector(selectEmailNotUsed);

  useEffect(() => {
    if (emailNotUsed === true) {
      navigate('/auth/register');
    }
  }, [emailNotUsed]);

  return (
    <div className="flex flex-1 bg-[#FFF]">
      <div className="flex-1 flex justify-center items-center">
        <div className="md:w-auto w-full px-5">
          <h2 className="font-bold text-2xl mb-6">Sign in to SilkChart</h2>
          {emailNotUsed === false ? (
            <SignInForm />
          ) : (
            <CheckEmailForm formType={'signIn'} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
