import { Spinner } from 'flowbite-react';
import React from 'react';

export const LoadingStep: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col items-center">
        <Spinner className="fill-[#2C6CF6] h-20 w-20 mb-4" />
        <span className="text-base text-dark text-center mb-1">
          Uploading...
        </span>
        <span className="text-sm text-main-gray-dark text-center">
          This will only take a few seconds, please do not close this page
        </span>
      </div>
    </div>
  );
};
