import { useEffect } from 'react';

export const usePreventLeave = (enabled = true) => {
  useEffect(() => {
    const callback = (event: BeforeUnloadEvent) => {
      // set a truthy value to property returnValue
      if (enabled) {
        event.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [enabled]);
};
