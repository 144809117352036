export const getAudioDuration = async (file: File): Promise<number> => {
  const url = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    audio.muted = true;
    const source = document.createElement('source');
    source.src = url; //--> blob URL
    audio.preload = 'metadata';
    audio.appendChild(source);

    audio.onloadedmetadata = function () {
      resolve(audio.duration);
    };

    audio.onerror = function (event) {
      reject(event);
    };
    source.onerror = function (event) {
      reject(event);
    };
  });
};
