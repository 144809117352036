import React from 'react';
import Avatar from 'react-avatar';
import { ArrowDown, ArrowUp, Info } from 'react-feather';

import {
  canUseImpersonation,
  impersonate,
} from '@/components/LeadershipTeamPage/impersonateHandler';
import { TeamPerformanceHelper } from '@/pages/LeadershipTeam/helpers/teamPerformanceHelper';
import cn from '@/utils/cn';

const getHeader =
  ({
    name,
    tooltip,
    width,
    minWidth,
    tooltipWidth,
    tooltipRight,
    tooltipLeft,
  }) =>
  (info) => (
    <div
      className={'flex flex-row mr-8 items-center cursor-pointer relative'}
      onClick={info.column.getToggleSortingHandler()}
      key={info.column.id}
      style={{ width, minWidth }}
    >
      {info.column.getIsSorted() === 'asc' ? (
        <div className="min-w-[16px] min-h-[16px]">
          <ArrowUp size={16} color="#2C6CF6" className="mr-0.5" />
        </div>
      ) : info.column.getIsSorted() === 'desc' ? (
        <div className="min-w-[16px] min-h-[16px]">
          <ArrowDown size={16} color="#2C6CF6" className="mr-0.5" />
        </div>
      ) : null}
      <span
        className="text-sm font-medium"
        style={{
          color: !info.column.getIsSorted() ? '#5C6A82' : '#2C6CF6',
        }}
      >
        {name}
      </span>
      {tooltip && (
        <>
          <div className="min-w-[16px] min-h-[16px] header-tooltip">
            <Info className="ml-0.5" size={16} color={'#B9C7D7'} />
          </div>
          <div
            className="header-tooltip-text pl-2.5 pt-2 pr-2.5 pb-2 absolute rounded-[10px] shadow-md top-5 bg-[#FFF] z-10"
            style={{
              width: tooltipWidth,
              right: tooltipRight,
              left: tooltipLeft,
            }}
          >
            <span className="text-[#1C1C1C] text-sm font-medium">
              {tooltip}
            </span>
          </div>
        </>
      )}
    </div>
  );

const getCell =
  ({ width, minWidth, suffix = '' }) =>
  (info) => {
    return (
      <div
        className="flex flex-row items-center mr-8"
        style={{ width, minWidth }}
      >
        {info.getValue() !== undefined && (
          <span
            className="text text-sm font-medium pt-0.5 pb-0.5 pl-1.5 pr-1.5 rounded"
            style={{
              backgroundColor: info.getValue()?.bgColor,
              color: info.getValue()?.textColor,
            }}
          >{`${info.getValue().value}${suffix}`}</span>
        )}
      </div>
    );
  };

export const teamPerformanceDefaultColumns = (columnHelper, user) => [
  columnHelper.accessor((row) => row.fullName, {
    id: 'fullName',
    header: getHeader({ name: 'Rep', width: '15.4%', minWidth: '180px' }),
    cell: (info) => (
      <div
        className={cn(
          'group/full-name w-[15.4%] min-w-[180px] mr-8 flex flex-row items-center relative',
          canUseImpersonation(user) && 'cursor-pointer',
        )}
        onClick={() =>
          canUseImpersonation(user) && impersonate(info.row.original.email)
        }
      >
        <Avatar
          size="32px"
          round={true}
          color={'#2C6CF61A'}
          fgColor={'#2C6CF6'}
          name={info.getValue()}
        />
        {info.row.original.playbookFulfilledPercentage.status === 'bad' ? (
          <div className="absolute left-[21px] top-[21px] w-[14px] h-[14px] flex items-center justify-center">
            <img src="/bad-playbook-status-circle.svg" className="absolute" />
            <img
              src="/lightning.png"
              className="absolute text-[8px] h-[12px] w-[10px] top-[1.5px]"
            />
          </div>
        ) : info.row.original.playbookFulfilledPercentage.status === 'good' ? (
          <div className="absolute left-[21px] top-[21px] w-[14px] h-[14px] flex items-center justify-center">
            <img src="/good-playbook-status-circle.svg" className="absolute" />
            <span className="absolute text-[8px]">🔥</span>
          </div>
        ) : (
          ''
        )}
        <div className="overflow-hidden overflow-ellipsis ml-2">
          <span
            className={cn(
              'text-dark text-sm font-medium whitespace-nowrap',
              canUseImpersonation(user) &&
                'group-hover/full-name:text-accent-blue',
            )}
          >
            {info.getValue()}
          </span>
        </div>
      </div>
    ),
  }),
];

const mockOrder = {
  playbookFulfilledPercentage: 1,
  demoPersonalizedOnNeed: 2,
  objection: 3,
  endingNextSteps: 4,
  endingScheduleFollowup: 5,
  introAgenda: 6,
  demoStory: 7,
  introRapport: 8,
  mainSpeakerWordsPerMinute: 9,
  mainSpeakerTalkPercentage: 10,
};

export const teamPerformanceDynamicColumns = (
  columnHelper,
  activePlaybookMetrics,
  showTeamTableColumnOptions,
  useMock,
) => {
  const resultColumns = [
    columnHelper.accessor((row) => row.playbookFulfilledPercentage, {
      id: 'playbookFulfilledPercentage',
      header: getHeader({
        name: 'Overall',
        width: '10.9%',
        minWidth: '162px',
        tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipLeft: '-60px',
      }),
      cell: getCell({
        width: '10.9%',
        minWidth: '162px',
        suffix: '%',
      }),
      mockOrder: mockOrder.playbookFulfilledPercentage,
    }),
    columnHelper.accessor((row) => row.averageSpeechPace, {
      id: 'mainSpeakerWordsPerMinute',
      header: getHeader({
        name: 'Speech Pace',
        width: '9.8%',
        minWidth: '140px',
        tooltip: 'Average words per minute across all calls',
        tooltipWidth: '297px',
        tooltipRight: '0',
      }),
      cell: getCell({
        width: '9.8%',
        minWidth: '140px',
        suffix: ' wpm',
      }),
      mockOrder: mockOrder.mainSpeakerWordsPerMinute,
    }),
    columnHelper.accessor((row) => row.averageTalkPercentage, {
      id: 'mainSpeakerTalkPercentage',
      header: getHeader({
        name: 'Talk ratio',
        width: '11.1%',
        minWidth: '141px',
        tooltip: 'Average share of call length that seller spoke',
        tooltipWidth: '321px',
        tooltipRight: '0',
      }),
      cell: getCell({
        width: '11.1%',
        minWidth: '141px',
        suffix: '%',
      }),
      mockOrder: mockOrder.mainSpeakerTalkPercentage,
    }),
    columnHelper.accessor((row) => row.averageLongestMonologue, {
      id: 'longestMonologueDurationSeconds',
      header: getHeader({
        name: 'Longest Monologue',
        width: '13.3%',
        minWidth: '169px',
        tooltip: 'Average length of longest client monologue',
        tooltipWidth: '311px',
        tooltipRight: '0',
      }),
      cell: getCell({
        width: '13.3%',
        minWidth: '169px',
        suffix: ' sec.',
      }),
      mockOrder: 9999,
    }),
    columnHelper.accessor((row) => row.averageQuestionsAsked, {
      id: 'questionsAsked',
      header: getHeader({
        name: 'Question Asked',
        width: '11.2%',
        minWidth: '143px',
        tooltip: 'Average number of open questions seller asked',
        tooltipWidth: '338px',
        tooltipRight: '0',
      }),
      cell: getCell({
        width: '11.2%',
        minWidth: '143px',
      }),
      mockOrder: 9999,
    }),
    columnHelper.accessor((row) => row.averageFillerWords, {
      id: 'fillerWordsCount',
      header: getHeader({
        name: 'Filler Words',
        width: '11.3%',
        minWidth: '117px',
        tooltip: 'Average number of filler words seller used',
        tooltipWidth: '302px',
        tooltipRight: '0',
      }),
      cell: getCell({
        width: '11.3%',
        minWidth: '117px',
      }),
      mockOrder: 9999,
    }),
    ...activePlaybookMetrics.map((metric) => {
      const styles =
        TeamPerformanceHelper.definedStylesForActivePlaybookMetricsColumns[
          metric.key
        ] ||
        TeamPerformanceHelper.defaultStylesForActivePlaybookMetricColumn(
          metric,
        );

      return columnHelper.accessor((row) => row[metric.key], {
        id: metric.key,
        header: getHeader(styles.header),
        cell: getCell(styles.cell),
        mockOrder: mockOrder[metric.key] || 9999,
      });
    }),
  ].filter((column) => {
    const columnOption = showTeamTableColumnOptions.find(
      (el) => el.key === column.id,
    );

    if (!columnOption) return false;

    return columnOption.show;
  });

  if (useMock) return resultColumns.sort((a, b) => a.mockOrder - b.mockOrder);

  return resultColumns;
};
