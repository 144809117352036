import { Switch } from '@headlessui/react';

const Toggle = ({ title, value, onChange }) => {
  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        <Switch.Label className="text-black">{title}</Switch.Label>
        <Switch
          checked={value}
          onChange={onChange}
          className={`${
            value ? 'bg-[#2D3648]' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-0 `}
        >
          <span
            className={`${
              value ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
