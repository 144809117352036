import React from 'react';
import Collapsible from 'react-collapsible';
import { ChevronUp } from 'react-feather';

import { useModal } from '@/hooks/useModal';
import cn from '@/utils/cn';

interface CollapsibleItemProps {
  title: React.ReactNode | ((params: { isOpen: boolean }) => React.ReactNode);
  children?: React.ReactNode;
  openByDefault?: boolean;
  hideDefaultChevron?: boolean;
  triggerDisabled?: boolean;
}

export const CollapsibleItem: React.FC<CollapsibleItemProps> = ({
  title,
  children,
  openByDefault,
  hideDefaultChevron,
  triggerDisabled,
}) => {
  const { isOpen, open, close } = useModal(openByDefault);

  return (
    <div
      className={cn(
        'bg-white rounded-[20px] border border-main-gray py-4 px-5',
        triggerDisabled && 'pointer-events-none',
      )}
    >
      <Collapsible
        open={isOpen}
        triggerDisabled={triggerDisabled}
        onOpening={open}
        onClosing={close}
        trigger={
          <div className="flex gap-1 items-center text-sm font-semibold rounded-[20px] w-full">
            {!hideDefaultChevron && (
              <button className="text-accent-blue focus:outline-accent-blue">
                <ChevronUp
                  size={20}
                  className={cn('transition-transform', !isOpen && 'rotate-90')}
                />
              </button>
            )}

            {typeof title === 'function' ? title({ isOpen }) : title}
          </div>
        }
        transitionTime={100}
      >
        {children && (
          <div className="pt-1.5">
            {typeof children === 'string' ? (
              <p className="text-black text-sm">{children}</p>
            ) : (
              children
            )}
          </div>
        )}
      </Collapsible>
    </div>
  );
};
