import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Datepicker, WeekStart } from 'flowbite-react';
import { useState } from 'react';
import { Check } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { eventTracker } from '@/amplitude/eventTracker';
import { Button } from '@/components/Button/Button';
import { datepickerTheme } from '@/components/Datepicker/datepicker.theme';
import { Input } from '@/components/Input/Input';
import { Label } from '@/components/Label/Label';
import Modal from '@/components/Shared/Modal';
import { useCallDetailsSlice } from '@/pages/CallDetailsPage/slice';
import { selectCall } from '@/pages/CallDetailsPage/slice/selectors';

import { useHomeSlice } from '../../pages/HomePage/slice';

dayjs.extend(localizedFormat);

interface EditCallDetailsModalProps {
  show: boolean;
  onClose: () => void;
}

export const EditCallDetailsModal: React.FC<EditCallDetailsModalProps> = ({
  show,
  onClose,
}) => {
  const call = useSelector(selectCall);
  const [name, setName] = useState(call.name);
  const defaultDate = new Date(dayjs(call.date || new Date()).format());

  const [date, setDate] = useState(dayjs(defaultDate).format());
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { actions: callDetailsActions } = useCallDetailsSlice();

  const onChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const input = e.target.value;
    if (input.length > 250) return;
    setName(input);
  };

  const onSelectedDateChanged = (date: Date) => setDate(dayjs(date).format());

  const updateCall = () => {
    eventTracker.callDetails.saveCallDetails({
      callId: call.id,
      name,
      date: dayjs(date).toDate(),
    });

    dispatch(
      actions.updateCall({
        id: call.id,
        body: { name, date: dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]') },
      }),
    );

    dispatch(callDetailsActions.fetchCall({ id: call.id }));

    onClose();
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      panelOverflow={true}
      showCloseButton={true}
      verticallyCentered={false}
    >
      <Modal.Title>Edit call details</Modal.Title>
      <div className="w-[400px] flex flex-col gap-4 mt-4">
        <div>
          <Label htmlFor="name">Call name:</Label>
          <Input name="name" value={name} onChange={onChangeName} />
        </div>
        <div>
          <Label htmlFor="date" className="block">
            Date:
          </Label>
          <Datepicker
            name="date"
            className="datepicker"
            weekStart={WeekStart.Sunday}
            autoHide={false}
            showTodayButton={false}
            showClearButton={false}
            theme={datepickerTheme}
            defaultDate={defaultDate}
            maxDate={new Date()}
            onSelectedDateChanged={onSelectedDateChanged}
          />
        </div>
        <div className="flex gap-2 justify-end">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={updateCall}>
            <Check size={14} /> Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
