import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useSignUpSlice } from '../../pages/SignUpPage/slice';

const LinkToSignUp = () => {
  const dispatch = useDispatch();
  const { actions } = useSignUpSlice();
  const navigate = useNavigate();

  const toSignUp = () => {
    dispatch(actions.setEmailNotUsed(null));
    navigate('/auth/register');
  };

  return (
    <div onClick={toSignUp}>
      <button className="bg-[#1774F8] px-[10px] py-[11px] rounded-lg text-white ml-auto font-semibold">
        Sign Up
      </button>
    </div>
  );
};

export default LinkToSignUp;
