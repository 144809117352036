import React from 'react';

import { Button } from '@/components/Button/Button';
import { RecordIcon } from '@/components/Shared/icons/RecordIcon';
import cn from '@/utils/cn';

export interface RecordButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  variant: 'primary' | 'secondary';
  isLoading?: boolean;
}

export const RecordButton: React.FC<RecordButtonProps> = ({
  onClick,
  variant,
  className,
  isLoading,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      isLoading={isLoading}
      className={cn('py-2.5 px-3 rounded-[10px]', className)}
    >
      <RecordIcon
        className={cn(
          variant === 'primary' && 'text-white',
          variant === 'secondary' && 'text-accent-blue',
        )}
      />
      <span
        className={cn(
          variant === 'primary' && 'text-white',
          variant === 'secondary' && 'text-accent-blue',
        )}
      >
        Record
      </span>
    </Button>
  );
};
