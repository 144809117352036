import {
  BaseInsightsMapper,
  ChartValueItem,
  PlaybookEvaluationColors,
} from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsMapper';

export interface ProspectChartValueItem extends ChartValueItem {
  chartLegend?: string;
  key: string;
}

export enum ProspectMetrics {
  BantBudget = 'bantBudget',
  BantAuthority = 'bantAuthority',
  BantNeed = 'bantNeed',
  BantTimeline = 'bantTimeline',
}

export interface ProspectItemData {
  key: ProspectMetrics;
  value: number;
  shortName: string;
  chartLegend: string;
  description: string;
  prevValue: number | null;
}

type ValueMapperFunc = (value: number) => string;

type CustomExplanationText = {
  [key in string]: string;
};

type CustomValueMappers = {
  [key in string]: ValueMapperFunc;
};

export class ProspectEvaluationDataMapper extends BaseInsightsMapper {
  static defaultExplanationText: string = 'Avg.';
  static defaultValueMapper: ValueMapperFunc = (value: number): string =>
    `${value}%`;

  // add custom values for items if needed
  static customExplanationText: CustomExplanationText = {
    averageBudget: 'Avg.',
  };

  // add custom values for items if needed
  static customValueMappers: CustomValueMappers = {
    averageBudget: (value: number): string => `${value}%`,
  };

  static getValueMapper = (key: string): ValueMapperFunc => {
    return this.customValueMappers[key] || this.defaultValueMapper;
  };

  static getValueExplanationText = (key: string): string => {
    return this.customExplanationText[key] || this.defaultExplanationText;
  };

  static map(data: ProspectItemData[]): ProspectChartValueItem[] {
    return data.map((item, index) => {
      const valueMapper: ValueMapperFunc = this.getValueMapper(item.key);
      const valueExplanationText: string = this.getValueExplanationText(
        item.key,
      );

      if (item.value) {
        return {
          key: item.key,
          value: Number(item.value),
          prevValue: item.prevValue === null ? null : Number(item.prevValue),
          displayValue: valueMapper(item.value),
          valueColor: this.mapColor(item.value),
          title: item.shortName,
          valueExplanationText,
          selected: index === 0,
          description: item.description,
          chartLegend: item?.chartLegend,
        };
      } else {
        return {
          key: item.key,
          value: 0,
          prevValue: null,
          displayValue: valueMapper(0),
          valueColor: PlaybookEvaluationColors.Grey,
          title: item.shortName,
          valueExplanationText,
          selected: index === 0,
          description: item?.description,
          chartLegend: item?.chartLegend,
          emptyState: true,
        };
      }
    });
  }
}
