import React from 'react';

const AveragePerformanceItem = ({ metricInfo }) => {
  return (
    <div className="w-[20%] flex flex-col p-5 border rounded-[20px] border-1 border-[#ECECEC] shadow-sm">
      <span className="text-[#1C1C1C] text-sm font-semibold leading-normal">
        {metricInfo.text.header}
      </span>
      <span
        className="text-lg font-semibold mt-2 leading-normal"
        style={{
          color: metricInfo.text.result.color,
        }}
      >
        {metricInfo.text.result.text}
      </span>
      <span className="text-[#5C6A82] text-xs font-normal mt-2 leading-normal">
        {metricInfo.text.value}
      </span>
    </div>
  );
};

export default AveragePerformanceItem;
