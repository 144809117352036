import { MetricsEvaluationColors } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

export interface ChartValueItem {
  value: number;
  displayValue: string;
  valueColor: PlaybookEvaluationColors | MetricsEvaluationColors;
  title: string;
  valueExplanationText: string;
  selected: boolean;
  prevValue: number | null;
  emptyState?: boolean;
}

export enum PlaybookEvaluationColors {
  Green = '#0FB600',
  Yellow = '#F89B11',
  Red = '#E74029',
  Grey = '#5C6A82',
}

export abstract class BaseInsightsMapper {
  static mapColor(value: number): PlaybookEvaluationColors {
    switch (true) {
      case value >= 60:
        return PlaybookEvaluationColors.Green;
      case value >= 20 && value < 60:
        return PlaybookEvaluationColors.Yellow;
      case value < 20:
        return PlaybookEvaluationColors.Red;
      default:
        return PlaybookEvaluationColors.Grey;
    }
  }
}
