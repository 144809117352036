// https://www.chartjs.org/docs/latest/charts/line.html

import { Line } from 'react-chartjs-2';

export const InsightsAcrossCallsChartDatasetsConst = (
  data: number[],
): React.ComponentProps<typeof Line>['data']['datasets'] => [
  {
    data,
    borderColor: '#2C6CF6',
    hoverBorderColor: '#2C6CF6',
    borderWidth: 4,

    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#2C6CF6',

    pointBorderWidth: 3,
    pointHoverBorderWidth: 3,

    pointRadius: 6,
    pointHoverRadius: 6,

    tension: 0.25,
    spanGaps: true,
    borderJoinStyle: 'round',
    // borderRadius: 50,
  },
];
