import { Spinner } from 'flowbite-react';
import React from 'react';

import { RecordLink } from '@/components/Shared/RecordLink';
import { UploadLink } from '@/components/Shared/UploadLink';

export const CallsProcessingState: React.FC = () => {
  return (
    <div className="flex justify-center flex-col items-center gap-4">
      <Spinner className="fill-[#2C6CF6] h-16 w-16" />
      <div className="flex flex-col items-center gap-4">
        <p className="text-xl font-bold">Calls are currently processing...</p>
        <div className="flex flex-col items-center gap-1">
          <p className="text-[#758DB9] font-normal text-sm">
            We will e-mail you when insights are ready (usually a few mins).
          </p>
          <p className="text-[#758DB9] font-normal text-sm">
            In the meantime, you can <UploadLink /> or <RecordLink /> your other
            calls.
          </p>
        </div>
      </div>
    </div>
  );
};
