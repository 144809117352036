import { useMutation, useQueryClient } from '@tanstack/react-query';

import { joinRecorderByUrl as joinRecorderByUrlAPI } from '@/api/calendar/join-recorder-by-url';

export const useJoinRecorderByUrl = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: joinRecorderByUrl, ...rest } = useMutation({
    mutationFn: joinRecorderByUrlAPI,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calls'] });
    },
  });

  return { joinRecorderByUrl, ...rest };
};
