import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.liveFeedback || initialState;

export const selectLiveFeedbackResult = createSelector(
  [selectSlice],
  (state) => state.liveFeedbackResult,
);

export const selectLastChunkKey = createSelector(
  [selectSlice],
  (state) => state.lastChunkKey,
);

export const selectAudioChunks = createSelector(
  [selectSlice],
  (state) => state.audioChunks,
);

export const selectMimeType = createSelector(
  [selectSlice],
  (state) => state.mimeType,
);

export const selectHeaderChunk = createSelector(
  [selectSlice],
  (state) => state.headerChunk,
);

export const selectCurrentMetric = createSelector(
  [selectSlice],
  (state) => state.currentMetric,
);

export const selectShowTimeBeforeEnd = createSelector(
  [selectSlice],
  (state) => state.showTimeBeforeEnd,
);

export const selectShowEndLiveFeedbackConfirmation = createSelector(
  [selectSlice],
  (state) => state.showEndLiveFeedbackConfirmation,
);
