import { PlaybookItemAnswer } from '@/components/CallDetailsPage/Playbook/PlaybookCard';
import {
  DetailedFillerWordsMetrics,
  PossibleMetrics,
} from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

export const DemoCallPlaybookPecentage = 71;

export const DemoTranscriptSentences = [
  {
    start: 1000,
    speaker: '0',
    text: 'Good morning, this is Anna from Acme Inc.',
  },
  {
    start: 6000,
    speaker: '1',
    text: 'Good morning, Anna.',
  },
  {
    start: 11000,
    speaker: '0',
    text: 'Great to connect with you, James. How can I assist you today?',
  },
  {
    start: 45000,
    speaker: '1',
    text: "I'm interested in learning more about your product and how it can benefit our company.",
  },
  {
    start: 81000,
    speaker: '0',
    text: "Wonderful! We have a range of solutions that could align well with your business needs. Can you share a bit about your company's objectives and challenges so I can tailor our discussion accordingly?",
  },
  {
    start: 157000,
    speaker: '1',
    text: "Of course. We are a growing tech company focusing on AI applications, and we're looking for a system to streamline our project management processes and improve collaboration among teams.",
  },
  {
    start: 252000,
    speaker: '0',
    text: "That's fantastic to hear. Our platform offers robust project management tools with a strong emphasis on collaboration and efficiency. I'd be happy to walk you through our features and discuss how they can specifically benefit your team. Would you be interested in scheduling a demo to see our platform in action?",
  },
  {
    start: 329000,
    speaker: '1',
    text: "Yes, a demo would be great to get a better understanding. Also, I'm curious about your pricing and contract terms.",
  },
  {
    start: 392000,
    speaker: '0',
    text: 'Absolutely, we can cover all those details during the demo. How does next Tuesday at 00 AM sound for the demo? We can dive into the product specifics, pricing options, and any other questions you may have.',
  },
];

export const DemoPlaybookItemsResults = [
  {
    category: 'Seller evaluation',
    highlight: 'Handled objections',
    description: 'Handled objections effectively',
    playbookItem: {
      name: 'objection',
    },
    name: 'objection',
    result: PlaybookItemAnswer.False,
    enabled: true,
    tooltip:
      'Listened to objections, addressed them confidently with relevant information, and turned potential roadblocks into opportunities.',
    seeHowResult: {
      criteriaName: 'Handled objections effectively',
      result: 'False',
      generalFeedback:
        'When the prospect raised a question about pricing and contract terms, the seller should have addressed it directly rather than deferring it to the demo.',
      userPhrase:
        'Absolutely, we can cover all those details during the demo. How does next Tuesday at 00 AM sound for the demo? We can dive into the product specifics, pricing options, and any other questions you may have.',
      userPhraseTimestamp: '392000',
      userPhraseSuggestion:
        'I understand that pricing and contract terms are crucial for your decision-making. Our pricing is competitive and flexible, designed to scale with your needs. For example, we offer tiered pricing based on the number of users and features required. Would you like a quick overview now, or should we dive deeper into it during the demo?',
    },
  },
  {
    category: 'Seller evaluation',
    highlight: 'Personalized demo',
    description: "Personalized demo to client's needs",
    playbookItem: {
      name: 'demoPersonalizedOnNeed',
    },
    name: 'demoPersonalizedOnNeed',
    result: PlaybookItemAnswer.False,
    enabled: true,
    tooltip:
      'Tailored the product overview to address the specific pain points, goals, and company-specific needs of the client.',
    seeHowResult: {
      criteriaName: "Personalized demo to client's needs",
      result: 'False',
      generalFeedback:
        "The seller should have tailored the demo offer more specifically to the client's stated needs and challenges.",
      userPhrase:
        "That's fantastic to hear. Our platform offers robust project management tools with a strong emphasis on collaboration and efficiency. I'd be happy to walk you through our features and discuss how they can specifically benefit your team. Would you be interested in scheduling a demo to see our platform in action?",
      userPhraseTimestamp: '252000',
      userPhraseSuggestion:
        "Given that you're focusing on AI applications and need to streamline project management and improve team collaboration, I can tailor the demo to show you how our AI-driven project management features can specifically address these areas. Would you be interested in seeing how our platform can help your teams work more efficiently?",
    },
  },
  {
    category: 'Seller evaluation',
    highlight: 'introductory banter',
    description: 'Engaged in introductory banter',
    playbookItem: {
      name: 'introRapport',
    },
    name: 'introRapport',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip:
      'Built rapport and established a personal connection with the client through casual and relevant conversation at the beginning of the interaction.',
    seeHowResult: {
      criteriaName: 'Engaged in introductory banter',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        'Great to connect with you, James. How can I assist you today?',
      userPhraseTimestamp: '11000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Seller evaluation',
    highlight: 'clear agenda',
    description: 'Set a clear agenda',
    playbookItem: {
      name: 'introAgenda',
    },
    name: 'introAgenda',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip:
      'Outlined the meeting structure at the start, ensuring both parties understood the flow and objectives of the session.',
    seeHowResult: {
      criteriaName: 'Set a clear agenda',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        "Wonderful! We have a range of solutions that could align well with your business needs. Can you share a bit about your company's objectives and challenges so I can tailor our discussion accordingly?",
      userPhraseTimestamp: '81000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Seller evaluation',
    highlight: 'demo to tell a story',
    description: 'Used demo to tell a story rather than review features',
    playbookItem: {
      name: 'demoStory',
    },
    name: 'demoStory',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip:
      'Wove the product’s features into a compelling narrative that resonated with the client’s challenges and aspirations, rather than merely listing functionalities.',
    seeHowResult: {
      criteriaName: 'Used demo to tell a story rather than review features',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        "That's fantastic to hear. Our platform offers robust project management tools with a strong emphasis on collaboration and efficiency. I'd be happy to walk you through our features and discuss how they can specifically benefit your team. Would you be interested in scheduling a demo to see our platform in action?",
      userPhraseTimestamp: '252000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Seller evaluation',
    highlight: 'aligned on next steps',
    description: 'Clearly aligned on next steps with prospect',
    playbookItem: {
      name: 'endingNextSteps',
    },
    name: 'endingNextSteps',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip:
      'Confirmed mutual understanding of the follow-up actions, timelines, and responsibilities, leaving no ambiguity about what happens next.',
    seeHowResult: {
      criteriaName: 'Clearly aligned on next steps with prospect',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        'Absolutely, we can cover all those details during the demo. How does next Tuesday at 00 AM sound for the demo? We can dive into the product specifics, pricing options, and any other questions you may have.',
      userPhraseTimestamp: '392000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Seller evaluation',
    highlight: 'next meeting',
    description: 'Booked the next meeting',
    playbookItem: {
      name: 'endingScheduleFollowup',
    },
    name: 'endingScheduleFollowup',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip:
      'Spoke to the prospect about finding time to meet again and agreed on a day and time to do so.',
    seeHowResult: {
      criteriaName: 'Booked the next meeting',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        'Absolutely, we can cover all those details during the demo. How does next Tuesday at 00 AM sound for the demo? We can dive into the product specifics, pricing options, and any other questions you may have.',
      userPhraseTimestamp: '392000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Prospect evaluation',
    highlight: 'has budget',
    description: 'Likely has budget for solution',
    playbookItem: {
      name: 'bantBudget',
    },
    name: 'bantBudget',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip: undefined,
    seeHowResult: {
      criteriaName: 'Likely has budget for solution',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        'Absolutely, we can cover all those details during the demo. How does next Tuesday at 00 AM sound for the demo? We can dive into the product specifics, pricing options, and any other questions you may have.',
      userPhraseTimestamp: '392000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Prospect evaluation',
    highlight: 'has authority',
    description: 'Likely has authority to purchase solution',
    playbookItem: {
      name: 'bantAuthority',
    },
    name: 'bantAuthority',
    result: PlaybookItemAnswer.False,
    enabled: true,
    tooltip: undefined,
    seeHowResult: {
      criteriaName: 'Likely has authority to purchase solution',
      result: 'False',
      generalFeedback:
        'To ensure the prospect has the authority to purchase, ask directly about their role in the decision-making process.',
      userPhrase:
        "Wonderful! We have a range of solutions that could align well with your business needs. Can you share a bit about your company's objectives and challenges so I can tailor our discussion accordingly?",
      userPhraseTimestamp: '81000',
      userPhraseSuggestion:
        "That's great to hear, James. Can you tell me a bit about your role in the company and who else might be involved in the decision-making process for a new project management system?",
    },
  },
  {
    category: 'Prospect evaluation',
    highlight: 'has need',
    description: 'Likely has need for solution',
    playbookItem: {
      name: 'bantNeed',
    },
    name: 'bantNeed',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip: undefined,
    seeHowResult: {
      criteriaName: 'Likely has need for solution',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        "That's fantastic to hear. Our platform offers robust project management tools with a strong emphasis on collaboration and efficiency. I'd be happy to walk you through our features and discuss how they can specifically benefit your team.",
      userPhraseTimestamp: '252000',
      userPhraseSuggestion: 'N/A',
    },
  },
  {
    category: 'Prospect evaluation',
    highlight: 'short timeline',
    description: 'Likely has short timeline for implementing solution',
    playbookItem: {
      name: 'bantTimeline',
    },
    name: 'bantTimeline',
    result: PlaybookItemAnswer.True,
    enabled: true,
    tooltip: undefined,
    seeHowResult: {
      criteriaName: 'Likely has short timeline for implementing solution',
      result: 'True',
      generalFeedback: 'N/A',
      userPhrase:
        'Absolutely, we can cover all those details during the demo. How does next Tuesday at 00 AM sound for the demo? We can dive into the product specifics, pricing options, and any other questions you may have.',
      userPhraseTimestamp: '392000',
      userPhraseSuggestion: 'N/A',
    },
  },
];

export const DemoTopQuestions = [
  {
    id: 1,
    timestamp: '754000',
    summary: 'How does it work?',
    answer:
      'Our platform works by integrating seamlessly into your existing workflows. It offers a user-friendly interface where you can manage tasks, collaborate with team members, and track progress in real time. Would you like a personalized demo to see it in action?',
  },
  {
    id: 2,
    timestamp: '861000',
    summary: 'Who else is using your product?',
    answer:
      'We have a diverse range of clients across various industries, including tech companies, financial institutions, and healthcare providers. Our product is designed to be scalable and adaptable, catering to the needs of businesses of all sizes. I can provide case studies and references relevant to your industry during our discussion.',
  },
  {
    id: 3,
    timestamp: '1298000',
    summary: 'Is there a discount for scaling up to 100 people?',
    answer:
      'Yes, we offer attractive discounts for scaling up to accommodate larger user bases, ensuring cost-effectiveness as your organization grows.',
  },
  {
    id: 4,
    timestamp: '1601000',
    summary: 'What are the available dates for a follow-up demo?',
    answer:
      'Our team is flexible and can schedule a follow-up demo at your convenience. Please let us know your preferred dates, and we will coordinate accordingly.',
  },
];

export const DemoQualitativeFeedbackItems = [
  {
    type: 'positive',
    title: 'Building rapport and demonstrating personal interest',
    description:
      'You did a great job of building rapport during our call. Your friendly conversation and genuine interest in my needs made me feel valued and understood. Keep up the excellent work!',
  },
  {
    type: 'positive',
    title: 'Understanding client needs before providing solutions',
    description:
      'You demonstrated a strong understanding of my needs before offering solutions. Your thoughtful questions and active listening ensured that your recommendations were perfectly tailored to what I was looking for. That level of attention really makes a difference!',
  },
  {
    type: 'positive',
    title: 'Demonstrating knowledge and expertise',
    description:
      'The knowledge and expertise displayed during the conversation were evident. Confidently answering questions, providing clear explanations, and offering relevant examples helped in better understanding the options. The demonstrated expertise is truly impressive and reassuring. Keep up the good work!',
  },
  {
    type: 'negative',
    title: 'Improve objection handling',
    description:
      'Strengthen your ability to address objections by anticipating common concerns and developing empathetic, informative responses that showcase the value proposition clearly. Practice active listening to discern the root cause of objections and tailor your responses to alleviate doubts effectively.',
  },
  {
    type: 'negative',
    title: 'Personalize the demo more to match needs heard during the call',
    description:
      'Enhance the demo by customizing it to align with the specific pain points and requirements voiced by the client during the call. Highlight features and functionalities that directly address their challenges, showcasing how your SaaS solution is tailored to meet their business needs effectively.',
  },
  {
    type: 'negative',
    title: 'Be more assertive in addressing concerns',
    description:
      "While showcasing knowledge and expertise is important, ensure that it is done in a way that is easily understandable to the client. Avoid using technical jargon without explanation and focus on providing clear, concise explanations that resonate with the client's level of understanding. Additionally, be open to questions and provide additional context or examples as needed to reinforce your expertise.",
  },
];

export const DemoMetrics = [
  {
    type: PossibleMetrics.MainSpeakerWordsPerMinute,
    value: 120,
  },
  {
    type: PossibleMetrics.MainSpeakerTalkPercentage,
    value: 30,
  },
  {
    type: PossibleMetrics.LongestMonologueDurationSeconds,
    value: 148,
  },
  {
    type: PossibleMetrics.QuestionsAsked,
    value: 12,
  },
  {
    type: DetailedFillerWordsMetrics.FillerWordsLikeCount,
    value: 4,
  },
  {
    type: DetailedFillerWordsMetrics.FillerWordsUmCount,
    value: 6,
  },
  {
    type: DetailedFillerWordsMetrics.FillerWordsAhCount,
    value: 5,
  },
  {
    type: DetailedFillerWordsMetrics.FillerWordsUhCount,
    value: 5,
  },
];
