import { Spinner } from 'flowbite-react';
import React from 'react';

export const ChartDataLoading: React.FC = () => {
  return (
    <div className="flex justify-center flex-col items-center">
      <Spinner className="fill-[#2C6CF6] h-16 w-16" />
    </div>
  );
};
