import { Button } from '@/components/Button/Button';

interface RequestCustomItemsBannerProps {
  openRequestCustomItemsModal: () => void;
}

export const RequestCustomItemsBanner: React.FC<
  RequestCustomItemsBannerProps
> = ({ openRequestCustomItemsModal }) => {
  return (
    <div className="flex flex-row items-center p-5 rounded-lg-1.5 bg-accent-blue/8">
      <span className="text-dark text-sm font-medium">
        Didn’t find right sales playbook?
      </span>
      <Button variant="link" onClick={openRequestCustomItemsModal}>
        Request your own sales playbook
      </Button>
    </div>
  );
};
