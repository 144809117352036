import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  updateCustomPlaybook: backendURL + '/api/organization/playbook/custom',
};

export const updateCustomPlaybook = async (
  items: { id: string }[],
): Promise<{ playbookId: string }> => {
  const response = await API(endpoints.updateCustomPlaybook, {
    method: 'PUT',
    body: JSON.stringify({ items }),
  });
  const body = response.json();
  return body;
};
