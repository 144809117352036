import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EndLifeFeedbackConfirmation from '../../components/LiveFeedbackPage/EndLiveFeedbackConfirmation';
import LiveFeedbackMetrics from '../../components/LiveFeedbackPage/LiveFeedbackMetrics';
import LiveFeedbackRecordingButton from '../../components/LiveFeedbackPage/LiveFeedbackRecordingButton';
import LiveFeedbackTimeLeftHint from '../../components/LiveFeedbackPage/LiveFeedbackTimeLeftHint';
import {
  MAX_CALL_LENGTH_MIN,
  MILLIS_PER_MIN,
  MILLIS_PER_SEC,
} from '../../constants';
import { useLiveFeedbackSlice } from './slice';
import {
  selectShowEndLiveFeedbackConfirmation,
  selectShowTimeBeforeEnd,
} from './slice/selectors';

const timeLimit = MAX_CALL_LENGTH_MIN * MILLIS_PER_MIN;

const LiveFeedbackPage = () => {
  const showTimeBeforeEnd = useSelector(selectShowTimeBeforeEnd);
  const showEndLiveConfirmation = useSelector(
    selectShowEndLiveFeedbackConfirmation,
  );

  const dispatch = useDispatch();
  const { actions } = useLiveFeedbackSlice();

  const [isRecording, setIsRecording] = useState(false);
  const [intervalLeftSeconds, setIntervalLeftSeconds] = useState(null);

  const recordingChannelRef = useRef(new BroadcastChannel('recording'));
  const recordingChannel = recordingChannelRef.current;

  const liveFeedbackChannelRef = useRef(new BroadcastChannel('liveFeedback'));
  const liveFeedbackChannel = liveFeedbackChannelRef.current;

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 380 && window.innerHeight > 240) {
        return;
      }

      const newSizes = [];
      newSizes[0] = window.innerWidth <= 380 ? 380 : window.innerWidth;
      newSizes[1] = window.innerHeight <= 240 ? 240 : window.innerHeight;
      newSizes[0] = newSizes[0] + (window.outerWidth - window.innerWidth);
      newSizes[1] = newSizes[1] + (window.outerHeight - window.innerHeight);

      window.resizeTo(newSizes[0], newSizes[1]);
    };

    window.addEventListener('resize', handleWindowResize, false);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!recordingChannel) return;

    recordingChannel.onmessage = (ev) => {
      if (ev.data.chunk) {
        dispatch(actions.addAudioChunk(ev.data.chunk));
        dispatch(actions.setMimeType(ev.data.mimeType));
      }
      if (ev.data.startCountdown === true) {
        dispatch(actions.setShowTimeBeforeEnd(true));
      }
      if (ev.data.closeWindow === true) {
        window.close();
      }
      if (ev.data.elapsedTime) {
        const remainingTime =
          Math.round((timeLimit - ev.data.elapsedTime) / MILLIS_PER_SEC) - 1;

        if (remainingTime < 0) {
          liveFeedbackChannel.postMessage({ stopRecording: true });
        }

        setIntervalLeftSeconds(remainingTime);
      }
    };

    return () => {
      // recordingChannel.close();
    };
  }, [recordingChannel]);

  const handleStartClick = () => {
    setIsRecording(true);
    liveFeedbackChannel.postMessage({ askActualCallDuration: true });
  };

  return (
    <div className="overflow-hidden">
      {showEndLiveConfirmation && (
        <EndLifeFeedbackConfirmation
          liveFeedbackChannel={liveFeedbackChannel}
        />
      )}
      <div
        className={`bg-[#091716] h-[100vh] w-[100vw] flex items-center justify-center ${showEndLiveConfirmation ? 'blur-[6px]' : ''} ${
          showEndLiveConfirmation ? 'scale-110' : ''
        }`}
      >
        {isRecording ? (
          <div className="h-[100vh] w-[100vw] pl-[32px] flex items-center justify-start relative">
            {showTimeBeforeEnd && intervalLeftSeconds && (
              <LiveFeedbackTimeLeftHint
                intervalLeftSeconds={intervalLeftSeconds}
              />
            )}
            <LiveFeedbackMetrics />
            <LiveFeedbackRecordingButton
              intervalLeftSeconds={intervalLeftSeconds}
              liveFeedbackChannel={liveFeedbackChannel}
            />
          </div>
        ) : (
          <button
            onClick={handleStartClick}
            type="submit"
            className="font-medium leading-4 bg-gradient-to-r from-[#6EC0F0] to-[#0F86CD] w-[280px] h-[32px] text-sm text-[#FFF] rounded-3xl border-[0.5px] border-[#6DBFF0]"
          >
            Start Call Analysis
          </button>
        )}
      </div>
    </div>
  );
};

export default LiveFeedbackPage;
