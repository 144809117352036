import { useMutation } from '@tanstack/react-query';

import { sendHelpRequest as sendHelpRequestAPI } from '@/api/help-request/sendHelpRequest';

export const useSendHelpRequest = () => {
  const { mutateAsync: sendHelpRequest, ...rest } = useMutation({
    mutationFn: sendHelpRequestAPI,
  });

  return { sendHelpRequest, ...rest };
};
