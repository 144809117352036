import './LiveFeedbackMetrics.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  LIVE_FEEDBACK_CHUNK_DURATION,
  RECORD_TIMESLICE,
} from '../../constants';
import { useLiveFeedbackSlice } from '../../pages/LiveFeedbackPage/slice';
import {
  selectAudioChunks,
  selectCurrentMetric,
  selectHeaderChunk,
  selectLastChunkKey,
  selectLiveFeedbackResult,
  selectMimeType,
} from '../../pages/LiveFeedbackPage/slice/selectors';
import { metrics, statuses } from './consts';
import LiveFeedbackSelectMenu from './LiveFeedbackSelectMenu';
const { v4: uuidv4 } = require('uuid');

const LiveFeedbackMetrics = () => {
  const defaultActionText = 'Collecting data';

  const liveFeedbackResult = useSelector(selectLiveFeedbackResult);
  const lastChunkKey = useSelector(selectLastChunkKey);
  const currentMetric = useSelector(selectCurrentMetric);
  const audioChunks = useSelector(selectAudioChunks);
  const mimeType = useSelector(selectMimeType);
  const headerChunk = useSelector(selectHeaderChunk);

  const dispatch = useDispatch();
  const { actions } = useLiveFeedbackSlice();

  let [actionText, setActionText] = useState(defaultActionText);
  let [status, setStatus] = useState('default');

  const liveFeedbackChunksCount =
    LIVE_FEEDBACK_CHUNK_DURATION / RECORD_TIMESLICE;
  const timeToProcessTranscription = LIVE_FEEDBACK_CHUNK_DURATION * 0.4;

  useEffect(() => {
    if (!audioChunks.length) return;

    if (!currentMetric) {
      dispatch(actions.resetAudioChunks());
      return;
    }

    if (audioChunks.length >= liveFeedbackChunksCount) {
      const blob = new Blob(audioChunks, { type: mimeType });

      dispatch(
        actions.uploadLiveFeedbackChunk({
          file: {
            type: mimeType,
            name: `${uuidv4()}-chunk.webm`,
            blob,
            headerChunk,
          },
        }),
      );

      dispatch(actions.resetAudioChunks());
    }
  }, [mimeType, audioChunks]);

  useEffect(() => {
    if (lastChunkKey) {
      setTimeout(() => {
        dispatch(actions.fetchLiveFeedback({ lastChunkKey }));
      }, timeToProcessTranscription);
    }
  }, [lastChunkKey]);

  useEffect(() => {
    if (liveFeedbackResult?.feedbackResult) {
      setStatus(liveFeedbackResult.feedbackResult);

      const actionText =
        liveFeedbackResult?.overrideStatusText ||
        metrics[currentMetric][liveFeedbackResult.feedbackResult].text;

      setActionText(actionText);
    }
  }, [liveFeedbackResult, mimeType]);

  const changeCurrentMetric = (metric) => {
    if (metric === currentMetric) return;

    dispatch(actions.setLiveFeedback(null));
    dispatch(actions.setCurrentMetric(metric));
    setStatus('default');
    setActionText(defaultActionText);
  };

  return (
    <div className="border-black flex items-center z-[1]">
      <div className="w-[56px] h-[56px] z-[1] mr-[11px] relative">
        <img
          className="z-10"
          width="56px"
          height="56px"
          src={statuses[status].icon}
          alt="logo"
        />
        <div
          className="w-[80px] h-[80px] z-0 blur-container absolute rounded-full"
          style={{
            left: -12,
            top: -12,
            background: `${statuses[status].bgColor}`,
          }}
        ></div>
      </div>
      <div className="flex flex-col">
        <div>
          <LiveFeedbackSelectMenu
            currentMetric={currentMetric}
            changeCurrentMetric={changeCurrentMetric}
          />
        </div>
        <div className="mt-[4px] flex">
          <span
            className={`loading pl-3.5 leading-4 font-semibold text-[10px] italic text-[#ffffffb8] ${
              status === 'default' ? 'loading_text blink_text' : ''
            }`}
          >
            {actionText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LiveFeedbackMetrics;

// animation: 'blinker 0.5s linear infinite, dotty steps(1,end) 1s infinite',
//   @keyframes blinker {
//   50% { opacity: 0; }
// }
