import { Spinner } from 'flowbite-react';

export const JoiningYourMeeting = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <Spinner key="spinner" className="fill-accent-blue h-16 w-16" />
      <div className="flex flex-col items-center justify-center gap-2">
        <span className="text-dark text-xl font-bold">
          We are working on joining your meeting...
        </span>
        <span className="text-main-gray-dark text-sm tracking-[-0.14px]">
          Please admit SilkChart Assistant to your meeting
        </span>
      </div>
    </div>
  );
};
