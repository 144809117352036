import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from '@/pages/HomePage/slice/selectors';

import { useLeadershipDashboardSlice } from '../../../pages/LeadershipDashboard/slice';
import { selectAveragePerformance } from '../../../pages/LeadershipDashboard/slice/selectors';
import { selectTimeRangeFilter } from '../../../pages/LeadershipPage/slice/selectors';
import AveragePerformanceItem from './AveragePerformanceItem';
import { PerformanceMetricsHelper } from './helpers/PerformanceMetricsHelper';
import { mock } from './mock';

const AveragePerformanceBlock = () => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipDashboardSlice();

  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const averagePerformance = useSelector(selectAveragePerformance);
  const currentTimeRangeFilter = useSelector(selectTimeRangeFilter);
  const [metricsWithInfo, setMetricsWithInfo] = useState([]);

  useEffect(() => {
    dispatch(
      actions.fetchAveragePerformance({ timeFilter: currentTimeRangeFilter }),
    );
  }, [currentTimeRangeFilter]);

  useEffect(() => {
    if (!averagePerformance) return;

    const metricsWithInfo = PerformanceMetricsHelper.setMetricsInfo(
      useMock ? mock : averagePerformance,
    );

    setMetricsWithInfo(metricsWithInfo);
  }, [averagePerformance]);

  return (
    <div className="mb-12 mt-12">
      <div>
        <span className="text-[#5C6A82] text-base font-semibold leading-normal">
          Average performance of your team
        </span>
      </div>
      <div className="mt-4 w-[100%] gap-2 flex flex-row justify-between">
        {metricsWithInfo
          ?.sort((a, b) => a.order - b.order)
          .map((metricInfo, index) => (
            <AveragePerformanceItem key={index} metricInfo={metricInfo} />
          ))}
      </div>
    </div>
  );
};

export default AveragePerformanceBlock;
