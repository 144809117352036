import React, { useEffect, useState } from 'react';
import { ArrowLeft, Check, Edit2, Info } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { eventTracker } from '@/amplitude/eventTracker';
import { Button } from '@/components/Button/Button';
import ActivePlaybookSelectComponent from '@/components/PlaybookSettingsPage/ActivePlaybookSelectComponent';
import { Checkbox } from '@/components/Shared/Checkbox';
import { toast } from '@/components/Toast/toaster';

import { selectCalls, selectUser } from '../HomePage/slice/selectors';
import { usePlaybookSettingsSlice } from './slice';
import {
  selectActionPlaybookTemplate,
  selectActionUserSettings,
  selectAvailablePlaybooks,
  selectLoading,
  selectSelectedPlaybook,
} from './slice/selectors';

const PlaybookSettingsPage = () => {
  const dispatch = useDispatch();
  const { actions } = usePlaybookSettingsSlice();

  const user = useSelector(selectUser);
  const calls = useSelector(selectCalls);

  const userSettings = useSelector(selectActionUserSettings);
  const playbookTemplate = useSelector(selectActionPlaybookTemplate);

  const availablePlaybooks = useSelector(selectAvailablePlaybooks);
  const selectedPlaybook = useSelector(selectSelectedPlaybook);
  const loading = useSelector(selectLoading);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedPlaybook) {
      dispatch(actions.fetchActivePlaybook());
    }
    dispatch(actions.fetchAvailablePlaybooks());

    return () => {
      dispatch(actions.resetSelectedPlaybook());
    };
  }, []);

  useEffect(() => {
    eventTracker.playbookPage.pageOpen();
  }, []);

  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(null);
  const [message, setMessage] = useState('');
  const canEditPlaybook = user?.permissions?.canEditPlaybook === true;
  useEffect(() => {
    setIsSaving(
      userSettings.saving || (canEditPlaybook && playbookTemplate.saving),
    );
  }, [userSettings.saving, playbookTemplate.saving, canEditPlaybook]);
  useEffect(() => {
    setSaveSuccess(
      canEditPlaybook
        ? userSettings.saveSuccess && playbookTemplate.saveSuccess
        : userSettings.saveSuccess,
    );
  }, [userSettings.saveSuccess, playbookTemplate.saveSuccess, canEditPlaybook]);
  useEffect(() => {
    setMessage(
      [userSettings.message, playbookTemplate.message]
        .filter((m) => !!m)
        .join(', '),
    );
  }, [userSettings.message, playbookTemplate.message]);

  useEffect(() => {
    if (isSaving) return;
    if (!saveSuccess) return;

    if (saveSuccess) {
      toast.success({
        title: 'Template updated!',
        subtitle:
          'Playbook changes will apply to calls from this week and all future calls for your team',
      });
    } else {
      toast.error({
        subtitle: message,
      });
    }

    dispatch(actions.resetSaveSuccess());
  }, [isSaving, saveSuccess, message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    eventTracker.playbookPage.updateClick();
    dispatch(
      actions.updatePlaybookSettings({
        playbookItems: selectedPlaybook.playbookItems,
        playbookId: selectedPlaybook.id,
      }),
    );

    if (user?.permissions?.canEditPlaybook === true) {
      dispatch(
        actions.updatePlaybookTemplate({ playbookId: selectedPlaybook.id }),
      );
    }
  };

  const handleOnChange = (item) => (e) => {
    dispatch(actions.toggleEnabled({ id: item.id, enabled: !item.enabled }));
  };

  const itemsByCategory = selectedPlaybook?.playbookItems.reduce(
    (acc, item) => {
      const { category } = item;
      acc.set(category, [...(acc.get(category) || []), item]);
      return acc;
    },
    new Map(),
  );

  const navigateToEditCustomPlaybook = () => {
    navigate('/playbook/custom/edit');
  };

  const isCustom = (playbook) => playbook.name.endsWith('custom');

  if (loading || !selectedPlaybook) {
    return (
      <div
        className="flex justify-center items-center w-[100%] bg-white z-10"
        style={{
          height: 'calc(100vh - 70px)',
        }}
      >
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  return (
    <div className="w-full mt-8 mb-10">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-start gap-6 relative max-w-[784px] pl-[124px]"
      >
        <button
          type="button"
          className="text-main-gray-dark absolute left-[48px] top-[2px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={24} />
        </button>
        <div className="flex flex-col items-start justify-center gap-2.5">
          <p className="text-[#1C1C1C] text-xl font-bold">
            Sales Playbook Template
          </p>
          <p className="text-[#717D96] text-sm font-medium">
            Choose a sales framework through which to analyze sales calls
          </p>
        </div>
        {user?.permissions?.canEditPlaybook === true && (
          <ActivePlaybookSelectComponent
            selectedPlaybook={selectedPlaybook}
            availablePlaybooks={availablePlaybooks}
          />
        )}
        {[...itemsByCategory.entries()].map(([category, items]) => (
          <div
            key={category}
            className="flex flex-col items-start gap-2.5 shrink-0 w-full"
          >
            {category && (
              <p className="text-[#5C6A82] text-xs font-medium">{category}</p>
            )}
            <ul className="flex flex-col items-start gap-1.5 w-full">
              {items.map((item) => (
                <li data-testid={item.slug} key={item.id} className="w-full">
                  <Checkbox
                    id={`item[${item.id}]`}
                    checked={item.enabled}
                    onChange={handleOnChange(item)}
                    label={<DisplayPlaybookItem item={item} />}
                    labelClasses="flex items-center self-stretch gap-3 px-5 py-4 border border-[#ECECEC] rounded-[20px]"
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className="flex items-start gap-1">
          <Button type="submit" className="px-3 py-2.5">
            <Check size={16} />
            Update
          </Button>
          {canEditPlaybook && isCustom(selectedPlaybook) && (
            <Button variant="secondary" onClick={navigateToEditCustomPlaybook}>
              <Edit2 size={16} />
              Edit Template
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

const DisplayPlaybookItem = ({ item: { description, highlight } }) => {
  const [before, after] = highlight
    ? description.split(highlight, 2)
    : [description, null];

  return (
    <p className="select-none text-sm">
      {before}
      <span className="font-bold italic">{highlight}</span>
      {after}
    </p>
  );
};

export default PlaybookSettingsPage;
