import React from 'react';
import Avatar from 'react-avatar';

const RepsAttentionItem = ({ isFirstItem, repInfo }) => {
  if (!repInfo) return;

  return (
    <div
      className={`flex flex-row items-center mb-4 ${isFirstItem ? '' : 'mt-4'}`}
    >
      <div className="w-[180px] flex flex-row items-center">
        <div>
          <Avatar
            size="32px"
            round={true}
            color={'#2C6CF61A'}
            fgColor={'#2C6CF6'}
            name={`${repInfo.firstName} ${repInfo.lastName}`}
          />
        </div>
        <div className="ml-2 overflow-hidden overflow-ellipsis">
          <span className="font-medium whitespace-nowrap text-sm text-[#1C1C1C]">{`${repInfo.firstName} ${repInfo.lastName}`}</span>
        </div>
      </div>
      <div style={{ width: 'calc(100% - 286px)' }} className="ml-2">
        <div className="w-[100%] h-[8px] rounded-lg bg-[#ECECEC]">
          <div
            className={'h-[8px] rounded-lg'}
            style={{
              width: `${repInfo.playbookFulfilledPercentage}%`,
              backgroundColor: `${repInfo.lineColor}`,
            }}
          ></div>
        </div>
      </div>
      <div className="ml-2 w-[84px]">
        <span className="font-medium text-xs text-[#1C1C1C]">
          {repInfo.playbookFulfilledPercentage}% of calls
        </span>
      </div>
    </div>
  );
};

export default RepsAttentionItem;
