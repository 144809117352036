import React, { useState } from 'react';
import { Check, ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';

import { eventTracker } from '@/amplitude/eventTracker';
import { Button } from '@/components/Button/Button';

import { selectCall } from '../../pages/CallDetailsPage/slice/selectors';
import { selectCallTranscript } from '../../pages/HomePage/slice/selectors';
import { getSpeakerQuotes } from '../../utils/getSpeakerQuotes';
import { mapSpeakerletterToNumber } from '../../utils/speakerMapping';

export const SpeakerSelection = ({ updateCall }) => {
  let [expanded, setExpanded] = useState(null);
  const callTranscript = useSelector(selectCallTranscript);
  const speakerQuotes = getSpeakerQuotes(callTranscript);
  const speakers = Object.keys(speakerQuotes);
  const call = useSelector(selectCall);

  const getQuotesToShow = (speaker) => {
    if (speaker === expanded) {
      return speakerQuotes[speaker]?.slice(0, 10);
    } else {
      return speakerQuotes[speaker]?.slice(0, 3);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label
          htmlFor="date"
          className="block text-base text-dark font-extrabold"
        >
          Which speaker is the seller?
        </label>
        <span className="text-xs text-main-gray-dark font-normal">
          Tell us whose performance you want to analyze
        </span>
      </div>
      <div className="flex flex-col gap-2">
        {speakers?.map((speaker) => {
          return (
            <div
              className="flex flex-col gap-[10px] rounded-[20px] border-[1px] border-solid border-main-gray w-[744px] px-5 py-4"
              key={speaker}
            >
              <div className="flex items-start self-stretch">
                <div className="w-full flex flex-col gap-2">
                  <p className="text-dark font-semibold text-base">
                    Speaker {mapSpeakerletterToNumber(speaker)}
                  </p>
                  <div className="text-main-gray-dark text-xs">Quotes:</div>
                </div>
                {speaker === call?.mainSpeaker ? (
                  <Button variant="primary">
                    <Check size={16} />
                    Selected
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      eventTracker.callDetails.changeSellerApply({
                        callId: call.id,
                        speaker,
                      });
                      updateCall(
                        {
                          mainSpeaker: speaker,
                        },
                        'speaker',
                      );
                    }}
                  >
                    Select
                  </Button>
                )}
              </div>
              <div>
                {
                  <ul className="list-inside list-disc text-sm text-dark">
                    {getQuotesToShow(speaker).map((q) => (
                      <li key={q}>{q}</li>
                    ))}
                  </ul>
                }
                {speaker !== expanded && (
                  <button
                    className="flex items-center gap-1 mt-1.5 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(speaker);
                    }}
                    type="button"
                  >
                    <ChevronDown size={16} />{' '}
                    <span className="text-sm font-semibold">More</span>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
