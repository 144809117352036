import React from 'react';
import { HelpCircle, Meh, Trash } from 'react-feather';

import { Api } from '@/api/api';
import { Button } from '@/components/Button/Button';

interface NotSalesCallFailedStateParams {
  openDeleteCallModal: any;
  callId: string;
}

export const NotSalesCallFailedState: React.FC<
  NotSalesCallFailedStateParams
> = ({ openDeleteCallModal, callId }) => {
  const forceCallAnalyze = async () => {
    await Api.call.forceCallAnalyze(callId);

    window.location.reload();
  };

  return (
    <div className="flex justify-center flex-col items-center gap-4">
      <div className="w-[72px] h-[72px] rounded-full bg-accent-yellow/10 flex justify-center items-center">
        <Meh size={40} className="text-accent-yellow" />
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="text-xl font-bold text-dark">
          Sounds like this is not a sales call
        </p>
        <p className="text-sm font-medium text-main-gray-dark">
          We currently only provide feedback for sales calls
        </p>
      </div>
      <div>
        <Button
          variant="secondary"
          onClick={forceCallAnalyze}
          className="w-fit"
        >
          <HelpCircle size={16} />
          It's actually a sales call
        </Button>
        <Button
          variant="danger"
          onClick={openDeleteCallModal}
          className="w-fit ml-1"
        >
          <Trash size={16} />
          Delete Call
        </Button>
      </div>
    </div>
  );
};
