export class TeamPerformanceHelperV2 {
  static columnIdToSortBy = {
    fullName: 'fullName',
    overall: 'overall',
    personalizedDemo: 'personalizedDemo',
    objectionHandling: 'objectionHandling',
    alignsOnNextSteps: 'alignsOnNextSteps',
    speechPace: 'speechPace',
    talkRatio: 'talkRatio',
  };

  static good = {
    textColor: '#1BBA0D',
    bgColor: '#0FB6001a',
    status: 'good',
  };

  static bad = {
    textColor: '#E74029',
    bgColor: '#E740291a',
    status: 'bad',
  };

  static okay = {
    textColor: '#1C1C1C',
    bgColor: '#FFF',
    status: 'okay',
  };

  static rangesByMemberMetric = {
    overall: (metricResult) => {
      switch (true) {
        case metricResult >= 90:
          return this.good;
        case metricResult < 40:
          return this.bad;
        default:
          return this.okay;
      }
    },
    personalizedDemo: (metricResult) => {
      switch (true) {
        case metricResult >= 90:
          return this.good;
        case metricResult < 40:
          return this.bad;
        default:
          return this.okay;
      }
    },
    objectionHandling: (metricResult) => {
      switch (true) {
        case metricResult >= 90:
          return this.good;
        case metricResult < 40:
          return this.bad;
        default:
          return this.okay;
      }
    },
    alignsOnNextSteps: (metricResult) => {
      switch (true) {
        case metricResult >= 90:
          return this.good;
        case metricResult < 40:
          return this.bad;
        default:
          return this.okay;
      }
    },
    speechPace: (metricResult) => {
      switch (true) {
        case metricResult >= 110 && metricResult <= 170:
          return this.good;
        case metricResult > 200:
          return this.bad;
        default:
          return this.okay;
      }
    },
    talkRatio: (metricResult) => {
      switch (true) {
        case metricResult >= 90:
          return this.good;
        case metricResult < 40:
          return this.bad;
        default:
          return this.okay;
      }
    },
  };

  static setMembersMetricsInfo(teamPerformance) {
    const membersWithInfo = [];

    teamPerformance.forEach((memberPerformance) => {
      const updatedMember = JSON.parse(JSON.stringify(memberPerformance));

      Object.keys(memberPerformance).forEach((key) => {
        const metricValue = memberPerformance[key];
        const calculateStatusFunction = this.rangesByMemberMetric[key];

        if (!calculateStatusFunction) return;

        const status = calculateStatusFunction(metricValue);

        updatedMember[key] = {
          key,
          ...status,
          value: metricValue,
        };
      });

      membersWithInfo.push(updatedMember);
    });

    return membersWithInfo;
  }
}
