import dayjs from 'dayjs';

import { getAudioDuration } from '@/utils/getAudioDuration';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const getCallUploadUrl = async (params: {
  file: File;
  callId?: string;
  fileExtension?: string;
}) => {
  const response = await fetch(backendURL + '/api/calls/uploadUrl', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `jwt ${localStorage.getItem('authToken')}`,
    },
    body: JSON.stringify({
      callId: params.callId,
      fileName: params.file.name,
      fileType: params.file.type,
      fileExtension: params.fileExtension,
    }),
  });

  const body = await response.json();
  const key: string = body.key;
  const callId: string = params.callId || body.callId;
  const uploadUrl: string = body.uploadUrl;

  return { uploadUrl, callId, key };
};

const postNewCall = async (params: {
  key: string;
  callId: string;
  file: File;
  duration?: number;
}) => {
  const duration = params.duration || (await getAudioDuration(params.file));

  if (!duration) {
    console.error('Duration should be defined');
  }

  const response = await fetch(backendURL + '/api/calls', {
    method: 'POST',
    headers: {
      Authorization: `jwt ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: params.key,
      callId: params.callId,
      fileName: params.file.name,
      duration,
      callType: 'Discovery',
      date: dayjs().format('YYYY-MM-DD'),
      noOfSpeakers: null,
    }),
  });

  return response.json();
};

export const createNewCall = async (params: {
  file: File;
  duration?: number;
  callId?: string;
  fileExtension?: string;
}) => {
  const { uploadUrl, key, callId } = await getCallUploadUrl({
    file: params.file,
    callId: params.callId,
    fileExtension: params.fileExtension,
  });

  await fetch(uploadUrl, {
    method: 'PUT',
    body: params.file,
  });

  const res = await postNewCall({
    key,
    callId,
    file: params.file,
    duration: params.duration,
  });

  return res.data;
};
