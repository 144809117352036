export const API: typeof fetch = (url, config = {}) => {
  return fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `jwt ${localStorage.getItem('authToken')}`,
      ...config.headers,
    },
    ...config,
  });
};
