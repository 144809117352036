import React from 'react';

import cn from '@/utils/cn';

interface SectionTitleProps extends React.ComponentProps<'p'> {
  children: React.ReactNode;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <p
      className={cn(
        'text-main-gray-dark text-xs font-semibold leading-6 uppercase',
        className,
      )}
      {...restProps}
    >
      {children}
    </p>
  );
};
