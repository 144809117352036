import { create } from 'zustand';

interface HelpModalStore {
  isHelpModalOpen: boolean;
  openHelpModal: () => void;
  closeHelpModal: () => void;
}

export const useHelpModalStore = create<HelpModalStore>((set) => ({
  isHelpModalOpen: false,
  openHelpModal: () => set(() => ({ isHelpModalOpen: true })),
  closeHelpModal: () => set(() => ({ isHelpModalOpen: false })),
}));
