import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import cn from '@/utils/cn';

import { Button, ButtonProps } from '../Button/Button';

interface LinkButtonProps extends ButtonProps {
  to: LinkProps['to'];
  buttonClassName?: ButtonProps['className'];
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  children,
  className,
  buttonClassName,
  ...buttonProps
}) => {
  return (
    <Link to={to} className={cn('h-fit', className)}>
      <Button tabIndex={-1} className={buttonClassName} {...buttonProps}>
        {children}
      </Button>
    </Link>
  );
};
