import React, { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { usePreventLeave } from '@/hooks/usePreventLeave';
import { useSubscription } from '@/hooks/useSubscription';
import { useHomeSlice } from '@/pages/HomePage/slice';
import cn from '@/utils/cn';

import { Modal } from '../Shared/Modal';
import { useCreateNewCall } from './hooks/useCreateNewCall';
import { ErrorStep } from './steps/ErrorStep';
import { LoadingStep } from './steps/LoadingStep';
import { SelectFileStep } from './steps/SelectFileStep';

export enum CallUploadError {
  InvalidFileType = 'InvalidFileType',
  InvalidFileSize = 'InvalidFileSize',
  LongDuration = 'LongDuration',
  ShortDuration = 'ShortDuration',
  UnknownError = 'UnknownError',
  CallsLimit = 'CallsLimit',
  // TODO: add error for audio with silence
}

enum CallUploadStep {
  FileSelect = 'FILE_SELECT',
  Loading = 'LOADING',
  Error = 'ERROR',
}

interface UploadCallModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadCallModal: React.FC<UploadCallModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const navigate = useNavigate();

  const [uploadError, setUploadError] = useState<CallUploadError | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { createNewCall, isPending } = useCreateNewCall();

  // Show "leave site" dialog
  usePreventLeave(isPending);

  const { canCreateNewCalls, isPending: subscriptionLoading } =
    useSubscription();

  if (subscriptionLoading || !canCreateNewCalls) {
    return <></>;
  }

  const getUploadStep = () => {
    if (uploadError) return CallUploadStep.Error;
    if (isPending) return CallUploadStep.Loading;
    return CallUploadStep.FileSelect;
  };
  const uploadStep = getUploadStep();

  const handleFileChange = async (file: File) => {
    try {
      const call = await createNewCall({ file });
      dispatch(actions.fetchCalls(null));
      closeModal();
      eventTracker.upload.uploadCompleted({ callId: call.id });
      navigate(`/calls/${call.id}`);
    } catch (error) {
      setUploadError(CallUploadError.UnknownError);
    }
  };

  const canCloseModal = !isPending;

  const closeModal = () => {
    if (!canCloseModal) return;

    onClose();
    reset();
  };

  const reset = () => {
    setUploadError(null);
  };

  return (
    <Modal show={isOpen} onClose={closeModal}>
      <div data-testid="upload-call-modal" className="w-[800px]">
        {canCloseModal && (
          <X
            size={24}
            color="#5C6A82"
            className="absolute right-3 top-3 cursor-pointer"
            onClick={closeModal}
          />
        )}
        <div>
          <p className="block text-base text-dark font-bold mb-1">
            Upload Call
          </p>
          <p className="text-xs text-main-gray-dark">
            Upload call recordings to analyze performance
          </p>
        </div>

        <div
          className={cn(
            'h-[320px] mt-2 border border-dashed border-rounded rounded-lg border-gray-900/25 transition-colors',
            isDragging && 'bg-[#2C6CF614] border-accent-blue',
          )}
        >
          {uploadStep === CallUploadStep.FileSelect && (
            <SelectFileStep
              onFileChange={handleFileChange}
              onError={setUploadError}
              onDragChange={setIsDragging}
            />
          )}
          {uploadStep === CallUploadStep.Error && (
            <ErrorStep uploadError={uploadError} onTryAgainClick={reset} />
          )}
          {uploadStep === CallUploadStep.Loading && <LoadingStep />}
        </div>
      </div>
    </Modal>
  );
};
