// https://www.chartjs.org/docs/latest/charts/line.html
export const ChartDatasetsConst = (data, groupBy) => [
  {
    data,
    borderColor: '#2C6CF6',
    hoverBorderColor: '#2C6CF6',
    borderWidth: 4,

    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#2C6CF6',

    pointBorderWidth: 3,
    pointHoverBorderWidth: 3,

    pointRadius: groupBy === 'byWeek' && data.length >= 2 ? 0 : 6,
    pointHoverRadius: 6,

    tension: 0.25,
    spanGaps: true,
    borderJoinStyle: 'round',
    borderRadius: 50,
  },
];
