import { useState } from 'react';

export const useCallsList = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen((isOpen) => !isOpen);

  return {
    isOpen,
    toggle,
  };
};
