import { useMutation } from '@tanstack/react-query';

import { eventTracker } from '@/amplitude/eventTracker';
import { resetPassword as resetPasswordAPICall } from '@/api/auth/reset-password/resetPassword';

export const useResetPassword = () => {
  const { mutateAsync: resetPassword, ...rest } = useMutation({
    mutationFn: (params: { resetPasswordToken: string; password: string }) => {
      return resetPasswordAPICall(params);
    },
    onSuccess: (data) => {
      localStorage.setItem('authToken', data.token);
      eventTracker.auth.passwordResetCompleted({
        email: data.email,
      });
    },
    onError: () => {
      console.error('Something went wrong');
    },
  });

  return { resetPassword, ...rest };
};
