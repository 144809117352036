import './style.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import TeamPerformanceTableV2 from '@/components/LeadershipTeamPage/TeamPerformanceTableV2';
import { selectUser } from '@/pages/HomePage/slice/selectors';

import TimeRangeOptions from '../../components/LeadershipPage/TimeRangeOptionsComponent';
import EditColumnsMenu from '../../components/LeadershipTeamPage/EditColumnsMenu';
import TeamPerformanceTable from '../../components/LeadershipTeamPage/TeamPerformanceTable';
import { useLeadershipTeamSlice } from './slice';
import { selectTeamTablePageLoading } from './slice/selectors';

const LeadershipTeamPage = () => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipTeamSlice();

  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const teamTablePageLoading = useSelector(selectTeamTablePageLoading);

  useEffect(() => {
    dispatch(actions.setTeamTablePageLoading(true));
  }, []);

  return (
    <div className="relative ">
      {teamTablePageLoading && !useMock && (
        <div
          className="absolute flex justify-center items-center w-[100%] bg-white z-10"
          style={{
            height: '100%',
          }}
        >
          <ClipLoader color="rgb(37 99 235)" />
        </div>
      )}
      <div
        className="h-[100%] pt-8 pl-[72px] pr-[72px] pb-[34px] overflow-y-auto overflow-x-hidden"
        style={{
          height: 'calc(100vh - 70px)',
        }}
      >
        <div className="mb-8 flex flex-row justify-between items-center">
          <span className="text-2xl font-semibold text-[#000]">Team</span>
          <div className="flex gap-1">
            <TimeRangeOptions className="h-[40px] w-[135px] bg-accent-blue flex justify-center items-center h-[100%]" />
            <EditColumnsMenu />
          </div>
        </div>
        <TeamPerformanceTable />
      </div>
    </div>
  );
};

export default LeadershipTeamPage;
