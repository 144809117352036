import { put, takeLatest } from 'redux-saga/effects';

import { homeActions } from '../../HomePage/slice';
import { callDetailsActions as actions } from '.';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function* doFetchCall(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    if (response.status === 200) {
      const body = yield response.json();
      yield put(actions.fetchCallSuccess(body?.data));
    } else {
      yield put(actions.fetchCallFailed('Fetch call Failed'));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.fetchCallFailed('Fetch call Failed'));
  }
}

function* doFetchCallQualitativeAnalysis(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload.id}/qualitative-analysis`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    if (response.status === 200) {
      const body = yield response.json();
      yield put(actions.fetchCallQualitativeAnalysisSuccess(body?.data));
    } else {
      yield put(
        actions.fetchCallQualitativeAnalysisFailed('Fetch call Failed'),
      );
    }
  } catch (err) {
    yield put(actions.fetchCallQualitativeAnalysisFailed('Fetch call Failed'));
  }
}

function* doUpdateCall(action) {
  try {
    const response = yield fetch(
      backendURL + `/api/calls/${action.payload.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(action.payload.body),
      },
    );
    const body = yield response.json();
    yield put(actions.updateCallSuccess(body.data));
    yield put(homeActions.updateCallSuccess({ data: body.data }));
  } catch (err) {
    console.log('save call failed', err);
    yield put(actions.updateCallFailed('Save Call Failed'));
  }
}

export function* callDetailsSaga() {
  yield takeLatest(actions.fetchCall.type, doFetchCall);
  yield takeLatest(
    actions.fetchCallQualitativeAnalysis.type,
    doFetchCallQualitativeAnalysis,
  );
  yield takeLatest(actions.updateCall.type, doUpdateCall);
}
