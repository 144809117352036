import { Response } from '@/api/response';
import { InsightsAcrossCallsTimeRangeOptionsValue } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';
import { DateCallsInfo } from '@/components/InsightsAcrossCallsPage/mappers/BaseInsightsAcrossCallsChartMapper';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export interface GetInsightsChartInfoResponse {
  chartData: DateCallsInfo[];
  callsInProcessingState: number;
  selectedTimeRangeCallsCount: number;
  hasAtLeastOneCall: boolean;
  isDemoMode: boolean;
  organizationMetadata: {
    lastReanalyzedIndex: number;
  };
}

export const getInsightsChartInfo =
  (timeRange?: InsightsAcrossCallsTimeRangeOptionsValue) =>
  async (): Promise<GetInsightsChartInfoResponse | undefined> => {
    if (!timeRange) return;

    const query = `timeRange=${timeRange}`;

    const response = await fetch(backendURL + `/api/insights/chart?${query}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    });

    const result: Response<GetInsightsChartInfoResponse> =
      await response.json();

    return result.data;
  };
