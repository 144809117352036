import { useEffect } from 'react';
const backendURL = process.env.REACT_APP_BACKEND_URL;

export function ImpersonatePage() {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  useEffect(() => {
    const getImpersonateToken = async () => {
      const response = await fetch(
        backendURL +
          `/api/users/impersonate?user=${encodeURIComponent(params.get('user'))}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `jwt ${localStorage.getItem('authToken')}`,
          },
        },
      );
      const body = await response.json();
      localStorage.setItem('authToken', body.jwt);
      window.location.href = '/';
    };
    getImpersonateToken();
  });

  return <></>;
}
