import React, { useRef } from 'react';
import { Paperclip, XCircle } from 'react-feather';

interface FileSelectorProps {
  files: File[];
  onChange: (files: File[]) => void;
  title?: string;
  disabled?: boolean;
}

export const FileSelector: React.FC<FileSelectorProps> = ({
  files,
  onChange,
  title = 'Attach image or video',
  disabled,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  return (
    <div>
      <FilePreviewList
        className="mb-4"
        files={files}
        onFileRemove={(removedFile) => {
          const newFiles = files.filter((f) => f !== removedFile);
          onChange(newFiles);
        }}
        disabled={disabled}
      />

      <button
        disabled={disabled}
        className="text-main-gray-dark text-sm font-semibold w-fit"
        onClick={() => {
          if (!fileInput.current) return;
          fileInput.current.click();
        }}
      >
        <div className="flex items-center gap-1">
          <Paperclip size={16} />
          <span>{title}</span>
        </div>
      </button>

      <input
        accept="image/*, video/*"
        type="file"
        ref={fileInput}
        onChange={(e) => {
          const fileList = e.target.files;
          if (!fileList) return;

          const newFiles = Array.from(fileList);

          onChange([...files, ...newFiles]);
        }}
        style={{ display: 'none' }}
      />
    </div>
  );
};

const FilePreview: React.FC<{
  file: File;
  onRemoveClick: () => void;
  disabled?: boolean;
}> = ({ file, onRemoveClick, disabled }) => {
  return (
    <div className="flex justify-between text-main-gray-dark text-sm px-3 py-2">
      <span>{file.name}</span>
      <button onClick={onRemoveClick} disabled={disabled}>
        <XCircle color="#B9C7D7" size={16} />
      </button>
    </div>
  );
};

interface FilePreviewListProps extends React.ComponentProps<'div'> {
  files: File[];
  onFileRemove: (file: File) => void;
  disabled?: boolean;
}

const FilePreviewList: React.FC<FilePreviewListProps> = ({
  files,
  onFileRemove,
  disabled,
  ...restProps
}) => {
  return (
    <div {...restProps}>
      {files.map((file, index) => (
        <React.Fragment key={file.name}>
          {index !== 0 && (
            <div className="border-t w-full border-t-[#F6F6F6]" />
          )}
          <FilePreview
            file={file}
            onRemoveClick={() => {
              onFileRemove(file);
            }}
            disabled={disabled}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
