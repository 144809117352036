import { useMutation, useQueryClient } from '@tanstack/react-query';

import { activateIsDemoMode as activateIsDemoModeAPI } from '@/api/insights/activate-is-demo-mode';

export const useActivateDemoMode = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: activateIsDemoMode, ...rest } = useMutation({
    mutationFn: activateIsDemoModeAPI,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['insightsChartInfo'] });
      queryClient.invalidateQueries({ queryKey: ['prospectInsights'] });
      queryClient.invalidateQueries({ queryKey: ['playbookInsights'] });
      queryClient.invalidateQueries({ queryKey: ['metricsInsights'] });
      queryClient.invalidateQueries({ queryKey: ['insightsTopQuestions'] });
    },
  });

  return { activateIsDemoMode, ...rest };
};
