import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { Input, InputProps } from './Input';

interface EmailInputProps extends InputProps {}

export const EmailInput: React.FC<EmailInputProps> = (props) => {
  return (
    <Input
      type="email"
      placeholder="Enter email address"
      leftSlot={<EnvelopeIcon width={20} height={20} />}
      leftSlotContainerClassName="pointer-events-none"
      {...props}
    />
  );
};
