const backendURL = process.env.REACT_APP_BACKEND_URL;

interface ResetPasswordResponse {
  token: string;
  email: string;
}

export const resetPassword = async (params: {
  resetPasswordToken: string;
  password: string;
}): Promise<ResetPasswordResponse> => {
  const response = await fetch(backendURL + '/api/password/resetPassword', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `jwt ${params.resetPasswordToken}`,
    },
    body: JSON.stringify({
      password: params.password,
      token: params.resetPasswordToken,
    }),
  });

  return response.json();
};
