import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.home || initialState;

export const selectLoggedIn = createSelector(
  [selectSlice],
  (state) => state.loggedIn || false, // explicitly false to prevent rerender from undefined initial state
);

export const selectIsImpersonating = createSelector(
  [selectSlice],
  (state) => state.isImpersonating,
);

export const selectRegisterErrorMessage = createSelector(
  [selectSlice],
  (state) => state.registerErrorMessage,
);

export const selectLoginError = createSelector(
  [selectSlice],
  (state) => state.loginError,
);

export const selectLoading = createSelector(
  [selectSlice],
  (state) => state.loading,
);

export const selectMessage = createSelector(
  [selectSlice],
  (state) => state.message,
);

export const selectCalls = createSelector(
  [selectSlice],
  (state) => state.calls,
);

export const selectSortedCalls = createSelector(
  [selectSlice],
  (state) => state.sortedCalls,
);

export const selectIsFetchingCallStatuses = createSelector(
  [selectSlice],
  (state) => state.isFetchingCallStatuses || false,
);

export const selectCallAudio = createSelector(
  [selectSlice],
  (state) => state.callAudio,
);

export const selectSelectedCall = createSelector(
  [selectSlice],
  (state) => state.selectedCall,
);

export const selectCallTranscript = createSelector(
  [selectSlice],
  (state) => state.callTranscript,
);

export const selectCallAnalysis = createSelector(
  [selectSlice],
  (state) => state.callAnalysis,
);

export const selectAnalysisLoading = createSelector(
  [selectSlice],
  (state) => state.analysisLoading,
);

export const selectTranscriptLoading = createSelector(
  [selectSlice],
  (state) => state.transcriptLoading,
);

export const selectAnalysisType = createSelector(
  [selectSlice],
  (state) => state.analysisType,
);

export const selectSlowAnalysisLoading = createSelector(
  [selectSlice],
  (state) => state.slowAnalysisLoading,
);

export const selectSavingCall = createSelector(
  [selectSlice],
  (state) => state.savingCall,
);

export const selectAnalyticsLoading = createSelector(
  [selectSlice],
  (state) => state.analyticsLoading,
);

export const selectAnalytics = createSelector(
  [selectSlice],
  (state) => state.analytics,
);

export const selectCallQualitativeAnalysis = createSelector(
  [selectSlice],
  (state) => state.callQualitativeAnalysis,
);

export const selectCallQualitativeAnalysisLoading = createSelector(
  [selectSlice],
  (state) => state.callQualitativeAnalysisLoading,
);

export const selectTempEmail = createSelector(
  [selectSlice],
  (state) => state.tempEmail,
);

export const selectSavedCallId = createSelector(
  [selectSlice],
  (state) => state.savedCall?.id,
);

export const selectUpdatedCallId = createSelector(
  [selectSlice],
  (state) => state.updatedCallId,
);

export const selectTranscriptId = createSelector(
  [selectSlice],
  (state) => state.transcriptId,
);

export const selectSpeakerQuotes = createSelector(
  [selectSlice],
  (state) => state.speakerQuotes,
);

export const selectPollingTranscriptId = createSelector(
  [selectSlice],
  (state) => state.pollingTranscriptId,
);

export const selectUploadStep = createSelector(
  [selectSlice],
  (state) => state.uploadStep,
);

export const selectDirectUploadFileLoading = createSelector(
  [selectSlice],
  (state) => state.directUploadFileLoading,
);

export const selectDirectUploadFile = createSelector(
  [selectSlice],
  (state) => state.directUploadFile,
);

export const selectFileUploadError = createSelector(
  [selectSlice],
  (state) => state.fileUploadError,
);

export const selectRenameCallModalOpen = createSelector(
  [selectSlice],
  (state) => state.renameCallModalOpen,
);

export const selectDeleteCallConfirmationModalOpen = createSelector(
  [selectSlice],
  (state) => state.deleteCallConfirmationModalOpen,
);

export const selectRedirectAfterDeleteCall = createSelector(
  [selectSlice],
  (state) => state.redirectAfterCallDelete,
);

export const selectRenamingCall = createSelector(
  [selectSlice],
  (state) => state.renamingCall,
);

export const selectDeleteCall = createSelector(
  [selectSlice],
  (state) => state.deleteCall,
);

export const selectDeleteCallLoading = createSelector(
  [selectSlice],
  (state) => state.deleteCallLoading,
);

export const selectActiveRescheduleCall = createSelector(
  [selectSlice],
  (state) => state.activeRescheduleCall,
);

export const selectFillerWords = createSelector(
  [selectSlice],
  (state) => state.fillerWords,
);

export const selectUpdatingCall = createSelector(
  [selectSlice],
  (state) => state.updatingCall,
);

export const selectUser = createSelector([selectSlice], (state) => state.user);

export const selectUpdatingCallId = createSelector(
  [selectSlice],
  (state) => state.updatingCallId,
);

export const selectAnalyticsGrouping = createSelector(
  [selectSlice],
  (state) => state.analyticsGrouping,
);

export const selectInsightsChartOption = createSelector(
  [selectSlice],
  (state) => state.insightsChartOption,
);

export const selectAppLoaded = createSelector(
  [selectSlice],
  (state) => state.appLoaded,
);

export const selectuploadController = createSelector(
  [selectSlice],
  (state) => state.uploadController,
);

export const selectIsUpdateCallDateModalOpen = createSelector(
  [selectSlice],
  (state) => state.updateCallDateModalOpen,
);

export const selectFetchCallsLoading = createSelector(
  [selectSlice],
  (state) => state.fetchCallsLoading,
);
