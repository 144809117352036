import { ClipLoader } from 'react-spinners';

import { useCallQuestions } from '@/hooks/useCallQuestions';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import { formatTimestamp } from '@/utils/formatDate';

import { CollapsibleItem } from '../CollapsibleItem/CollapsibleItem';
import { SectionTitle } from '../SectionTitle/SectionTitle';

export const Questions = ({ callId }) => {
  const { data, isLoading } = useCallQuestions(callId);

  const { navigateToTranscript } = useNavigateToTranscript();

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  return (
    <div>
      {data.length > 0 ? (
        <div key="prospects-top-questions" className="h-full flex flex-col">
          <SectionTitle className="mb-2 mt-5">
            Prospect’s top questions and objections
          </SectionTitle>
          <div className="flex flex-col gap-1.5">
            {data.map(({ id, summary, answer, timestamp }) => (
              <div key={id}>
                <CollapsibleItem title={summary}>
                  <div className="bg-[#F6F6F6] rounded-[14px] p-[14px]">
                    <div className="flex flex-row mb-2 items-center">
                      <p className="text-xs text-[#5C6A82] font-semibold">
                        Your answer
                      </p>
                      <p
                        className="text-[#2C6CF6] text-xs font-normal cursor-pointer flex ml-[3px]"
                        onClick={() =>
                          navigateToTranscript({
                            callId,
                            text: answer,
                            timestamp: Number(timestamp),
                          })
                        }
                      >
                        at {formatTimestamp(timestamp)}
                      </p>
                    </div>
                    <p className="text-black text-sm font-semibold">{answer}</p>
                  </div>
                </CollapsibleItem>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-12 text-center">
            <img src="/question.svg" className="mx-auto" />
            <p className="mt-4 font-inter font-semibold text-lg leading-tight">
              We didn’t detect prospect questions during this call
            </p>
            <p className="mt-2 font-inter font-normal text-sm leading-tight tracking-wide text-gray-700">
              When the prospects ask questions about the product or service,
              they will appear here.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
