import React from 'react';

import cn from '@/utils/cn';

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  isError,
  ...restProps
}) => {
  return (
    <textarea
      {...restProps}
      className={cn(
        'min-h-[44px] px-3 w-full font-medium rounded-lg border border-main-gray outline-none focus:border-accent-blue placeholder-gray-dark placeholder-opacity-75 placeholder:text-base placeholder:font-normal',
        isError && 'border-accent-red focus:border-accent-red',
        className,
      )}
    />
  );
};
