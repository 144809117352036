import { useMutation, useQueryClient } from '@tanstack/react-query';

import { inviteAssistantAgain as inviteAssistantAgainAPI } from '@/api/calendar/invite-assistant-again';

export const useInviteAssistantAgain = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: inviteAssistantAgain, ...rest } = useMutation({
    mutationFn: inviteAssistantAgainAPI,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calls'] });
    },
  });

  return { inviteAssistantAgain, ...rest };
};
