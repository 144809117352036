import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { Logo } from '@/components/Shared/Logo';

import LinkToSignIn from '../../components/AuthPage/LinkToSignIn';
import LinkToSignUp from '../../components/AuthPage/LinkToSignUp';
import AuthBenefits from '../../components/Shared/AuthBenefits';
import { useSignUpSlice } from '../SignUpPage/slice';

export const AuthPage = () => {
  const dispatch = useDispatch();
  const { actions } = useSignUpSlice();
  const location = useLocation();

  const isRegisterPage = location.pathname === '/auth/register';

  useEffect(() => {
    return () => {
      dispatch(actions.setEmailNotUsed(null));
      dispatch(actions.setVerifiedEmail(null));
    };
  }, []);

  return (
    <div>
      <div className="w-full h-[72px] bg-white flex items-center px-6 justify-between border-b-[1px] border border-[#EEE]">
        <Logo to="https://silkchart.com" />
        {isRegisterPage ? <LinkToSignIn /> : <LinkToSignUp />}
      </div>
      <div className="flex flex-row" style={{ height: 'calc(100vh - 72px)' }}>
        <Outlet />
        <AuthBenefits />
      </div>
    </div>
  );
};
