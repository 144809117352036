import { useEffect, useState } from 'react';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const useCallQuestions = (callId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(
          backendURL + `/api/calls/${callId}/top-questions`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `jwt ${localStorage.getItem('authToken')}`,
            },
          },
        );
        const body = await response.json();
        setData(body.data);
      } catch (err) {
        console.error('fetch calls failed', err);
      }
      setIsLoading(false);
    };

    fetchQuestions();
  }, []);

  return { data, isLoading };
};
