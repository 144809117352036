import React from 'react';

interface PlaybookItemTitleParams {
  item: any;
}

export const PlaybookItemTitle: React.FC<PlaybookItemTitleParams> = ({
  item,
}: PlaybookItemTitleParams) => {
  const [before, after] = item.highlight
    ? item.description.split(item.highlight, 2)
    : [item.description, null];
  return (
    <div className="text-sm font-normal">
      {before}
      <span className="font-bold">{item.highlight}</span>
      {after}
    </div>
  );
};
