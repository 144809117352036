import cn from '@/utils/cn';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelClasses?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  labelClasses,
  id,
  ...inputProps
}) => {
  return (
    <label
      htmlFor={id}
      className={
        labelClasses ||
        'flex items-center gap-1 text-main-gray-dark enabled:hover:text-black select-none'
      }
    >
      <input
        id={id}
        type="checkbox"
        className={cn(`
          appearance-none bg-white border-2 border-main-gray-dark
          peer
          w-5 h-5 rounded-sm
          relative shrink-0
          checked:bg-none checked:bg-accent-blue checked:border-0
          enabled:hover:bg-accent-dark-blue-12-percent
          enabled:hover:checked:bg-accent-dark-blue
          disabled:border-main-gray disabled:checked:bg-main-gray-dark disabled:cursor-not-allowed
          focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-accent-blue
          `)}
        {...inputProps}
      />
      {label}
      <svg
        className="absolute w-5 h-5 hidden peer-checked:block pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6667 5L7.50004 14.1667L3.33337 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </label>
  );
};
