import { call, put, takeLatest } from 'redux-saga/effects';

import { leadershipTeamActions } from '../../LeadershipTeam/slice';
import { playbookSettingsActions as actions } from '.';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function buildAvailablePlaybooksOptions(availablePlaybooks) {
  const customPlaybookIndex = availablePlaybooks.findIndex((el) =>
    el.prettyName.toLowerCase().includes('custom'),
  );

  if (customPlaybookIndex >= 0) {
    const customPlaybookItem = availablePlaybooks.splice(
      customPlaybookIndex,
      1,
    )[0];
    availablePlaybooks.push(customPlaybookItem);
  } else {
    const buttonPlaybook = {
      id: 'CREATE_CUSTOM_PLAYBOOK',
      name: '+ Create custom template',
      prettyName: '+ Create custom template',
    };
    availablePlaybooks.push(buttonPlaybook);
  }

  return availablePlaybooks;
}

function* doUpdatePlaybookSettings(action) {
  try {
    yield call(
      fetch,
      `${backendURL}/api/organization/playbook/template/${action.payload.playbookId}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(action.payload.playbookItems),
      },
    );
    yield put(actions.updatePlaybookSettingsSuccess());
  } catch (err) {
    yield put(
      actions.updatePlaybookSettingsFailed('Update Playbook Template Failed'),
    );
  }
}

function* doFetchAvailablePlaybooks() {
  try {
    const response = yield call(
      fetch,
      `${backendURL}/api/organization/playbook/template`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    const body = yield response.json();

    /* Warn: these contain either be real playbooks or "disguised buttons" like "+ Create custom template" */
    const reorderedPlaybooksOptions = buildAvailablePlaybooksOptions(body);

    yield put(
      actions.fetchAvailablePlaybooksSuccess({
        availablePlaybooks: reorderedPlaybooksOptions,
      }),
    );
  } catch (err) {
    yield put(
      actions.fetchAvailablePlaybooksFailed('Fetch Available Playbook Failed'),
    );
  }
}

function* doFetchActivePlaybook() {
  try {
    const response = yield call(
      fetch,
      `${backendURL}/api/organization/playbook/template/active`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    const body = yield response.json();

    yield put(actions.fetchActivePlaybookSuccess(body));
  } catch (err) {
    yield put(
      actions.fetchActivePlaybookFailed('Fetch Active Playbook Failed'),
    );
  }
}

function* doFetchPlaybookById(action) {
  try {
    const response = yield call(
      fetch,
      `${backendURL}/api/organization/playbook/template/${action.payload.playbookId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );
    const body = yield response.json();
    console.log({ body });

    yield put(
      actions.fetchPlaybookByIdSuccess({
        id: body.playbook.id,
        name: body.playbook.name,
        playbookItems: body.items,
      }),
    );
  } catch (err) {
    yield put(actions.fetchPlaybookByIdFailed('Fetch Playbook by id Failed'));
  }
}

function* doUpdatePlaybookTemplate(action) {
  try {
    const body = {
      activePlaybookId: action.payload.playbookId,
    };

    yield call(
      fetch,
      `${backendURL}/api/organization/playbook/template/active`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(body),
      },
    );
    yield put(leadershipTeamActions.resetShowTeamTableColumnOptions());
    yield put(leadershipTeamActions.resetActivePlaybookMetrics());
    yield put(leadershipTeamActions.resetTeamPerformance());
    yield put(actions.updatePlaybookTemplateSuccess());
  } catch (err) {
    yield put(
      actions.updatePlaybookSettingsFailed('Update Playbook Template Failed'),
    );
  }
}

export function* playbookSettingsSaga() {
  yield takeLatest(
    actions.updatePlaybookSettings.type,
    doUpdatePlaybookSettings,
  );
  yield takeLatest(
    actions.fetchAvailablePlaybooks.type,
    doFetchAvailablePlaybooks,
  );
  yield takeLatest(actions.fetchActivePlaybook.type, doFetchActivePlaybook);
  yield takeLatest(actions.fetchPlaybookById.type, doFetchPlaybookById);
  yield takeLatest(
    actions.updatePlaybookTemplate.type,
    doUpdatePlaybookTemplate,
  );
}
