import React from 'react';
import { BarChart2, List } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button/Button';

interface EmptyPlaybookItemsStateParams {
  title: string;
}

export const EmptyPlaybookItemsState: React.FC<
  EmptyPlaybookItemsStateParams
> = ({ title }: EmptyPlaybookItemsStateParams) => {
  const navigate = useNavigate();

  const goToEditPlaybookTemplate = () => {
    navigate('/playbook');
  };

  return (
    <div className="flex justify-center flex-col items-center">
      <div className="mb-2">
        <BarChart2 size={48} color={'#2C6CF6'} />
      </div>
      <div className="flex flex-col items-center mb-6">
        <p className="text-xl font-bold">Feels a little empty over here...</p>
        <p className="text-[#758DB9] font-normal text-sm mt-2">
          Select at least one item from the {title} category to see insights
        </p>
      </div>
      <div className="flex flex-row justify-center">
        <div className="ml-1">
          <Button
            variant="primary"
            onClick={() => {
              goToEditPlaybookTemplate();
            }}
          >
            <List size={20} color="#FFFFFF" />
            Edit sales playbook
          </Button>
        </div>
      </div>
    </div>
  );
};
