export class RepPerformanceHelper {
  static setLineColor = (metricPercentage) => {
    switch (true) {
      case metricPercentage > 80:
        return '#0FB600';
      case metricPercentage < 50:
        return '#E74029';
      default:
        return '#F89B11';
    }
  };

  static setRepsInfo(reps) {
    const repsWithFullInfo = [];

    reps.forEach((rep) => {
      repsWithFullInfo.push({
        ...rep,
        lineColor: this.setLineColor(rep.playbookFulfilledPercentage),
      });
    });

    return repsWithFullInfo;
  }
}
