import React from 'react';
import { AlertCircle } from 'react-feather';

export const GeneralFailedState = () => {
  return (
    <div className="flex justify-center flex-col items-center gap-4">
      <div className="w-[72px] h-[72px] rounded-full bg-accent-red/10 flex justify-center items-center">
        <AlertCircle size={40} className="text-accent-red" />
      </div>
      <div className="flex flex-col items-center gap-2">
        <p className="text-xl font-bold text-dark">
          Sorry, we are unable to analyze this call
        </p>
        <p className="text-sm font-medium text-main-gray-dark">
          Please check your file and try to record or upload it again.
        </p>
      </div>
    </div>
  );
};
