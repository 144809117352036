import { Info } from 'react-feather';

import { Api } from '@/api/api';
import { Button } from '@/components/Button/Button';

interface ReanalyzeCallBannerProps {
  call: { id: string; isDemoCall: boolean };
}

export const ReanalyzeCallBanner: React.FC<ReanalyzeCallBannerProps> = ({
  call,
}) => {
  if (call.isDemoCall) return;

  const reanalyzeCall = async () => {
    const response = await Api.call.reanalyzeCall(call);
    // TODO: error handling
    // TODO: instead of reload have the SPA update its state after refactoring calls/call sagas
    window.location.reload();
  };

  return (
    <div className="flex justify-between items-center self-stretch px-4 py-3 mt-8 mb-2 rounded-lg-1.5 bg-accent-blue/10">
      <div className="flex items-center gap-2">
        <Info size={20} className="text-accent-blue" />
        <p className="text-dark text-sm font-normal">
          You can re-analyze this call based on your updated sales-playbook
        </p>
      </div>
      <Button variant="link" onClick={reanalyzeCall}>
        Update call analysis
      </Button>
    </div>
  );
};
