import React from 'react';

import { AddNewCalendarEvent } from '@/components/Recording/AddNewCalendarEvent';
import { AddSilkchartAssistantHeader } from '@/components/Recording/AddSilkchartAssistantHeader';
import { ConnectCalendarTooltip } from '@/components/Recording/ConnectCalendarTooltip';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { SyncingCalendarLoader } from '@/components/Recording/SyncingCalendarLoader';

interface AddSilkchartAssistantProps {
  onClose: () => void;
}

export const AddSilkchartAssistant: React.FC<AddSilkchartAssistantProps> = ({
  onClose,
}) => {
  const { isCalendarConnected, isLoading } = useRecordingCalendarStore();

  if (isLoading) {
    return <SyncingCalendarLoader />;
  }

  return (
    <div className="pt-6 w-[640px]">
      <AddSilkchartAssistantHeader containerClassName="px-6 pb-6" />
      <AddNewCalendarEvent onClose={onClose} containerClassName="p-6" />
      {!isCalendarConnected && <ConnectCalendarTooltip />}
    </div>
  );
};
