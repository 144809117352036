import { useState } from 'react';

import useInterval from './useInterval';

export const useInternetConnectionStatus = (
  params: { checkIntervalMs?: number } = { checkIntervalMs: 3000 },
) => {
  const [isOnline, setIsOnline] = useState(false);

  useInterval(() => {
    setIsOnline(navigator.onLine);
  }, params.checkIntervalMs);

  return {
    isOnline,
    isOffline: !isOnline,
  };
};
