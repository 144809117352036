import { API } from '../../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  googleAuthRedirect: (authCode: string) =>
    backendURL + `/api/google/auth/redirect?authCode=${authCode}`,
};

export const googleAuthRedirect = async (authCode: string) => {
  await API(endpoints.googleAuthRedirect(authCode), {
    method: 'GET',
  });
};
