import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  forceCallAnalyze: backendURL + '/api/calls/:callId/force-analyze',
};

export const forceCallAnalyze = async (callId: string) => {
  return await API(endpoints.forceCallAnalyze.replace(':callId', callId), {
    method: 'POST',
  });
};
