import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from '@/pages/HomePage/slice/selectors';

import { useLeadershipDashboardSlice } from '../../../pages/LeadershipDashboard/slice';
import {
  selectTopReps,
  selectWorstReps,
} from '../../../pages/LeadershipDashboard/slice/selectors';
import { selectTimeRangeFilter } from '../../../pages/LeadershipPage/slice/selectors';
import { mock } from './mock';
import RepsAttentionList from './RepsAttentionList';

const RepsAttentionBlock = () => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipDashboardSlice();

  const user = useSelector(selectUser);
  const useMock = user.email.includes('demo.com');

  const DEFAULT_PAGINATION_SIZE = 5;
  const [pagination, setPagination] = useState({
    iteration: 1,
    size: DEFAULT_PAGINATION_SIZE,
  });

  const topReps = useSelector(selectTopReps);
  const worstReps = useSelector(selectWorstReps);
  const currentTimeRangeFilter = useSelector(selectTimeRangeFilter);

  useEffect(() => {
    dispatch(
      actions.fetchRepsPerformance({
        timeFilter: currentTimeRangeFilter,
        page: 0,
        size: pagination.size,
      }),
    );
  }, [currentTimeRangeFilter, pagination]);

  const nextPage = () => {
    const newIteration = pagination.iteration + 1;
    const newSize = newIteration * DEFAULT_PAGINATION_SIZE;

    setPagination({ iteration: newIteration, size: newSize });
  };

  return (
    <div>
      <div>
        <span className="text-[#5C6A82] text-base font-semibold">
          Reps to pay attention to
        </span>
      </div>
      <div className="mt-4 flex flex-row">
        <RepsAttentionList
          header={'Bottom reps: Sales playbook completion'}
          dotColor={'#E74029'}
          marginRightNeeded={true}
          reps={useMock ? mock.worstReps : worstReps}
          nextPage={nextPage}
          paginationSize={pagination.size}
        />
        <RepsAttentionList
          header={'Top reps: Sales playbook completion'}
          dotColor={'#0FB600'}
          reps={useMock ? mock.topReps : topReps}
          nextPage={nextPage}
          paginationSize={pagination.size}
        />
      </div>
    </div>
  );
};

export default RepsAttentionBlock;
