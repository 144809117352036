import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.leadershipTeam || initialState;

export const selectTeamPerformance = createSelector(
  [selectSlice],
  (state) => state.teamPerformance,
);

export const selectActivePlaybookMetrics = createSelector(
  [selectSlice],
  (state) => state.activePlaybookMetrics,
);

export const selectTeamTableLoading = createSelector(
  [selectSlice],
  (state) => state.teamTableLoading,
);

export const selectTeamTablePageLoading = createSelector(
  [selectSlice],
  (state) => state.teamTablePageLoading,
);

export const selectShowTeamTableColumnOptions = createSelector(
  [selectSlice],
  (state) => state.showTeamTableColumnOptions,
);

export const selectTeamTableColumnsLoading = createSelector(
  [selectSlice],
  (state) => state.teamTableColumnsLoading,
);
