import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

class AmplitudeService {
  constructor() {
    if (!process.env.REACT_APP_AMPLITUDE_API_KEY) {
      console.warn('REACT_APP_AMPLITUDE_API_KEY env variable is not set');
      return;
    }

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1, // track 100% of users
    });

    amplitude.add(sessionReplayTracking).promise.then(() => {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
        defaultTracking: false,
      });
    });
  }

  trackEvent(eventName: string, params?: Record<string, unknown>) {
    if (!process.env.REACT_APP_AMPLITUDE_API_KEY) return;

    amplitude.track(eventName, params);

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(
        `%c tracked "${eventName}" ${params ? JSON.stringify(params, null, 2) : ''}`,
        'background: #222; color: yellow',
      );
    }
  }

  setUserId(email: string) {
    amplitude.setUserId(email);
  }

  reset() {
    amplitude.reset();
  }
}

export const amplitudeService = new AmplitudeService();
