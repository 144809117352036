import React from 'react';
import { Check } from 'react-feather';
import Select, { components } from 'react-select';

const LiveFeedbackSelectMenu = ({ currentMetric, changeCurrentMetric }) => {
  let options = [
    { value: 'speechPace', label: 'Speech Pace' },
    { value: 'talkRatio', label: 'Talk Ratio' },
    { value: 'longestCustomerMonologue', label: 'Monologue' },
    { value: 'questionsAsked', label: 'Questions' },
    { value: 'fillerWords', label: 'Filler Words' },
  ];

  return (
    <Select
      value={options.find((option) => option.value === currentMetric)}
      onChange={(e) => changeCurrentMetric(e.value)}
      isSearchable={false}
      menuShouldScrollIntoView={false}
      maxMenuHeight={window?.innerHeight > 500 ? 300 : 115}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
        Option,
      }}
      styles={{
        placeholder: (provided) => ({
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          color: '#FFF',
        }),
        valueContainer: (provided) => ({
          display: 'flex',
          height: '100%',
          alignItems: 'center',
        }),
        control: (provided) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: 'none',
          width: '145px',
          maxHeight: '32px',
          height: '32px',
          flexShrink: 0,
          borderRadius: 24,
          border: '0.5px solid rgba(255, 255, 255, 0.32)',
          background:
            'linear-gradient(271deg, rgba(255, 255, 255, 0.16) 0.49%, rgba(255, 255, 255, 0.05) 100%)',
          padding: '8px 10px 8px 14px',
          cursor: 'pointer',
        }),
        singleValue: (provided) => ({
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          color: '#FFF',
        }),
        input: (provided) => ({
          margin: '0px',
          padding: '0px',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#484A51',
          borderRadius: '8px',
          marginTop: 0,
          marginLeft: 0,
          background: '#323232',
          width: '270px',
          top: 'calc(100% + 4px)',
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
          borderRadius: '8px',
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          ':active': {
            ...styles[':active'],
            backgroundColor: 'transparent',
          },
          backgroundColor: isSelected ? '#ffffff14' : 'transparent',
          color: '#DFDEDF',
          fontSize: '13px',
          height: '32px',
          padding: 0,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
        }),
        indicatorSeparator: (state) => ({
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          height: '16px',
          width: '16px',
          padding: 0,
          margin: 0,
        }),
      }}
      options={options}
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <img
          width="16px"
          height="16px"
          src="/chevron-up-icon.svg"
          className="text-white"
        />
      ) : (
        <img
          width="16px"
          height="16px"
          src="/chevron-down-icon.svg"
          className="text-white"
        />
      )}
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center">
        <div className="w-[24px] h-[24px] ml-2">
          {props.isSelected && <Check size={24} />}
        </div>
        <label className="pl-2">{props.label}</label>
      </div>
    </components.Option>
  );
};

export default LiveFeedbackSelectMenu;
