const supportedMimeTypes = [
  'audio/aac',
  'audio/webm',
  'audio/webm;codecs=opus',
  'audio/ogg;codecs=opus',
  'audio/ogg;codecs=vorbis',
].filter(MediaRecorder.isTypeSupported);

export const createMediaRecorder = async () => {
  const mediaStream = await window.navigator.mediaDevices.getUserMedia({
    audio: {
      autoGainControl: true,
      echoCancellation: false,
    },
  });
  const mimeType = supportedMimeTypes[0];

  const mediaRecorder = new MediaRecorder(mediaStream, {
    mimeType,
    audioBitsPerSecond: 32000,
  });
  return mediaRecorder;
};
