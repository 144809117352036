import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  setActivePlaybook: backendURL + '/api/organization/playbook/template/active',
};

export const setActivePlaybook = async (activePlaybookId: string) => {
  const response = await API(endpoints.setActivePlaybook, {
    method: 'PUT',
    body: JSON.stringify({ activePlaybookId }),
  });
  return response;
};
