export class TeamPerformanceHelper {
  static defaultStylesForActivePlaybookMetricColumn = (metric) => ({
    header: {
      name: metric.shortName,
      width: '10.9%',
      minWidth: '167px',
    },
    cell: {
      width: '10.9%',
      minWidth: '167px',
      suffix: '%',
    },
  });

  static definedStylesForActivePlaybookMetricsColumns = {
    objection: {
      header: {
        name: 'Handles objections',
        width: '10.9%',
        minWidth: '167px',
      },
      cell: {
        width: '10.9%',
        minWidth: '167px',
        suffix: '%',
      },
    },
    introRapport: {
      header: {
        name: 'Intro Banter',
        width: '10.9%',
        minWidth: '167px',
        // tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipRight: '0',
      },
      cell: {
        width: '10.9%',
        minWidth: '167px',
        suffix: '%',
      },
    },
    introAgenda: {
      header: {
        name: 'Sets Agenda',
        width: '10.9%',
        minWidth: '125px',
        // tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipRight: '0',
      },
      cell: {
        width: '10.9%',
        minWidth: '125px',
        suffix: '%',
      },
    },
    demoPersonalizedOnNeed: {
      header: {
        name: 'Personalizes demo',
        width: '10.9%',
        minWidth: '163px',
        // tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipRight: '0',
      },
      cell: {
        width: '10.9%',
        minWidth: '163px',
        suffix: '%',
      },
    },
    demoStory: {
      header: {
        name: 'Demo tells a story',
        width: '10.9%',
        minWidth: '167px',
        // tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipRight: '0',
      },
      cell: {
        width: '10.9%',
        minWidth: '167px',
        suffix: '%',
      },
    },
    endingNextSteps: {
      header: {
        name: 'Aligns on Next Steps',
        width: '10.9%',
        minWidth: '172px',
        // tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipRight: '0',
      },
      cell: {
        width: '10.9%',
        minWidth: '172px',
        suffix: '%',
      },
    },
    endingScheduleFollowup: {
      header: {
        name: 'Books next meeting',
        width: '10.9%',
        minWidth: '195px',
        // tooltip: 'Share of all calls in which 75%+ of playbook was executed',
        tooltipWidth: '408px',
        tooltipRight: '0',
      },
      cell: {
        width: '10.9%',
        minWidth: '195px',
        suffix: '%',
      },
    },
  };

  static playbookMetricEvaluation = (metricResult) => {
    switch (true) {
      case metricResult >= 80:
        return this.good;
      case metricResult < 50:
        return this.bad;
      default:
        return this.okay;
    }
  };

  static columnIdToSortBy = {
    fullName: 'fullName',
    salesPlaybookRating: 'playbookFulfilledPercentage',
    speechPace: 'averageSpeechPace',
    sellerSpeaking: 'averageTalkPercentage',
    longestMonologue: 'averageLongestMonologue',
    questionsAsked: 'averageQuestionsAsked',
    fillerWords: 'averageFillerWords',
  };

  static good = {
    textColor: '#0FB600',
    bgColor: '#0FB6001a',
    status: 'good',
  };

  static bad = {
    textColor: '#E74029',
    bgColor: '#E740291a',
    status: 'bad',
  };

  static okay = {
    textColor: '#1C1C1C',
    bgColor: '#FFF',
    status: 'okay',
  };

  static defaultCalculationFunction = () => this.okay;

  // Here you can add custom value evaluation styles for each metric, if no mapper for metric here then default styles will be used
  static rangesByMemberMetric = {
    bantBudget: this.playbookMetricEvaluation,
    bantAuthority: this.playbookMetricEvaluation,
    bantTimeline: this.playbookMetricEvaluation,
    bantNeed: this.playbookMetricEvaluation,
    playbookFulfilledPercentage: this.playbookMetricEvaluation,
    introRapport: this.playbookMetricEvaluation,
    demoPersonalizedOnNeed: this.playbookMetricEvaluation,
    demoStory: this.playbookMetricEvaluation,
    endingScheduleFollowup: this.playbookMetricEvaluation,
    endingNextSteps: this.playbookMetricEvaluation,
    introAgenda: this.playbookMetricEvaluation,
    objection: this.playbookMetricEvaluation,
    averageSpeechPace: (metricResult) => {
      switch (true) {
        case metricResult >= 110 && metricResult <= 170:
          return this.good;
        case metricResult > 200:
          return this.bad;
        default:
          return this.okay;
      }
    },
    averageTalkPercentage: (metricResult) => {
      switch (true) {
        case metricResult < 40:
          return this.good;
        case metricResult > 60:
          return this.bad;
        default:
          return this.okay;
      }
    },
    averageLongestMonologue: (metricResult) => {
      switch (true) {
        case metricResult > 60:
          return this.good;
        case metricResult < 30:
          return this.bad;
        default:
          return this.okay;
      }
    },
    averageQuestionsAsked: (metricResult) => {
      switch (true) {
        case metricResult >= 18:
          return this.good;
        case metricResult < 12:
          return this.bad;
        default:
          return this.okay;
      }
    },
    averageFillerWords: (metricResult) => {
      switch (true) {
        case metricResult < 2:
          return this.good;
        case metricResult > 20:
          return this.bad;
        default:
          return this.okay;
      }
    },
  };

  static setMembersMetricsInfo(teamPerformance, activePlaybookMetrics) {
    const membersWithInfo = [];

    teamPerformance.forEach((memberPerformance) => {
      const updatedMember = JSON.parse(JSON.stringify(memberPerformance));

      Object.keys(memberPerformance).forEach((key) => {
        const metricValue = memberPerformance[key];
        const playbookMetric = activePlaybookMetrics
          .map((el) => el.key)
          .includes(key);

        let calculateStatusFunction = this.rangesByMemberMetric[key];

        // set default evaluation styles only for columns that are related to playbook metric keys
        if (!calculateStatusFunction && !playbookMetric) return;
        if (!calculateStatusFunction)
          calculateStatusFunction = this.defaultCalculationFunction;

        const status = calculateStatusFunction(metricValue);

        updatedMember[key] = {
          key,
          ...status,
          value: metricValue,
        };
      });

      membersWithInfo.push(updatedMember);
    });

    return membersWithInfo;
  }
}
