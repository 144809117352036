import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.callDetails || initialState;

export const selectLoading = createSelector(
  [selectSlice],
  (state) => state.loading,
);

export const selectCallQualitativeAnalysisLoading = createSelector(
  [selectSlice],
  (state) => state.callQualitativeAnalysisLoading,
);

export const selectCallQualitativeAnalysis = createSelector(
  [selectSlice],
  (state) => state.callQualitativeAnalysis,
);

export const selectCall = createSelector([selectSlice], (state) => state.call);

export const selectFetchCallFailed = createSelector(
  [selectSlice],
  (state) => state.fetchCallFailed,
);

export const selectChangeSpeakerModalOpen = createSelector(
  [selectSlice],
  (state) => state.changeSpeakerModalOpen,
);
