import React from 'react';

import { Button } from '@/components/Button/Button';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';

export const CalendarSuccessfullyConnected: React.FC = () => {
  const { setShowCalendarConnectedScreen } = useRecordingCalendarStore();

  return (
    <div className="flex flex-col gap-4 px-8 py-6">
      <div className="flex flex-col items-center justify-center gap-2">
        <span className="text-dark text-xl font-bold">
          Calendar successfully connected!
        </span>
        <span className="text-main-gray-dark text-sm font-medium tracking-[-0.14px]">
          If you have upcoming meetings, you will see them when you click on the
          "Record" button
        </span>
      </div>
      <div className="flex items-center justify-center bg-accent-blue/10 rounded-lg px-[115px]">
        <img
          className="h-[162px] w-[397px] my-6"
          src="/calendar-connected-photo.png"
        ></img>
      </div>
      <div className="flex items-center justify-center">
        <Button
          className="px-3 py-2.5 flex items-center justify-center min-w-[120px]"
          variant="primary"
          onClick={() => setShowCalendarConnectedScreen(false)}
        >
          <span className="text-white text-[13px] font-semibold">Done</span>
        </Button>
      </div>
    </div>
  );
};
