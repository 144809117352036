import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createNewCall as createNewCallAPI } from '@/api/call/createNewCall';

export const useCreateNewCall = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createNewCall, ...rest } = useMutation({
    mutationFn: createNewCallAPI,
    onSuccess: () => {
      // Invalidate and refetch calls query
      queryClient.invalidateQueries({ queryKey: ['calls'] });
    },
  });

  return { createNewCall, ...rest };
};
