import React from 'react';
import { Calendar, Check, ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';

import { useLeadershipDashboardSlice } from '../../pages/LeadershipDashboard/slice';
import { useLeadershipSlice } from '../../pages/LeadershipPage/slice';
import { selectTimeRangeFilter } from '../../pages/LeadershipPage/slice/selectors';
import { useLeadershipTeamSlice } from '../../pages/LeadershipTeam/slice';
let options = [
  {
    label: 'Last week',
    value: 'week',
    icon: <Calendar size={20} />,
  },
  {
    label: 'Last 2 weeks',
    value: 'twoWeeks',
    icon: <Calendar size={20} />,
  },
  {
    label: 'Last month',
    value: 'month',
    icon: <Calendar size={20} />,
  },
  {
    label: 'Last 3 months',
    value: 'threeMonths',
    icon: <Calendar size={20} />,
  },
  // {
  //   label: "Custom Period",
  //   value: "customPeriod",
  //   icon: <Calendar size={20} />
  // },
];
const TimeRangeOptions = () => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipSlice();
  const { actions: leadershipDashboardActions } = useLeadershipDashboardSlice();
  const { actions: leadershipTeamActions } = useLeadershipTeamSlice();
  const currentTimeRangeFilter = useSelector(selectTimeRangeFilter);
  const handleChange = (selectedOption) => {
    const possibleOptions = options.map((el) => el.value);
    if (possibleOptions.includes(selectedOption.value)) {
      dispatch(actions.setTimeRangeFilter(selectedOption.value));
      dispatch(leadershipDashboardActions.setDashboardPageLoading(true));
      dispatch(leadershipTeamActions.setTeamTablePageLoading(true));
    }
  };
  return (
    <div>
      <Select
        name="options"
        options={options}
        value={options.find(
          (option) => option.value === currentTimeRangeFilter,
        )}
        onChange={handleChange}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          SingleValue,
          DropdownIndicator,
        }}
        styles={{
          control: (provided, state) => ({
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid var(--main-gray-main, #ECECEC)',
            borderRadius: 10,
            boxShadow: 'none',
            padding: '12px 10px 10px 10px',
            backgroundColor: '#F6F6F6',
            cursor: 'pointer',
          }),
          indicatorsContainer: (styles) => ({
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            marginLeft: 8,
          }),
          dropdownIndicator: (provided, state) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1C1C1C',
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              fontWeight: 500,
              backgroundColor: '#fff',
              color: '#1C1C1C',
              fontSize: 14,
              padding: 8,
              ':active': {
                backgroundColor: '#fff',
              },
            };
          },
          menu: (styles, state) => ({
            ...styles,
            width: '100%',
            minWidth: '171px',
            boxShadow:
              '0px 2px 6px -2px rgba(24, 39, 75, 0.06), 0px 1px 6px -2px rgba(24, 39, 75, 0.10)',
            borderRadius: 10, // bottom left and bottom right radius 10
            padding: '8px 12px 8px 12px',
            gap: 4,
            marginTop: 2,
            right: 0,
            backgroundColor: '#FFF',
          }),
          menuList: (styles) => ({
            ...styles,
            padding: 0,
          }),
          singleValue: (styles) => ({
            ...styles,
            color: '#1C1C1C',
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: 0,
            input: { height: 0 },
          }),
          transition: 'none',
        }}
      />
    </div>
  );
};
const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center w-full h-full">
        {props.isSelected === true ? (
          <div className="flex flex-row items-center">
            <Check
              className="mr-2"
              size={16}
              stroke={'#1C1C1C'}
              strokeWidth={2}
            />
            <div className="flex-1">
              <div className="text-sm">{props.label}</div>
            </div>
          </div>
        ) : (
          <div className="flex-1 ml-6">
            <div className="text-sm">{props.label}</div>
          </div>
        )}
      </div>
    </components.Option>
  );
};
const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center w-full h-full">
        <div className="h-[20px] w-[20px]">{props.data.icon}</div>
        <div className="flex-1 ml-1">
          <div className="text-sm">{props.data.label}</div>
        </div>
      </div>
    </components.SingleValue>
  );
};

const DropdownIndicator = (props) => {
  return (
    <div className="h-[16px] w-[16px]">
      <ChevronDown
        size={16}
        className={`transition ease-in-out ${props.selectProps.menuIsOpen ? 'rotate-180' : ''}`}
      />
    </div>
  );
};

export default TimeRangeOptions;
