import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { selectLoggedIn } from '../HomePage/slice/selectors';
import { CheckInboxStep } from './steps/CheckInboxStep';
import { EmailInputStep } from './steps/EmailInputStep';
import { SetPasswordStep } from './steps/SetPasswordStep';
import { SuccessStep } from './steps/SuccessStep';

enum ResetPasswordStep {
  EmailInput,
  CheckInbox,
  SetPassword,
  Success,
}

export const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loggedIn = useSelector(selectLoggedIn);
  const [searchParams] = useSearchParams();

  const resetPasswordToken = searchParams.get('t');

  const getStep = () => {
    if (password) {
      return ResetPasswordStep.Success;
    }

    if (resetPasswordToken) {
      return ResetPasswordStep.SetPassword;
    }

    if (email) {
      return ResetPasswordStep.CheckInbox;
    }

    return ResetPasswordStep.EmailInput;
  };

  const step = getStep();

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn, navigate]);

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full h-[72px] bg-white flex items-center px-6 justify-between border-b border-main-gray">
        <Link to="/auth/login">
          <img src="/logo.svg" alt="silkchart-logo" />
        </Link>
      </div>
      <div className="flex flex-col items-center bg-white py-[80px]">
        {step === ResetPasswordStep.EmailInput && (
          <EmailInputStep
            initialEmail={locationState?.email || ''}
            onEmailAccept={setEmail}
          />
        )}
        {step === ResetPasswordStep.CheckInbox && (
          <CheckInboxStep email={email} />
        )}
        {step === ResetPasswordStep.SetPassword && (
          <SetPasswordStep
            resetPasswordToken={resetPasswordToken || ''}
            onPasswordChange={setPassword}
          />
        )}
        {step === ResetPasswordStep.Success && <SuccessStep />}
      </div>
    </div>
  );
};
