export enum DetailedFillerWordsMetrics {
  FillerWordsUmCount = 'fillerWordsUmCount',
  FillerWordsAhCount = 'fillerWordsAhCount',
  FillerWordsUhCount = 'fillerWordsUhCount',
  FillerWordsLikeCount = 'fillerWordsLikeCount',
}

export enum PossibleMetrics {
  MainSpeakerWordsPerMinute = 'mainSpeakerWordsPerMinute',
  MainSpeakerTalkPercentage = 'mainSpeakerTalkPercentage',
  LongestMonologueDurationSeconds = 'longestMonologueDurationSeconds',
  QuestionsAsked = 'questionsAsked',
  FillerWordsCount = 'fillerWordsCount',
}

export enum MetricsEvaluationColors {
  Green = '#0FB600',
  Yellow = '#F89B11',
  Red = '#E74029',
  Grey = '#5C6A82',
}

export type MetricColorMapperFunc = (value: number) => MetricsEvaluationColors;

export type MapColorByMetricKey = {
  [key in PossibleMetrics]: MetricColorMapperFunc;
};

export class BasicMetricsEvaluationHelper {
  static mapColorByMetricKey: MapColorByMetricKey = {
    [PossibleMetrics.MainSpeakerWordsPerMinute]: (value) => {
      switch (true) {
        case value >= 110 && value <= 170:
          return MetricsEvaluationColors.Green;
        case value > 200:
          return MetricsEvaluationColors.Red;
        default:
          return MetricsEvaluationColors.Yellow;
      }
    },
    [PossibleMetrics.MainSpeakerTalkPercentage]: (
      value: number,
    ): MetricsEvaluationColors => {
      switch (true) {
        case value < 40:
          return MetricsEvaluationColors.Green;
        case value > 60:
          return MetricsEvaluationColors.Red;
        default:
          return MetricsEvaluationColors.Yellow;
      }
    },
    [PossibleMetrics.LongestMonologueDurationSeconds]: (
      value: number,
    ): MetricsEvaluationColors => {
      switch (true) {
        case value > 60:
          return MetricsEvaluationColors.Green;
        case value < 30:
          return MetricsEvaluationColors.Red;
        default:
          return MetricsEvaluationColors.Yellow;
      }
    },
    [PossibleMetrics.QuestionsAsked]: (
      value: number,
    ): MetricsEvaluationColors => {
      switch (true) {
        case value >= 18:
          return MetricsEvaluationColors.Green;
        case value < 12:
          return MetricsEvaluationColors.Red;
        default:
          return MetricsEvaluationColors.Yellow;
      }
    },
    [PossibleMetrics.FillerWordsCount]: (
      value: number,
    ): MetricsEvaluationColors => {
      switch (true) {
        case value < 2:
          return MetricsEvaluationColors.Green;
        case value > 20:
          return MetricsEvaluationColors.Red;
        default:
          return MetricsEvaluationColors.Yellow;
      }
    },
  };
}
