import { IPlaybook } from '../../interfaces/playbook.interface';
import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  getAvailablePlaybooks: backendURL + '/api/organization/playbook/template',
};

export const getAvailablePlaybooks = async (): Promise<IPlaybook[]> => {
  const response = await API(endpoints.getAvailablePlaybooks, {
    method: 'GET',
  });
  const body = await response.json();
  return body as IPlaybook[];
};
