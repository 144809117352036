const backendURL = process.env.REACT_APP_BACKEND_URL;

export const sendHelpRequest = async (params: {
  topic: string;
  details: string;
  files: File[];
}): Promise<void> => {
  const formData = new FormData();
  formData.append('topic', params.topic);
  formData.append('details', params.details);
  params.files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await fetch(backendURL + '/api/help', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `jwt ${localStorage.getItem('authToken')}`,
    },
    body: formData,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  }

  return data;
};
