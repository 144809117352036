import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { playbookSettingsSaga } from './saga';

export const initialState = {
  loading: false,
  message: '',
  defaultPlaybook: null,
  availablePlaybooks: [],
  selectedPlaybook: null,
  playbookTemplate: {
    saving: false,
    saveSuccess: null,
    message: '',
  },
  userSettings: {
    saving: false,
    saveSuccess: null,
    message: '',
  },
};

export const playbookSettingsSlice = createSlice({
  name: 'playbookSettings',
  initialState,
  reducers: {
    updatePlaybookTemplate(state, _action) {
      state.playbookTemplate = {
        saving: true,
        saveSuccess: false,
        message: '',
      };
    },
    updatePlaybookTemplateSuccess(state, _action) {
      state.playbookTemplate = {
        saving: false,
        saveSuccess: true,
        message: '',
      };
    },
    updatePlaybookTemplateFailed(state, action) {
      state.playbookTemplate = {
        saving: false,
        saveSuccess: false,
        message: action.payload,
      };
    },
    fetchPlaybookById(state, _action) {
      state.loading = true;
    },
    fetchPlaybookByIdSuccess(state, action) {
      state.loading = false;
      state.message = '';
      state.selectedPlaybook = action.payload;
    },
    fetchPlaybookByIdFailed(state, action) {
      state.loading = false;
      state.message = action.payload;
    },
    fetchActivePlaybook(state, _action) {
      state.loading = true;
    },
    fetchActivePlaybookSuccess(state, action) {
      state.loading = false;
      state.selectedPlaybook = {
        id: action.payload.activePlaybook.playbook.id,
        name: action.payload.activePlaybook.playbook.name,
        playbookItems: action.payload.activePlaybook.items,
      };
      state.defaultPlaybook = {
        id: action.payload.defaultPlaybook.playbook.id,
        name: action.payload.defaultPlaybook.playbook.name,
        playbookItems: action.payload.defaultPlaybook.items,
      };
    },
    fetchActivePlaybookFailed(state, action) {
      state.loading = false;
      state.message = action.payload;
    },
    fetchAvailablePlaybooks(state, _action) {
      state.loading = true;
    },
    fetchAvailablePlaybooksSuccess(state, action) {
      state.loading = false;
      state.message = '';
      state.availablePlaybooks = action.payload.availablePlaybooks;
    },
    fetchAvailablePlaybooksFailed(state, action) {
      state.loading = false;
      state.message = action.payload;
    },
    toggleEnabled(state, action) {
      const playbookItemObject = state.selectedPlaybook.playbookItems.find(
        (item) => item.id === action.payload.id,
      );
      playbookItemObject.enabled = action.payload.enabled;
    },
    updatePlaybookSettings(state, _action) {
      state.userSettings = {
        saving: true,
        saveSuccess: false,
        message: '',
      };
    },
    updatePlaybookSettingsSuccess(state, _action) {
      state.userSettings = {
        saving: false,
        saveSuccess: true,
        message: '',
      };
    },
    updatePlaybookSettingsFailed(state, action) {
      state.userSettings = {
        saving: false,
        saveSuccess: false,
        message: action.payload,
      };
    },
    resetSaveSuccess(state, _action) {
      state.playbookTemplate.saveSuccess = null;
      state.userSettings.saveSuccess = null;
    },
    resetActivePlaybook(state, _action) {
      state.defaultPlaybook = null;
    },
    resetSelectedPlaybook(state, _action) {
      state.selectedPlaybook = null;
    },
  },
});

export const { actions: playbookSettingsActions } = playbookSettingsSlice;

export const usePlaybookSettingsSlice = () => {
  useInjectReducer({
    key: playbookSettingsSlice.name,
    reducer: playbookSettingsSlice.reducer,
  });
  useInjectSaga({
    key: playbookSettingsSlice.name,
    saga: playbookSettingsSaga,
  });
  return { actions: playbookSettingsSlice.actions };
};
