import { useMutation } from '@tanstack/react-query';

import { eventTracker } from '@/amplitude/eventTracker';
import { sendPasswordResetEmail as sendPasswordResetEmailAPICall } from '@/api/auth/reset-password/sendPasswordResetEmail';

export const useSendPasswordResetEmail = () => {
  const { mutateAsync: sendPasswordResetEmail, ...rest } = useMutation({
    mutationFn: (email: string) => {
      eventTracker.auth.passwordResetEmailSent({ email });
      return sendPasswordResetEmailAPICall({ email });
    },
  });

  return { sendPasswordResetEmail, ...rest };
};
