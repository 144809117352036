import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ChartValueBlock } from '@/components/InsightsAcrossCallsPage/chart/ChartValueBlock';
import { TabScroll } from '@/components/TabScroll/TabScroll';

interface InsightStatisticItem {
  displayValue: string;
  valueColor: string;
  title: string;
  description?: string;
  selected: boolean;
  valueExplanationText?: string;
  emptyState?: boolean;
}

interface InsightsMetricsProps {
  isLoading?: boolean;
  isFirstLoading: boolean;
  setSelectedChartItem?: any;
  chartValueItems: InsightStatisticItem[];
  beforeDataLoadItems: InsightStatisticItem[];
}

const MIN_CARD_WIDTH_IN_PIXELS = 220;

export const InsightsStatistics: React.FC<InsightsMetricsProps> = ({
  chartValueItems,
  beforeDataLoadItems,
  setSelectedChartItem,
  isLoading,
  isFirstLoading,
}: InsightsMetricsProps) => {
  const [needsScroll, setNeedsScroll] = useState(false);
  const [stepSize, setStepSize] = useState(100);
  const [selectedBlockOrder, setSelectedBlockOrder] = useState(1);
  const [displayItems, setDisplayItems] = useState<InsightStatisticItem[]>([]);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [maxItemsInView, setMaxItemsInView] = useState(4);

  const updateMaxItemsInView = useCallback(() => {
    if (!scrollRef.current) return;
    const containerWidth = scrollRef.current.offsetWidth;
    const maxItems = Math.floor(containerWidth / MIN_CARD_WIDTH_IN_PIXELS);
    setMaxItemsInView(maxItems);
    setNeedsScroll(displayItems.length > maxItems);
  }, [displayItems]);

  useEffect(() => {
    if (isFirstLoading || !chartValueItems?.length) {
      setDisplayItems(beforeDataLoadItems);
    } else {
      setDisplayItems(chartValueItems);
    }
  }, [chartValueItems, isLoading]);

  useEffect(() => {
    updateMaxItemsInView();
  }, [updateMaxItemsInView]);

  useEffect(() => {
    if (!needsScroll || !scrollRef.current) return;

    setStepSize(scrollRef.current.offsetWidth / maxItemsInView);
  }, [maxItemsInView, needsScroll]);

  useEffect(() => {
    const handleResize = () => {
      updateMaxItemsInView();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateMaxItemsInView]);

  const onChartValueBlockClick = (order: number) => {
    setSelectedBlockOrder(order);
    if (setSelectedChartItem) setSelectedChartItem(chartValueItems[order - 1]);
  };

  const scrollStep = (step: number) => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollLeft += step;
  };

  const scrollLeft = () => scrollStep(-stepSize);
  const scrollRight = () => scrollStep(stepSize);

  return (
    <div className="flex relative">
      {needsScroll && (
        <TabScroll
          className="absolute left-1 top-1/2 -translate-y-1/2 z-40"
          direction="left"
          onClick={scrollLeft}
        />
      )}
      <div
        ref={scrollRef}
        style={{ scrollbarWidth: 'none' }}
        className="w-full flex overflow-x-scroll scroll-smooth snap-x border border-main-gray rounded-t-[20px]"
      >
        {displayItems.map(
          (
            {
              displayValue,
              valueColor,
              description,
              valueExplanationText,
              title,
              emptyState = false,
            },
            index,
          ) => (
            <ChartValueBlock
              hoverDescription={description}
              clickHandler={onChartValueBlockClick}
              key={index}
              order={index + 1}
              items={displayItems.length}
              displayValue={displayValue}
              emptyState={emptyState}
              isLoading={isLoading}
              valueColor={valueColor}
              selected={selectedBlockOrder === index + 1}
              title={title}
              valueExplanationText={valueExplanationText}
              maxItemsInView={maxItemsInView}
            ></ChartValueBlock>
          ),
        )}
      </div>
      {needsScroll && (
        <TabScroll
          className="absolute right-1 top-1/2 -translate-y-1/2 z-40"
          direction="right"
          onClick={scrollRight}
        />
      )}
    </div>
  );
};
