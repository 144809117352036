export const statuses = Object.freeze({
  default: {
    icon: 'grey-circle-active.svg',
    bgColor: '#979797',
  },
  bad: {
    icon: 'red-circle-active.svg',
    bgColor: '#FF1717',
  },
  okay: {
    icon: 'yellow-circle-active.svg',
    bgColor: '#FFB800',
  },
  good: {
    icon: 'green-circle-active.svg',
    bgColor: '#00E8A0',
  },
});

export const metrics = Object.freeze({
  speechPace: {
    bad: {
      text: 'Slow down',
    },
    okay: {
      text: 'A bit faster',
    },
    good: {
      text: "You're doing great!",
    },
  },
  talkRatio: {
    bad: {
      text: 'Listen more',
    },
    okay: {
      text: 'Try listening more',
    },
    good: {
      text: "You're doing great!",
    },
  },
  longestCustomerMonologue: {
    bad: {
      text: 'Ask more open questions',
    },
    okay: {
      text: 'Ask more open questions',
    },
    good: {
      text: "You're doing great!",
    },
  },
  questionsAsked: {
    bad: {
      text: 'Ask more questions',
    },
    okay: {
      text: 'Ask more questions',
    },
    good: {
      text: "You're doing great!",
    },
  },
  fillerWords: {
    bad: {
      text: 'Try using less jargon',
    },
    okay: {
      text: 'Try using less jargon',
    },
    good: {
      text: "You're doing great!",
    },
  },
});
