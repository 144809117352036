import { Tab } from '@headlessui/react';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { DemoCallTranscript } from '@/components/CallDetailsPage/DemoCall/DemoCallTranscript';
import { DemoQuestions } from '@/components/CallDetailsPage/DemoCall/DemoQuestions';
import Feedback from '@/components/CallDetailsPage/Feedback';
import Playbook from '@/components/CallDetailsPage/Playbook';
import Transcript from '@/components/CallDetailsPage/Transcript';
import cn from '@/utils/cn';

import { OnboardingTooltip } from '../Onboarding/OnboardingTooltip/OnboardingTooltip';
import { OnboardingTooltipId } from '../Onboarding/OnboardingTooltip/useOnboardingTooltip';
import { Questions } from './Questions';

interface DoneStateProps {
  call: any;
}

export const PLAYBOOK_TAB_KEYS = {
  playbook: 'playbook',
  feedback: 'feedback',
  transcript: 'transcript',
  questions: 'questions',
};

export const DoneState: React.FC<DoneStateProps> = ({ call }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const showQuestionsTab =
    dayjs(call?.createdAt).isAfter(dayjs('2024-03-17')) ||
    Number(call?.topQuestions?.topQuestionsCount) > 0 ||
    call.isDemoCall;

  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = useMemo(() => {
    const baseTabs: Array<{
      key: string;
      panel: React.ReactNode;
      tabText: string;
      loadEvent: (data: { callId: string }) => void;
      onboardingTooltipId: OnboardingTooltipId | undefined;
      onboardingTooltipAction?: () => void;
    }> = [
      {
        key: PLAYBOOK_TAB_KEYS.playbook,
        panel: <Playbook call={call} />,
        tabText: 'Sales Playbook',
        loadEvent: eventTracker.calls.playbookTabClick,
        onboardingTooltipId: 'playbook',
        onboardingTooltipAction: () => setTabIndex((i) => i + 1),
      },
      {
        key: PLAYBOOK_TAB_KEYS.feedback,
        panel: <Feedback call={call} />,
        tabText: 'Coaching Feedback',
        loadEvent: eventTracker.calls.feedbackTabClick,
        onboardingTooltipId: 'feedback',
        onboardingTooltipAction: () => setTabIndex((i) => i + 1),
      },
      {
        key: PLAYBOOK_TAB_KEYS.transcript,
        panel: call.isDemoCall ? (
          <DemoCallTranscript call={call} />
        ) : (
          <Transcript call={call} />
        ),
        tabText: 'Transcript',
        loadEvent: eventTracker.calls.transcriptTabClick,
        onboardingTooltipId: undefined,
      },
    ];

    if (showQuestionsTab) {
      baseTabs.splice(2, 0, {
        key: PLAYBOOK_TAB_KEYS.questions,
        panel: call.isDemoCall ? (
          <DemoQuestions callId={call.id} />
        ) : (
          <Questions callId={call.id} />
        ),
        tabText: 'Objection Handling',
        loadEvent: eventTracker.calls.questionsTabClick,
        onboardingTooltipId: 'questions',
        onboardingTooltipAction: () => {
          navigate('/insights');
        },
      });
    }

    return baseTabs;
  }, [call, showQuestionsTab]);

  const tabSearchParam = searchParams.get('tab');

  const setTabFromSearchParams = () => {
    const tabIndex = tabsData.findIndex((tab) => tab.key === tabSearchParam);
    if (tabIndex === -1) return;

    setTabIndex(tabIndex);
  };

  useEffect(() => {
    setTabFromSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSearchParam]);

  const trackTabOpenEvent = (tabIndex: number) => {
    const tab = tabsData[tabIndex];
    tab.loadEvent({ callId: call.id });
  };

  const handleOnChange = (index: number) => {
    trackTabOpenEvent(index);
    setTabIndex(index);

    const tab = tabsData[index];
    if (!tab) return;
    setSearchParams(`tab=${tab.key}`);
  };

  return (
    <Tab.Group onChange={handleOnChange} selectedIndex={tabIndex}>
      <Tab.List className="flex gap-6 items-center border-b border-b-[#CBD2E0]">
        {tabsData.map(
          ({ key, tabText, onboardingTooltipId, onboardingTooltipAction }) => (
            <div key={key}>
              <Tab
                className={({ selected }) =>
                  cn(
                    'flex flex-col items-center focus:ring-0 focus:outline-none',
                    selected &&
                      "after:content-[''] after:inline-block after:bg-accent-blue after:h-1 after:-mt-1 after:w-full after:rounded-full",
                  )
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={cn(
                        'text-sm py-[12px]',
                        selected
                          ? 'text-dark font-semibold'
                          : 'text-main-gray-dark font-medium',
                      )}
                    >
                      {tabText}
                    </span>
                  </>
                )}
              </Tab>
              <OnboardingTooltip
                tooltipId={onboardingTooltipId}
                placement="bottom-start"
                onActionButtonClick={onboardingTooltipAction}
              />
            </div>
          ),
        )}
      </Tab.List>
      <Tab.Panels>
        {tabsData.map(({ key, panel }) => (
          <Tab.Panel key={key} unmount={false}>
            {panel}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
