export const mock = {
  averageAhWord: '0',
  averageFillerWords: '21',
  averageLikeWord: '24',
  averageLongestMonologue: '183',
  averageQuestionsAsked: '18',
  averageSpeechPace: '120',
  averageTalkPercentage: '30',
  averageUhWord: '21',
  averageUmWord: '47',
  playbookFulfilledPercentage: '87',
};
