import { PlayIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { ArrowRight, Check } from 'react-feather';

import {
  Tooltip,
  TooltipContent,
  TooltipProps,
  TooltipTrigger,
} from '@/components/Tooltip/Tooltip';
import cn from '@/utils/cn';

import {
  OnboardingTooltipId,
  onboardingTooltipsData,
  useOnboardingTooltip,
} from './useOnboardingTooltip';

interface OnboardingTooltipProps
  extends Omit<TooltipProps, 'children' | 'placement'> {
  tooltipId: OnboardingTooltipId | undefined;
  onActionButtonClick?: () => void;
  placement?: 'bottom-start' | 'bottom-end' | 'right-start';
}

export const OnboardingTooltip: React.FC<OnboardingTooltipProps> = ({
  tooltipId,
  onActionButtonClick,
  placement = 'bottom-start',
  ...restProps
}) => {
  const {
    isOnboardingStarted,
    activeTooltipIndex,
    activeTooltipData,
    next,
    isLastTooltip,
  } = useOnboardingTooltip();

  if (
    !isOnboardingStarted ||
    !activeTooltipData ||
    !tooltipId ||
    activeTooltipData.id !== tooltipId
  ) {
    return null;
  }

  return (
    <Tooltip open={true} padding={16} placement={placement} {...restProps}>
      <TooltipTrigger asChild>
        <div className="absolute"></div>
      </TooltipTrigger>
      <TooltipContent
        className={cn(
          'bg-dark p-4 text-white text-start transition-all z-10',
          placement === 'right-start' && '-mt-2',
        )}
      >
        <PlayIcon
          width={32}
          height={32}
          className={cn(
            'absolute text-dark',
            placement === 'right-start' && 'rotate-180 -left-4 top-2',
            placement === 'bottom-start' && '-rotate-90 left-2 -top-4',
            placement === 'bottom-end' && '-rotate-90 right-2 -top-4',
          )}
        />

        <div className="font-semibold text-base mb-1">
          {activeTooltipData.title}
        </div>
        <div className="mb-4 text-sm">{activeTooltipData.description}</div>
        <div className="flex justify-between items-center text-sm">
          <span className="text-main-gray-2">
            {activeTooltipIndex + 1}/{onboardingTooltipsData.length}
          </span>
          <button
            className="px-2.5 py-1.5 text-black bg-white rounded-[10px] flex justify-center items-center gap-1"
            onClick={() => {
              next();
              onActionButtonClick?.();
            }}
          >
            {isLastTooltip ? (
              <>
                <Check size={16} /> Done
              </>
            ) : (
              <>
                Next <ArrowRight size={16} />
              </>
            )}
          </button>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
