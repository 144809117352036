import { useNavigate } from 'react-router-dom';

import { PLAYBOOK_TAB_KEYS } from '@/components/CallDetailsPage/DoneState';

export const useNavigateToTranscript = () => {
  const navigate = useNavigate();

  const navigateToTranscript = (params: {
    callId: string;
    timestamp: string | number;
    text: string;
  }) => {
    navigate(
      `/calls/${params.callId}?tab=${PLAYBOOK_TAB_KEYS.transcript}&timestamp=${params.timestamp}&text=${params.text}`,
    );
  };

  return { navigateToTranscript };
};
