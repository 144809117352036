import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.signUp || initialState;

export const selectLoading = createSelector(
  [selectSlice],
  (state) => state.selectLoading,
);

export const selectEmailNotUsed = createSelector(
  [selectSlice],
  (state) => state.emailNotUsed,
);

export const selectVerifiedEmail = createSelector(
  [selectSlice],
  (state) => state.verifiedEmail,
);

export const selectUserExitsError = createSelector(
  [selectSlice],
  (state) => state.error,
);
