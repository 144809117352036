import { create } from 'zustand';

import { CallStatus } from '@/enums/callStatus.enum';

interface BotRecordingState {
  callId: string | null;
  isBotRecording: boolean;
  isGeneralBotFlowActive: boolean;
  inStopRecordingProcess: boolean;
  showStopRecordingModal: boolean;
  recordingStartedAt: Date | null;
  elapsedSeconds: number;

  elapsedSecondsTimer: NodeJS.Timeout | null;
}

interface BotRecordingActions {
  startBotRecording: (botRecordingCall: any) => void;
  setupBotRecordingFlow: (callInBotRecordingState: any) => void;
  stopRecording: () => void;

  requestRecordingStop: () => void;
  closeStopRecordingModal: () => void;

  reset: () => void;
  setInStopRecordingProcess: (inStopRecordingProcess: boolean) => void;
}

const getInitialState = (): BotRecordingState => ({
  callId: null,
  showStopRecordingModal: false,
  inStopRecordingProcess: false,
  isGeneralBotFlowActive: false,
  isBotRecording: false,
  recordingStartedAt: null,
  elapsedSeconds: 0,

  elapsedSecondsTimer: null,
});

export const useBotRecordingStore = create<
  BotRecordingState & BotRecordingActions
>((set, get) => ({
  ...getInitialState(),

  setupBotRecordingFlow: async (callInBotRecordingState) => {
    if (
      ![CallStatus.BotRecording, CallStatus.BotJoining].includes(
        callInBotRecordingState.status,
      )
    )
      return;

    set(() => ({
      callId: callInBotRecordingState.id,
      isGeneralBotFlowActive: true,
      inStopRecordingProcess: false,
    }));
  },

  startBotRecording: async (botRecordingCall) => {
    if (!botRecordingCall.botRecordingStartedAt) return;

    set(() => ({
      callId: botRecordingCall.id,
      isBotRecording: true,
      recordingStartedAt: new Date(botRecordingCall.botRecordingStartedAt),
      elapsedSecondsTimer: setInterval(() => {
        set((state) => ({
          elapsedSeconds: state.recordingStartedAt
            ? (new Date().getTime() - state.recordingStartedAt.getTime()) / 1000
            : 0,
        }));
      }, 1000),
    }));
  },

  stopRecording: () => {
    set(() => ({
      isBotRecording: false,
      isGeneralBotFlowActive: false,
      inStopRecordingProcess: true,
    }));
  },

  requestRecordingStop: () => {
    set(() => ({ showStopRecordingModal: true }));
  },

  closeStopRecordingModal: () => {
    set(() => ({ showStopRecordingModal: false }));
  },

  reset: () => {
    const { elapsedSecondsTimer, inStopRecordingProcess } = get();
    if (elapsedSecondsTimer) {
      clearInterval(elapsedSecondsTimer);
    }
    set(() => ({
      ...getInitialState(),
      inStopRecordingProcess,
    }));
  },

  setInStopRecordingProcess: (inStopRecordingProcess: boolean) => {
    set(() => ({ inStopRecordingProcess }));
  },
}));
