import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './';

const selectSlice = (state) => state?.leadership || initialState;

export const selectTimeRangeFilter = createSelector(
  [selectSlice],
  (state) => state.timeRangeFilter,
);
