export const options = (recommendedRangeStart, recommendedRangeEnd) => ({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    annotation: {
      annotations: {
        box1: {
          type: 'box',
          yMin: recommendedRangeStart,
          yMax: recommendedRangeEnd,
          backgroundColor: 'rgba(44, 108, 246, 0.06)',
          borderColor: 'transparent',
        },
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      offset: true,
      beginAtZero: true,
      title: {
        display: true,
        color: '#000',
        font: {
          weight: 'bold',
          size: 12,
        },
      },
      ticks: {
        min: 0,
        stepSize: 50,
      },
      grid: {
        tickWidth: 0,
      },
      border: {
        dash: [5, 3],
        width: 0,
      },
      afterDataLimits(scale) {
        scale.max += 2;
        scale.min = scale.min === 0 ? 0 : scale.min - 1;
      },
    },
  },
});
