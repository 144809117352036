import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckEmailForm } from '../../components/AuthPage/CheckEmailForm';
import { SignUpForm } from '../../components/SignUpPage/SignUpForm';
import { selectEmailNotUsed } from './slice/selectors';

const SignUpPage = () => {
  const navigate = useNavigate();
  const emailNotUsed = useSelector(selectEmailNotUsed);

  useEffect(() => {
    if (emailNotUsed === false) {
      navigate('/auth/login');
    }
  }, [emailNotUsed]);

  return (
    <div className="flex flex-1 bg-[#FFF]">
      <div className="flex-1 flex justify-center items-center">
        <div className="md:w-auto w-full px-5">
          <h2 className="text-dark font-bold text-2xl mb-6">
            Sign up for SilkChart
          </h2>
          {emailNotUsed === true ? (
            <SignUpForm />
          ) : (
            <CheckEmailForm formType={'signUp'} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
