import { Switch } from '@headlessui/react';
import { useDispatch } from 'react-redux';

import { useLeadershipTeamSlice } from '../../pages/LeadershipTeam/slice';

const ToggleColumn = ({ item }) => {
  const dispatch = useDispatch();
  const { actions } = useLeadershipTeamSlice();

  const onChange = () => {
    const newItem = {
      ...item,
      show: !item.show,
    };

    dispatch(actions.updateShowColumnOption(newItem));
  };

  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        <Switch
          checked={item.show}
          onChange={onChange}
          className={`${
            item.show ? 'bg-[#2C6CF6]' : 'bg-[#5C6A82]'
          } relative inline-flex h-4 w-7 items-center rounded-full transition-colors focus:outline-none focus:ring-0 `}
        >
          <span
            className={`${
              item.show ? 'translate-x-[14px]' : 'translate-x-[2px]'
            } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default ToggleColumn;
