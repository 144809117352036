import { create } from 'zustand';

interface RecordingCalendarState {
  isCalendarConnectionSkipped: boolean | null;
  isCalendarConnected: boolean | null;
  isEventAdded: boolean | null;
  isLoading: boolean;
  areCredentialsValid: boolean | null;
  showCalendarConnectedScreen: boolean;
}

interface RecordingCalendarStoreActions {
  setIsCalendarConnectionSkipped: (
    isCalendarConnectionSkipped: boolean,
  ) => void;
  setIsCalendarConnected: (isCalendarConnected: boolean) => void;
  setIsEventAdded: (isEventAdded: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setAreCredentialsValid: (areCredentialsValid: boolean | null) => void;
  setShowCalendarConnectedScreen: (
    showCalendarConnectedScreen: boolean,
  ) => void;
  reset: () => void;
}

export const getInitialState = (): RecordingCalendarState => ({
  isCalendarConnectionSkipped: null,
  isCalendarConnected: null,
  isEventAdded: null,
  isLoading: false,
  areCredentialsValid: null,
  showCalendarConnectedScreen: false,
});

export const useRecordingCalendarStore = create<
  RecordingCalendarState & RecordingCalendarStoreActions
>((set) => ({
  ...getInitialState(),
  setIsCalendarConnectionSkipped: (isCalendarConnectionSkipped: boolean) =>
    set(() => ({ isCalendarConnectionSkipped })),
  setIsCalendarConnected: (isCalendarConnected: boolean) =>
    set(() => ({ isCalendarConnected })),
  setIsEventAdded: (isEventAdded: boolean) => set(() => ({ isEventAdded })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setAreCredentialsValid: (areCredentialsValid: boolean | null) =>
    set(() => ({ areCredentialsValid })),
  setShowCalendarConnectedScreen: (showCalendarConnectedScreen: boolean) =>
    set(() => ({ showCalendarConnectedScreen })),

  reset: () => {
    console.log('reset called');
    set(() => ({
      ...getInitialState(),
    }));
  },
}));
