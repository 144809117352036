import React from 'react';

import { GeneralFailedState } from '@/components/CallDetailsPage/FailStates/GeneralFailedState';
import { NotSalesCallFailedState } from '@/components/CallDetailsPage/FailStates/NotSalesCallFailedState';
import { InviteAssistantAgain } from '@/components/CallDetailsPage/InviteAssistantAgain';
import { CallFailedCode } from '@/enums/call-failed-code.enum';

interface FailedStateParams {
  call: any;
  openDeleteCallModal: any;
}

export const FailedState: React.FC<FailedStateParams> = ({
  call,
  openDeleteCallModal,
}) => {
  if (call.failedCode === CallFailedCode.NotSalesCall) {
    return (
      <NotSalesCallFailedState
        callId={call.id}
        openDeleteCallModal={openDeleteCallModal}
      />
    );
  }

  if (
    call.failedCode === CallFailedCode.BotRecordingFailed ||
    call.failedCode === CallFailedCode.CallTooShort
  ) {
    return <InviteAssistantAgain callId={call.id} />;
  }

  return <GeneralFailedState />;
};
