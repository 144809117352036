import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { DemoQualitativeFeedbackItems } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { useCallDetailsSlice } from '@/pages/CallDetailsPage/slice';
import {
  selectCallQualitativeAnalysis,
  selectCallQualitativeAnalysisLoading,
} from '@/pages/CallDetailsPage/slice/selectors';
import { mapSpeakerletterToNumber } from '@/utils/speakerMapping';
import { validParamId } from '@/utils/validParamId';

import { SectionTitle } from '../SectionTitle/SectionTitle';

const checkSpeakerNameAndMap = (text) => {
  if (!text) return '';
  const pattern = /Speaker\s[A-Za-z]/g;
  const matches = text.match(pattern);

  if (!matches) return text;

  let result = text;

  matches.forEach((match) => {
    const speakerLetter = match.split(' ')[1];
    const SpeakerNumber = mapSpeakerletterToNumber(speakerLetter);
    result = result.replace(match, `Speaker ${SpeakerNumber}`);
  });

  return result;
};

const toSentenceCase = (text) => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const handleTextFormat = (text) => {
  if (!text) return '';
  let outputText = checkSpeakerNameAndMap(text);
  outputText = toSentenceCase(outputText);
  return outputText;
};

// Call details component for qualitative call feedback
const PerformanceFeedback = ({ call }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const callQualitativeAnalysisLoading = useSelector(
    selectCallQualitativeAnalysisLoading,
  );
  const callQualitativeAnalysis = useSelector(selectCallQualitativeAnalysis);
  const { actions: callDetailsActions } = useCallDetailsSlice();

  const qualitativeDataItems = call.isDemoCall
    ? DemoQualitativeFeedbackItems
    : callQualitativeAnalysis;

  const didWellItems = [];
  const needImprovementsItems = [];

  qualitativeDataItems?.forEach((el) => {
    switch (el.type) {
      case 'positive':
        didWellItems.push(el);
        break;
      case 'negative':
        needImprovementsItems.push(el);
        break;
    }
  });

  useEffect(() => {
    if (validParamId(id)) {
      dispatch(
        callDetailsActions.fetchCallQualitativeAnalysis({
          id,
        }),
      );
    }
  }, [id]);

  if (callQualitativeAnalysisLoading) {
    return (
      <div className="flex items-center justify-center">
        <ClipLoader />
      </div>
    );
  }
  // if (!callQualitativeAnalysis) return null;

  return (
    <div>
      <h2 className="hidden text-lg font-bold text-[#2d3648] mb-2">
        Performance Feedback
      </h2>
      <div>
        <SectionTitle className="mb-2">AI Sales Coach feedback</SectionTitle>
        <h3 className="text-base font-semibold">👍 What you did well</h3>
        <ul className="mt-2.5 flex flex-col gap-1.5">
          {didWellItems?.map((item, index) => (
            <li key={index}>
              <CollapsibleItem
                title={handleTextFormat(item.title)}
                openByDefault={call.isDemoCall}
              >
                {checkSpeakerNameAndMap(item.description)}
              </CollapsibleItem>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8">
        <h3 className="text-base font-semibold">⚡️ Where you can improve</h3>
        <ul className="mt-2.5 flex flex-col gap-1.5">
          {needImprovementsItems?.map((item, index) => (
            <li key={index}>
              <CollapsibleItem
                title={handleTextFormat(item.title)}
                openByDefault={call.isDemoCall}
              >
                {checkSpeakerNameAndMap(item.description)}
              </CollapsibleItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PerformanceFeedback;
