import { Tab } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import LeadershipHeader from '../../components/Shared/LeadershipHeader';
import { leadershipTabsData } from '../../utils/tabsData';

const LeadershipPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.pathname.includes('team') ? 1 : 0,
  );

  const handleTabChange = (index) => {
    leadershipTabsData[index].path && navigate(leadershipTabsData[index].path);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    setSelectedTabIndex(location.pathname.includes('dashboard') ? 0 : 1);
  }, [location.pathname]);

  return (
    <div className="bg-[#FFF]">
      <div>
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={(index) => {
            setSelectedTabIndex(index);
            handleTabChange(index);
          }}
        >
          <LeadershipHeader />
          <Tab.Panels>
            {leadershipTabsData.map((tab, tabIndex) => {
              return (
                <Tab.Panel key={tab.id} unmount={false}>
                  {selectedTabIndex === tabIndex && (
                    <div>
                      <Outlet />
                    </div>
                  )}
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default LeadershipPage;
