import { useEffect } from 'react';

import { Logo } from '@/components/Shared/Logo';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const UnsubscribePage = () => {
  useEffect(() => {
    let _mounted = true;

    const unsubscribeUser = async (id) => {
      try {
        await fetch(`${backendURL}/api/unsubscribe/${id}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
      } catch (err) {}
    };

    const queryString = new URLSearchParams(window.location.search);
    const id = queryString.get('id');
    if (id) unsubscribeUser(id);

    return () => (_mounted = false);
  }, []);

  return (
    <>
      <div className="w-full h-16 pl-12 flex items-center border-b-[1px] border-[#EEE]">
        <Logo className="h-7" />
      </div>
      <Unsubscribed />
    </>
  );
};

const Unsubscribed = () => {
  return (
    <div className="flex flex-col gap-10 items-center mt-20">
      <img
        src="/frowning-face.png"
        width={120}
        height={120}
        alt="frowning-face"
      />
      <p className="font-bold text-xl text-dark">You've been unsubscribed!</p>
      <p className="font-normal text-lg text-dark">
        If you change your mind, ping us:&nbsp;
        <a
          href="mailto:hello@silkchart.com"
          className="no-underline font-bold text-lg text-accent-blue hover:text-accent-dark-blue"
        >
          hello@silkchart.com
        </a>
      </p>
      <a
        className="flex gap-1 bg-[#2C6CF6] hover:bg-accent-dark-blue items-center rounded-[10px] py-2.5 px-3 h-9 text-white font-semibold text-sm cursor-pointer"
        href="/"
      >
        Go to Home Page
      </a>
    </div>
  );
};

export default UnsubscribePage;
