import { DatepickerProps } from 'flowbite-react';

export const datepickerTheme: DatepickerProps['theme'] = {
  root: {
    // https://github.com/themesberg/flowbite-react/issues/1210
    input: {
      field: {
        input: {
          base: 'w-full border-main-gray',
          colors: { gray: 'bg-white' },
        },
      },
    },
  },
  views: {
    days: {
      items: {
        item: {
          selected:
            'bg-accent-blue rounded-full text-white hover:bg-accent-dark-blue',
          disabled: 'text-gray-400',
        },
      },
    },
  },
};
