import React from 'react';
import { HelpCircle } from 'react-feather';

export const TopQuestionsEmptyState: React.FC = () => {
  return (
    <div className="flex flex-col items-center pt-2">
      <HelpCircle size={40} color={'#2C6CF6'} strokeWidth={'1.5px'} />
      <span className="text-[#1C1C1C] text-base font-bold mt-4">
        We didn’t detect prospect questions during your calls
      </span>
      <span className="mt-2 text-[#5C6A82] text-xs tracking-[-0.14px] font-normal">
        When prospects ask questions about the product or service, they will
        appear here.
      </span>
    </div>
  );
};
