import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  activateIsDemoMode: backendURL + '/api/organization/me/activate-demo-mode',
};

export const activateIsDemoMode = async () => {
  await API(endpoints.activateIsDemoMode, {
    method: 'PATCH',
  });
};
