import { LogOut } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  selectIsImpersonating,
  selectUser,
} from '@/pages/HomePage/slice/selectors';

export function RevertImpersonationBanner() {
  const user = useSelector(selectUser);
  const isImpersonating = useSelector(selectIsImpersonating);

  if (!isImpersonating) return null;
  return (
    <div className="flex gap-1 p-4 justify-center items-center bg-accent-blue/8 font-semibold">
      <p className="text-dark text-sm">You are signed in as {user.username}.</p>
      <Link
        to="/unimpersonate"
        className="flex items-center gap-1 text-accent-blue"
      >
        Return to Sales Leader View <LogOut size={16} />
      </Link>
    </div>
  );
}
