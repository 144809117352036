export const mock = {
  worstReps: [
    {
      firstName: 'Ryan',
      lastName: 'Calzoni',
      fulfilledItems: '',
      id: '1',
      playbookFulfilledPercentage: '34',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Lain',
      lastName: 'sionron',
      fulfilledItems: '',
      id: '2',
      playbookFulfilledPercentage: '38',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Marcus',
      lastName: 'Dias',
      fulfilledItems: '',
      id: '3',
      playbookFulfilledPercentage: '54',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Carter',
      lastName: 'Franci',
      fulfilledItems: '',
      id: '4',
      playbookFulfilledPercentage: '56',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Aspen',
      lastName: 'Lubin',
      fulfilledItems: '',
      id: '5',
      playbookFulfilledPercentage: '59',
      totalPlaybookItems: '',
    },
  ],
  topReps: [
    {
      firstName: 'Ruben',
      lastName: 'Dias',
      fulfilledItems: '',
      id: '1',
      playbookFulfilledPercentage: '96',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Mira',
      lastName: 'Mango',
      fulfilledItems: '',
      id: '2',
      playbookFulfilledPercentage: '94',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Aspen',
      lastName: 'Philips',
      fulfilledItems: '',
      id: '3',
      playbookFulfilledPercentage: '92',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Talan',
      lastName: 'Calzoni',
      fulfilledItems: '',
      id: '4',
      playbookFulfilledPercentage: '87',
      totalPlaybookItems: '',
    },
    {
      firstName: 'Mark',
      lastName: 'Press',
      fulfilledItems: '',
      id: '5',
      playbookFulfilledPercentage: '85',
      totalPlaybookItems: '',
    },
  ],
};
