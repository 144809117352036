import React from 'react';
import { BarChart2 } from 'react-feather';

export const NoCallsForTimeRange = () => {
  return (
    <div className="flex justify-center flex-col items-center">
      <div className="flex flex-col items-center">
        <BarChart2 size={48} color={'#2C6CF6'} />
        <span className="mt-2 text-dark text-xl font-bold">
          You don’t have calls{' '}
        </span>
        <span className="text-dark text-xl font-bold">
          for the selected date range
        </span>
        <span className="mt-2 text-main-gray-dark text-sm font-normal">
          Please choose another date range
        </span>
      </div>
    </div>
  );
};
