import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Spinner } from 'flowbite-react';
import React, { useEffect } from 'react';

import {
  getGoogleAuthStatus,
  GetGoogleAuthStatusResponse,
} from '@/api/auth/google/get-google-auth-status';
import {
  AddAssistantSuccess,
  SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE,
} from '@/components/Recording/AddAssistantSuccess';
import { AddSilkchartAssistant } from '@/components/Recording/AddSilkchartAssistant';
import { CalendarSuccessfullyConnected } from '@/components/Recording/CalendarSuccessfullyConnected';
import { ConnectYourCalendar } from '@/components/Recording/ConnectYourCalendar';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { UpcomingEvents } from '@/components/Recording/UpcomingEvents';
import { getCookie } from '@/utils/cookie';

interface CalendarFlowModalProps {
  onClose: () => void;
}

export const CalendarFlowWrapper: React.FC<CalendarFlowModalProps> = ({
  onClose,
}) => {
  const {
    isEventAdded,
    areCredentialsValid,
    setAreCredentialsValid,
    showCalendarConnectedScreen,
    setIsCalendarConnected,
    isCalendarConnected,
    isCalendarConnectionSkipped,
  } = useRecordingCalendarStore();

  const showSuccessView =
    getCookie(SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE) || 'true';

  const googleAuthStatus: UseQueryResult<GetGoogleAuthStatusResponse> =
    useQuery<GetGoogleAuthStatusResponse>({
      queryFn: getGoogleAuthStatus(),
      queryKey: ['getGoogleAuthStatus'],
    });

  useEffect(() => {
    if (!googleAuthStatus.data) return;

    setAreCredentialsValid(googleAuthStatus.data.areCredentialsValid);
    setIsCalendarConnected(googleAuthStatus.data.isCalendarConnected);
  }, [googleAuthStatus.data]);

  const isLoading =
    isCalendarConnected === null && areCredentialsValid === null;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-w-[692px] min-h-[129px]">
        <Spinner className="fill-accent-blue h-16 w-16" />
      </div>
    );
  }

  console.log(
    'isCalendarConnected',
    isCalendarConnected,
    'areCredentialsValid',
    areCredentialsValid,
    'isCalendarConnectionSkipped',
    isCalendarConnectionSkipped,
  );

  if (!isCalendarConnected && !isCalendarConnectionSkipped) {
    return <ConnectYourCalendar />;
  }

  if (isEventAdded && showSuccessView === 'true') {
    return <AddAssistantSuccess onClose={onClose} />;
  }

  if (areCredentialsValid && showCalendarConnectedScreen) {
    return <CalendarSuccessfullyConnected />;
  }

  if (areCredentialsValid && !showCalendarConnectedScreen) {
    return <UpcomingEvents onClose={onClose} />;
  }

  if (!isCalendarConnected && isCalendarConnectionSkipped) {
    return <AddSilkchartAssistant onClose={onClose} />;
  }

  return null;
};
