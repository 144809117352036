import React from 'react';

import { CalendarFlowWrapper } from '@/components/Recording/CalendarFlowWrapper';
import Modal from '@/components/Shared/Modal';

interface CalendarFlowModalProps {
  show: boolean;
  onClose: () => void;
}

export const CalendarRecordingModal: React.FC<CalendarFlowModalProps> = ({
  show,
  onClose,
}) => {
  return (
    <Modal
      containerClassName="p-0 m-0"
      show={show}
      onClose={onClose}
      showCloseButton={true}
      closeButtonSize={20}
    >
      <CalendarFlowWrapper onClose={onClose} />
    </Modal>
  );
};
