import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  stopEventRecording: backendURL + '/api/calendar/events/stop-recording',
};

export const stopEventRecording = async (
  callId: string,
  isCallTooShort: boolean,
) => {
  await API(endpoints.stopEventRecording, {
    method: 'POST',
    body: JSON.stringify({
      callId,
      isFailed: isCallTooShort,
    }),
  });
};
