import React from 'react';

export interface AddAssistantWithNoCalendarIntegrationProps {
  containerClassName?: string;
}

export const AddSilkchartAssistantHeader: React.FC<
  AddAssistantWithNoCalendarIntegrationProps
> = ({ containerClassName }) => {
  return (
    <>
      <div className={containerClassName}>
        <span className="text-dark text-base font-bold">
          Add SilkChart Assistant to live meeting to record
        </span>
      </div>
      <div className="w-full bg-main-gray h-[1px]"></div>
    </>
  );
};
