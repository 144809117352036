import { Square } from 'react-feather';
import { useShallow } from 'zustand/react/shallow';

import { eventTracker } from '@/amplitude/eventTracker';
import { CalendarRecordingModal } from '@/components/Recording/CalendarRecordingModal';
import { useModal } from '@/hooks/useModal';
import { useSubscription } from '@/hooks/useSubscription';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

import { Button } from '../Button/Button';
import { WithHoverTooltip } from '../HoverTooltip/WithHoverTooltip';
import { RecordIcon } from './icons/RecordIcon';

interface RecordButtonProps {
  title?: string;
  className?: string;
}

export const RecordButton: React.FC<RecordButtonProps> = ({
  title = 'Record',
  className,
}) => {
  const {
    isOpen: isOpenCalendarIntegrationModal,
    open: openCalendarIntegrationModal,
    close: closeCalendarIntegrationModal,
  } = useModal();

  const {
    isRecording,
    isRecordingRequested,
    requestRecordingStop,
    requestRecordingStart,
  } = useCallRecordingStore(
    useShallow((state) => ({
      isRecording: state.isRecording,
      isRecordingRequested: state.isRecordingRequested,
      requestRecordingStart: state.requestRecordingStart,
      requestRecordingStop: state.requestRecordingStop,
    })),
  );

  const { isGeneralBotFlowActive, requestBotRecordingStop } =
    useBotRecordingStore(
      useShallow((state) => ({
        isGeneralBotFlowActive: state.isGeneralBotFlowActive,
        requestBotRecordingStop: state.requestRecordingStop,
      })),
    );

  console.log('header isGeneralBotFlowActive', isGeneralBotFlowActive);

  const { canCreateNewCalls, isPending: subscriptionLoading } =
    useSubscription();

  const handleClickProd = () => {
    if (isRecording) {
      requestRecordingStop();
      return;
    }

    requestRecordingStart();
  };

  const handleClickQa = () => {
    if (isRecording) {
      requestRecordingStop();
      eventTracker.record.stopClick();
      return;
    }

    if (isGeneralBotFlowActive) {
      requestBotRecordingStop();
      eventTracker.record.stopClick();
      return;
    }

    openCalendarIntegrationModal();

    // eventTracker.record.startClick();
    // requestRecordingStart();
  };

  const handleClick =
    process.env.REACT_APP_ENVIRONMENT === 'qa'
      ? handleClickQa
      : handleClickProd;

  const isLoading =
    subscriptionLoading || (isRecordingRequested && !isRecording);

  return (
    <>
      <CalendarRecordingModal
        show={isOpenCalendarIntegrationModal}
        onClose={closeCalendarIntegrationModal}
      />
      <WithHoverTooltip
        tooltip={
          canCreateNewCalls
            ? undefined
            : 'Upgrade your plan to record new calls'
        }
        placement="bottom-end"
      >
        <Button
          variant={
            isRecording || isGeneralBotFlowActive ? 'danger' : 'secondary'
          }
          isLoading={isLoading}
          onClick={handleClick}
          className={className}
          disabled={!canCreateNewCalls}
        >
          {isRecording || isGeneralBotFlowActive ? (
            <Square size={16} />
          ) : (
            <RecordIcon />
          )}
          <span className="font-semibold text-[13px]">
            {isRecording || isGeneralBotFlowActive ? 'Stop Recording' : title}
          </span>
        </Button>
      </WithHoverTooltip>
    </>
  );
};
