import React from 'react';
import { Info } from 'react-feather';

import cn from '@/utils/cn';

import { WithHoverTooltip, WithHoverTooltipProps } from './WithHoverTooltip';

type InfoTooltipProps = Omit<WithHoverTooltipProps, 'children'>;

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  tooltipContainerClassName,
  ...restProps
}) => {
  return (
    <WithHoverTooltip
      tooltipContainerClassName={cn('max-w-[520px]', tooltipContainerClassName)}
      {...restProps}
    >
      <Info size={14} className="text-main-gray-2" />
    </WithHoverTooltip>
  );
};
