import { Spinner } from 'flowbite-react';
import React from 'react';

export const SyncingCalendarLoader = () => (
  <div className="flex flex-col items-center justify-center gap-4 px-[197.5px] py-6">
    <Spinner className="fill-accent-blue h-16 w-16" />
    <div className="flex flex-col items-center justify-center gap-2">
      <span className="text-dark text-xl font-bold">
        Syncing your calendar...
      </span>
      <span className="text-main-gray-dark text-sm font-medium tracking-[-0.14px]">
        This will only take a few seconds, please wait
      </span>
    </div>
  </div>
);
