const backendURL = process.env.REACT_APP_BACKEND_URL;

interface SubscriptionResponse {
  id: string;
  status: string;
  currentPeriodStart: string; // date
  currentPeriodEnd: string; // date
  subscriptionPlanName: string;
  isTrial: boolean;
  isCanceled: boolean;
  isDemoMode: boolean;
  isExpired: boolean;
  callsLimit: number;
  availableCalls: number;
}

export const getSubscription = async (): Promise<SubscriptionResponse> => {
  const response = await fetch(backendURL + '/api/subscription', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `jwt ${localStorage.getItem('authToken')}`,
    },
  });

  return response.json();
};
