export const teamsMock = [
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 59,
    introAgenda: 48,
    introRapport: 48,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '1',
    firstName: 'Ruben',
    lastName: 'Dias',
    playbookFulfilledPercentage: 96,
    demoPersonalizedOnNeed: 97,
    objection: 94,
    endingNextSteps: 87,
    endingScheduleFollowup: 83,
    averageSpeechPace: 188,
    averageTalkPercentage: 47,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 64,
    introAgenda: 72,
    introRapport: 54,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '2',
    firstName: 'Mira',
    lastName: 'Mango',
    playbookFulfilledPercentage: 94,
    demoPersonalizedOnNeed: 92,
    objection: 95,
    endingNextSteps: 92,
    endingScheduleFollowup: 90,
    averageSpeechPace: 209,
    averageTalkPercentage: 41,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 72,
    introAgenda: 78,
    introRapport: 62,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '3',
    firstName: 'Aspen',
    lastName: 'Philips',
    playbookFulfilledPercentage: 92,
    demoPersonalizedOnNeed: 93,
    objection: 91,
    endingNextSteps: 81,
    endingScheduleFollowup: 72,
    averageSpeechPace: 172,
    averageTalkPercentage: 52,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 71,
    introAgenda: 71,
    introRapport: 71,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '4',
    firstName: 'Talan',
    lastName: 'Calzoni',
    playbookFulfilledPercentage: 79,
    demoPersonalizedOnNeed: 79,
    objection: 80,
    endingNextSteps: 72,
    endingScheduleFollowup: 92,
    averageSpeechPace: 194,
    averageTalkPercentage: 68,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 58,
    introAgenda: 91,
    introRapport: 83,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '3',
    firstName: 'Mark',
    lastName: 'Press',
    playbookFulfilledPercentage: 85,
    demoPersonalizedOnNeed: 72,
    objection: 81,
    endingNextSteps: 69,
    endingScheduleFollowup: 69,
    averageSpeechPace: 172,
    averageTalkPercentage: 52,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 50,
    introAgenda: 63,
    introRapport: 49,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '6',
    firstName: 'Emerson',
    lastName: 'Mango',
    playbookFulfilledPercentage: 78,
    demoPersonalizedOnNeed: 76,
    objection: 69,
    endingNextSteps: 80,
    endingScheduleFollowup: 55,
    averageSpeechPace: 181,
    averageTalkPercentage: 29,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 64,
    introAgenda: 36,
    introRapport: 65,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '7',
    firstName: 'Skylar',
    lastName: 'Herwitz',
    playbookFulfilledPercentage: 71,
    demoPersonalizedOnNeed: 67,
    objection: 49,
    endingNextSteps: 69,
    endingScheduleFollowup: 73,
    averageSpeechPace: 190,
    averageTalkPercentage: 61,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 90,
    introAgenda: 50,
    introRapport: 62,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '8',
    firstName: 'Aspen',
    lastName: 'Lubin',
    playbookFulfilledPercentage: 59,
    demoPersonalizedOnNeed: 71,
    objection: 61,
    endingNextSteps: 39,
    endingScheduleFollowup: 46,
    averageSpeechPace: 175,
    averageTalkPercentage: 51,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 67,
    introAgenda: 67,
    introRapport: 54,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '9',
    firstName: 'Carter',
    lastName: 'Franci',
    playbookFulfilledPercentage: 56,
    demoPersonalizedOnNeed: 50,
    objection: 55,
    endingNextSteps: 81,
    endingScheduleFollowup: 50,
    averageSpeechPace: 128,
    averageTalkPercentage: 49,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 51,
    introAgenda: 94,
    introRapport: 76,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '10',
    firstName: 'Marcus',
    lastName: 'Dias',
    playbookFulfilledPercentage: 54,
    demoPersonalizedOnNeed: 42,
    objection: 47,
    endingNextSteps: 51,
    endingScheduleFollowup: 54,
    averageSpeechPace: 179,
    averageTalkPercentage: 47,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 81,
    introAgenda: 50,
    introRapport: 77,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '11',
    firstName: 'Zain',
    lastName: 'Siphron',
    playbookFulfilledPercentage: 38,
    demoPersonalizedOnNeed: 31,
    objection: 35,
    endingNextSteps: 37,
    endingScheduleFollowup: 28,
    averageSpeechPace: 172,
    averageTalkPercentage: 43,
    email: 'demo@silkchart.com',
  },
  {
    bantAuthority: 10,
    bantBudget: 10,
    bantNeed: 10,
    bantTimeline: 10,
    demoStory: 74,
    introAgenda: 54,
    introRapport: 58,
    averageFillerWords: 2,
    averageLongestMonologue: 5,
    averageQuestionsAsked: 1,
    organizationUserId: '12',
    firstName: 'Ryan',
    lastName: 'Calzoni',
    playbookFulfilledPercentage: 34,
    demoPersonalizedOnNeed: 28,
    objection: 37,
    endingNextSteps: 87,
    endingScheduleFollowup: 49,
    averageSpeechPace: 187,
    averageTalkPercentage: 59,
    email: 'demo@silkchart.com',
  },
];
