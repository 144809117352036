import React from 'react';

import { Button } from '@/components/Button/Button';

const LoadMoreButton = ({ disabled, onClick, isLoading }) => {
  return (
    <Button variant="secondary" onClick={onClick} disabled={disabled}>
      {isLoading ? (
        <img
          className="animate-spin"
          src="/black-button-loader.svg"
          alt="loader"
        />
      ) : (
        <span
          className="text-[13px] font-semibold"
          style={{
            color: disabled ? '#5C6A82' : '#1C1C1C',
            opacity: disabled ? 0.4 : 1,
          }}
        >
          Load more
        </span>
      )}
    </Button>
  );
};

export default LoadMoreButton;
