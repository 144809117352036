import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

const selectSlice = (state) => state?.leadershipDashboard || initialState;

export const selectAveragePerformance = createSelector(
  [selectSlice],
  (state) => state.averagePerformance,
);

export const selectTopReps = createSelector(
  [selectSlice],
  (state) => state.topReps,
);

export const selectWorstReps = createSelector(
  [selectSlice],
  (state) => state.worstReps,
);

export const selectTeamInsights = createSelector(
  [selectSlice],
  (state) => state.teamInsights,
);

export const selectRepsLoading = createSelector(
  [selectSlice],
  (state) => state.repsLoading,
);

export const selectShowNoCallsBanner = createSelector(
  [selectSlice],
  (state) => state.showNoCallsBanner,
);

export const selectDashboardPageLoading = createSelector(
  [selectSlice],
  (state) => state.dashboardPageLoading,
);
