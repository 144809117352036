import React from 'react';

import { InviteAssistantAgain } from '@/components/CallDetailsPage/InviteAssistantAgain';
import { RequestPermissionFailed } from '@/components/CallDetailsPage/RequestPermissionFailed';
import { BotRecordingStatus } from '@/enums/bot-recording-status.enum';

export interface BotJoiningFailedProps {
  botRecordingStatus?: BotRecordingStatus | null;
  callId: string;
}

export const BotJoiningFailed: React.FC<BotJoiningFailedProps> = ({
  botRecordingStatus,
  callId,
}) => {
  if (botRecordingStatus === BotRecordingStatus.RecordingPermissionDenied) {
    return <RequestPermissionFailed callId={callId} />;
  }

  if (botRecordingStatus === BotRecordingStatus.CantJoinCall) {
    return <InviteAssistantAgain callId={callId} />;
  }

  return <InviteAssistantAgain callId={callId} />;
};
