import React, { useState } from 'react';
import { Eye, EyeOff, Lock } from 'react-feather';

import cn from '@/utils/cn';

import { Input, InputProps } from './Input';

interface PasswordInputProps extends InputProps {}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggle = () => {
    setIsPasswordVisible((v) => !v);
  };

  return (
    <Input
      type={isPasswordVisible ? 'text' : 'password'}
      leftSlot={<Lock width={20} height={20} />}
      leftSlotContainerClassName="pointer-events-none"
      rightSlot={
        <button
          onClick={toggle}
          type="button"
          className={cn('h-5 w-5 text-gray-400')}
        >
          {isPasswordVisible ? (
            <Eye width={20} height={20} />
          ) : (
            <EyeOff width={20} height={20} />
          )}
        </button>
      }
      {...props}
    />
  );
};
