import { InsightsAcrossCallsTimeRangeOptionsValue } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';
import { PlaybookItemData } from '@/components/InsightsAcrossCallsPage/mappers/PlaybookEvaluationDataMapper';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export interface GetPlaybookInsightsData {
  playbookInsights: PlaybookItemData[];
  metaInfo: {
    callsWithoutResultsCount: number;
    callsWithAllPlaybookResultsCount: number;
  };
}

export const getPlaybookInsights =
  (timeRange?: InsightsAcrossCallsTimeRangeOptionsValue) => async () => {
    if (!timeRange) return;

    const query = `timeRange=${timeRange}`;

    const response = await fetch(
      backendURL + `/api/insights/playbook?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const result = await response.json();

    return result.data;
  };
