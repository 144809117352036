import React from 'react';

import { secondsToMinSec } from '@/utils/secondsToMinSec';

const LiveFeedbackTimeLeftHint = ({ intervalLeftSeconds }) => {
  return (
    <div className="absolute top-0 left-0 flex items-center justify-start flex-row h-[43px] w-[100%] rounded-b-[10px] bg-[#1c1c1c99]">
      <img
        src="/hourglass-end.svg"
        width="14px"
        height="14px"
        className="ml-4"
      />
      <span className="text-xs text-[#FFF] font-medium ml-1">
        The recording will be stopped in {secondsToMinSec(intervalLeftSeconds)}
      </span>
    </div>
  );
};

export default LiveFeedbackTimeLeftHint;
