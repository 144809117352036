import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

export const initialState = {
  timeRangeFilter: 'week',
};

export const leadershipSlice = createSlice({
  name: 'leadership',
  initialState,
  reducers: {
    setTimeRangeFilter(state, action) {
      state.teamPerformance = [];
      state.timeRangeFilter = action.payload;
    },
  },
});

export const { actions: leadershipActions } = leadershipSlice;

export const useLeadershipSlice = () => {
  useInjectReducer({
    key: leadershipSlice.name,
    reducer: leadershipSlice.reducer,
  });
  return { actions: leadershipSlice.actions };
};
